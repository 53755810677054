import {ContentCard} from 'src/components/common/ContentCard';
import {GoogleAdsPage} from 'src/components/googleAds/page/Page';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {GridType} from 'src/enums/GridType';
import GoogleSignIn from 'src/components/googleAds/GoogleSignIn';
import {Trans, useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet-async';
import GooglePartnerSVG from 'src/assets/GooglePartner.svg';
import BadgeFooter from 'src/components/common/BadgeFooter';
import {PageTitle} from 'src/enums/PageTitle';
import {ServiceType} from 'src/enums/ServiceType';

export const GoogleAdsHome = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.GOOGLE]);

  return (
    <>
      <Helmet>
        <title>{PageTitle.GOOGLE} | Home </title>
      </Helmet>
      <GoogleAdsPage gridType={GridType.FORM}
        additionalFooter={
          <BadgeFooter i18nNameSpace={ServiceType.GOOGLE}
            badgeSVG={GooglePartnerSVG}
            iconHref={t('googleBadgeLink')}
          />
        }>
        <FlexBoxColumn>
          <ContentCard title={t('start.title')} body={<Trans t={t} i18nKey={t('start.body')} />}>
            <GoogleSignIn />
          </ContentCard>
        </FlexBoxColumn>
      </GoogleAdsPage>
    </>
  );
};
