import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {ResponsiveRow} from 'src/components/common/ResponsiveRow';
import {MetricsDataProvider} from 'src/components/providers/metricsData';
import {SelectedMetricProvider} from 'src/components/providers/selectedMetrics';
import styled from 'styled-components';
import {DateRange} from 'src/components/common/metrics/DateRange';
import {DynamicChart} from 'src/components/common/metrics/DynamicChart';
import {Email} from 'src/components/common/metrics/Email';
import {MetricSelect} from './MetricSelect';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {useParams} from 'react-router-dom';
import {useShoppingGrade} from 'src/hooks/shopping/useShoppingGrade';

const StyledColumn = styled(FlexBoxColumn)`
  width: 100%;
  align-items: stretch;
  gap: ${({theme}) => theme.spacing(2)};
`;

const SelectContainer = styled(ResponsiveRow).attrs((props) => ({...props, breakpoint: 600}))`
  width: 100%;
  gap: ${({theme}) => theme.spacing(2)};
  align-items: flex-start;
  justify-content: space-between;
`;

export const PerformanceTracker = (): JSX.Element => {
  const {t} = useTranslation([GoogleAdType.SHOPPING]);
  const {UUID} = useParams();
  const {shoppingGrade} = useShoppingGrade(UUID);

  return (
    <SelectedMetricProvider>
      <MetricsDataProvider>
        <StyledColumn>
          <Typography marginRight='auto' variant='h6' fontWeight='medium'>{t('tracker.view')}</Typography>
          <SelectContainer>
            <MetricSelect />
            <DateRange />
          </SelectContainer>
          <DynamicChart currencySymbol={shoppingGrade?.overallScore.currencySymbol ?? '$'} />
          <Email sx={{mt: 3}} />
        </StyledColumn>
      </MetricsDataProvider>
    </SelectedMetricProvider>
  );
};
