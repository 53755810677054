import {ResultBlock} from './ResultBlock';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {ResponsiveRow} from 'src/components/common/ResponsiveRow';
import {useParams} from 'react-router-dom';
import {useOverallGrade} from 'src/hooks/overall/useOverallGrade';

export const ResultBlocks = (): JSX.Element => {
  const {UUID} = useParams();
  const {overallGrade, b4Shopping} = useOverallGrade(UUID);

  return (
    <ResponsiveRow breakpoint={600} gap={3} sx={{alignItems: 'stretch'}}>
      <ResultBlock adType={GoogleAdType.SEARCH} />
      {
        overallGrade?.displayScores
          && <ResultBlock adType={GoogleAdType.DISPLAY} />
      }
      {
        overallGrade?.shoppingScores
        && !b4Shopping
        && <ResultBlock adType={GoogleAdType.SHOPPING} />
      }
    </ResponsiveRow>
  );
};
