import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

interface Props {
  sectionKey: string;
}

export const DisplayKeyMetricInsight = ({sectionKey}: Props): JSX.Element => {
  const {t} = useTranslation([GoogleAdType.DISPLAY]);

  return (
    <FlexBoxColumn sx={{alignItems: 'flex-start'}} gap={1}>
      <Typography fontWeight={'fontWeightMedium'} variant='h5'>
        {t('grade.keyMetricTextTitle', {context: sectionKey})}
      </Typography>
      <Typography variant='body1' textAlign={'left'}>
        {t('grade.keyMetricText', {context: sectionKey})}
      </Typography>
    </FlexBoxColumn>
  );
};
