import {Table, TableBody} from '@mui/material';
import {getScoreName, getScoreNameForCPC} from '../getScoreName';
import {ATTableRow} from './AudienceTypesTableRow';
import {AudienceTypesTableHead} from './AudienceTypesTableHead';
import {AudienceTypeStat, withSymbol} from '../utils';

type Props = {
  ctr: {
    you: number | undefined;
    competitors: number | undefined;
    difference: number | undefined;
  };
  cpc: {
    you: number | undefined;
    competitors: number | undefined;
    difference: number | undefined;
    currencySymbol: string;
  };
}

const AudienceTypesTable = ({ctr, cpc}: Props): JSX.Element => {
  return (
    <Table sx={{tableLayout: 'fixed'}}>
      <AudienceTypesTableHead />
      <TableBody>
        <ATTableRow
          text={AudienceTypeStat.CTR}
          you={withSymbol(ctr.you, '%', 'Not Used')}
          competitors={withSymbol(ctr.competitors, '%')}
          difference={ctr.difference}
          scoreName={getScoreName({
            you: ctr.you,
            benchmark: ctr.competitors,
            difference: ctr.difference,
            youZeroNotUsed: true
          })}
        />
        <ATTableRow
          text={AudienceTypeStat.CPC}
          you={withSymbol(cpc.you, cpc.currencySymbol, 'Not Used')}
          competitors={withSymbol(cpc.competitors, cpc.currencySymbol)}
          difference={cpc.difference}
          scoreName={getScoreNameForCPC({
            you: cpc.you,
            benchmark: cpc.competitors,
            difference: cpc.difference,
            youZeroNotUsed: true
          })}
        />
      </TableBody>
    </Table>
  );
};

export default AudienceTypesTable;
