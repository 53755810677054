import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ScoreName} from 'src/enums/ScoreName';
import {GoogleDisplayScoreSection} from 'src/enums/google/ScoreSection';
import {GradeUtils} from 'src/utils/GradeUtils';
import {useDisplayGrade} from './useDisplayGrade';
import {IDisplayGrade} from 'src/types/display/DisplayGrade';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {ServiceType} from 'src/enums/ServiceType';
import {ISODateParser} from 'src/utils/helpers';

const EMPTY_CHAR = '\u00a0';

type Props = {
  sectionName?: GoogleDisplayScoreSection;
}

export type DisplayGradeScoreType = {
  displayGrade: IDisplayGrade | undefined;
  scoreName: ScoreName | undefined;
  scoreValue: number | string;
  date: string;
  currencySymbol: string;
  currencyCode: string;
}

export const useDisplayGradeScore = ({sectionName}: Props): DisplayGradeScoreType => {
  const {UUID} = useParams();
  const {displayGrade} = useDisplayGrade(UUID);
  const [scoreName, setScoreName] = useState<ScoreName | undefined>();
  const [scoreValue, setScoreValue] = useState<number | string>(EMPTY_CHAR);
  const [date, setDate] = useState<string>(new Date().toLocaleDateString(process.env.REACT_APP_LOCALE));
  const [currencySymbol, setCurrencySymbol] = useState<string>('$');
  const [currencyCode, setCurrencyCode] = useState<string>('USD');

  useEffect(() => {
    if (!displayGrade) return;

    setScoreName(GradeUtils.getScoreName(displayGrade, ServiceType.GOOGLE, sectionName, GoogleAdType.DISPLAY));

    if (sectionName) {
      setScoreValue(GradeUtils.getScoreValue(sectionName, displayGrade, ServiceType.GOOGLE, GoogleAdType.DISPLAY));
    } else {
      setScoreValue(GradeUtils.roundoff(displayGrade.overallScore.overallScore, 0));
    }

    // eslint-disable-next-line
    if (displayGrade && displayGrade.overallScore.reportDate) {
      setDate(ISODateParser(displayGrade.overallScore.reportDate).toLocaleDateString(process.env.REACT_APP_LOCALE));
    }

    if (displayGrade.overallScore.currencySymbol) {
      setCurrencySymbol(displayGrade.overallScore.currencySymbol);
    }

    if (displayGrade.overallScore.currencyCode) {
      setCurrencyCode(displayGrade.overallScore.currencyCode);
    }
  }, [displayGrade]);

  return {displayGrade, scoreName, scoreValue, date, currencySymbol, currencyCode};
};
