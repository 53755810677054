import {Chart, FontSpec, TooltipModel} from 'chart.js';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {ServiceType} from 'src/enums/ServiceType';
import {withSymbol} from '../components/facebook/grade/table/utils';

const getElWithPadding = (el: string) => {
  const created = document.createElement(el);
  created.style.padding = '4px';
  return created;
};

const getStyledText = (text: string, bold?: boolean) => {
  const created = document.createElement('div');
  created.style.fontFamily = 'Unify Sans';
  created.style.fontSize = '16px';
  created.style.fontWeight = bold ? '600' : '400';
  created.style.padding = '2px 4px';
  created.appendChild(document.createTextNode(text));
  return created;
};

export const getOrCreateTooltip = (chart: Chart): HTMLDivElement => {
  let tooltipEl = chart.canvas.parentNode?.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.id = 'external_tooltip';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode?.appendChild(tooltipEl);
  }

  return tooltipEl;
};

type ExtTooltipProps = {
  chart: Chart;
  tooltip: TooltipModel<'line'>;
  tickPrefix: string | undefined;
  tickSuffix: string | undefined;
  getUUID: (index: number) => string | undefined;
  service: string;
  googleAdType: GoogleAdType;
}

export const externalTooltipHandler = ({chart, tooltip, tickPrefix, tickSuffix, getUUID, service, googleAdType}: ExtTooltipProps): void => {
  const tooltipEl = getOrCreateTooltip(chart);
  const UUID = getUUID(tooltip.dataPoints[0].dataIndex);
  const report = service === ServiceType.FACEBOOK || service === ServiceType.GOOGLE && googleAdType === GoogleAdType.OVERALL ? 'report' : googleAdType;
  const hrefLink = `${process.env.PUBLIC_URL}/${service}-grader/${report}/${UUID}`;

  // Set Text
  const titleLines = tooltip.title;
  const bodyLines = tooltip.body.map((bodyElement) => bodyElement.lines);

  const tooltipBody = getElWithPadding('div');
  tooltipBody.style.display = 'flex';
  tooltipBody.style.flexDirection = 'column';
  tooltipBody.style.alignItems = 'center';

  const dateP = getStyledText(`On ${titleLines[0]}`);

  const scoreP = getStyledText(
    tickPrefix
      ? withSymbol(parseFloat(bodyLines[0][0].replace(/,/g, '')), tickPrefix)
      : tickSuffix
        ? tickSuffix === '%'
          ? withSymbol(parseFloat(bodyLines[0][0].replace(/,/g, '')), tickSuffix)
          : bodyLines[0] + tickSuffix
        : bodyLines[0] + '', true
  );

  const detailsLink = document.createElement('a');
  const detailsText = getStyledText('View Details');
  detailsLink.appendChild(detailsText);
  detailsLink.style.cursor = 'pointer';
  detailsLink.style.textDecoration = 'underline';
  detailsLink.href = hrefLink;
  detailsLink.target = '_blank';
  detailsLink.style.color = 'white';
  detailsText.style.pointerEvents = 'stroke';

  tooltipBody.appendChild(scoreP);
  tooltipBody.appendChild(dateP);
  tooltipBody.appendChild(detailsLink);

  const tableRoot = tooltipEl.querySelector<HTMLElement>('table');

  if (!tableRoot) return;
  // Remove old children
  while (tableRoot.firstChild) {
    tableRoot.firstChild.remove();
  }

  // Add new children
  tableRoot.appendChild(tooltipBody);

  const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = (tooltip.options.bodyFont as FontSpec).family;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};
