import {useMediaQuery, useTheme} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useFAContext} from 'src/components/providers/facebookAds';
import {useFacebookGrade} from 'src/hooks/facebook/useFacebookGrade';
import useGATracking from 'src/hooks/google/useGATracking';
import {ConditionalClearTimeout, DelayNavigationToTimedOutError, TimeIDRef} from 'src/utils/helpers';
import {ServiceType} from 'src/enums/ServiceType';

type ReturnType = {
  isMobile: boolean;
  isZeroSpend: boolean;
}

const dataLayer = {
  event: 'gaTriggerEvent',
  gaEventCategory: 'FBGrader',
  gaEventAction: 'Impression',
  gaEventLabel: 'Step3'
};

export const useReport = (): ReturnType => {
  const navigate = useNavigate();
  const context = useFAContext();
  const {UUID} = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const {grade, error} = useFacebookGrade(UUID);
  const [isZeroSpend, setZeroSpend] = useState(false);
  const {pushCustomGAEvent} = useGATracking(
    {
      pageViewDefaults: {title: 'FacebookAdsGrader Report'},
      triggerOnMount: true
    }
  );
  const timeIDRef: TimeIDRef = {
    timeID: undefined
  };

  useEffect(() => {
    if (UUID === undefined) navigate('/');
  }, []);

  useEffect(() => {
    if (!grade && !error) {
      timeIDRef.timeID = DelayNavigationToTimedOutError(navigate, ServiceType.FACEBOOK);
    } else if (grade && !error) {
      ConditionalClearTimeout(timeIDRef);
      pushCustomGAEvent(dataLayer);
      let displayName = grade.overallScore.accountId;
      if (grade.overallScore.accountName) {
        displayName = grade.overallScore.accountName + ' (' + grade.overallScore.accountId + ')';
      }
      context.setFacebookAdsAccount({accountId: grade.overallScore.accountId!, accountName: displayName});
      setZeroSpend(grade.accountSummary.totalSpend === 0);
    } else if (error) {
      ConditionalClearTimeout(timeIDRef);
      navigate('/facebook-grader/error?event=noRoute');
    }

    return () => ConditionalClearTimeout(timeIDRef);
  }, [grade, error]);

  return {isMobile, isZeroSpend};
};
