import { Typography, useMediaQuery, MenuItem, Box } from '@mui/material';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { GoogleAdsScoreSection } from 'src/enums/google/ScoreSection';
import { LineGraph } from './LineGraph';
import { VerticalStackedGraph } from 'src/components/common/grade/charts/VerticalStackedGraph';
import { DoughnutChart } from 'src/components/common/grade/charts/DoughnutChart';
import { HorizontalBarGraph } from 'src/components/common/grade/charts/HorizontalBarGraph';
import StyledSelect from 'src/components/common/styled/StyledSelect';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { CommonChartLabels } from 'src/components/common/grade/charts/Labels';
import { ImpressionShareLabels } from './Labels';
import { useSectionManager } from './useSectionManager';
import { useTranslation } from 'react-i18next';
import { commonChartOptions } from 'src/components/common/grade/charts/commonOptions';
import { DatasetDuration } from 'src/utils/datasetHelper';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { useChartDefaults } from 'src/components/common/grade/charts/useChartDefaults';
import { ChartDataset } from 'chart.js';
import { LabelOptions } from 'chartjs-plugin-datalabels/types/options';
import { LineHeight } from 'src/components/theme/orion/overrides/LineHeight';

const doughnutStyling: Partial<ChartDataset<'doughnut'>> = {
  backgroundColor: [OrionColors.chart.green, OrionColors.chart.blue, OrionColors.chart.orange],
  borderWidth: 1,
  borderColor: '#ffffff',
};

const doughnutLabelOptions: LabelOptions = {
  anchor: 'end',
  align: 'end',
  offset: 4,
  font: {
    size: 16,
    weight: 'bold',
  },
  formatter: (value: number) => Math.floor(value) + '%',
};

const acountActivityLabels = ['Campaigns', 'Text Ads', 'Keywords', 'Ad Groups', 'Placements'];
const wastedSpendLabels = ['You', 'Industry Benchmark'];

export const ChartRenderer: React.FC<{ section: GoogleAdsScoreSection }> = ({ section }) => {
  const { t } = useTranslation(['google', 'common']);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));

  const {
    title,
    onDurationChange,
    qualityScoreData,
    qualityScoreLabels,
    accountActivityData,
    impressionsData,
    wastedSpendData,
  } = useSectionManager({ section });

  useChartDefaults();

  return (
    <FlexBoxColumn gap={3} sx={{ width: '100%' }}>
      <ResponsiveRow style={{ justifyContent: 'space-between' }} alignSelf={'stretch'} breakpoint={600}>
        <Typography
          fontWeight={'fontWeightMedium'}
          sx={{
            alignSelf: 'flex-start',
            pb: isMobile ? 2 : 0,
            fontSize: '1.25rem',
            lineHeight: LineHeight.LOOSE,
          }}
        >
          {title}
        </Typography>
        {section !== GoogleAdsScoreSection.IMPRESSION_SHARE && (
          <StyledSelect
            data-testid="durationSelect"
            defaultValue={section === GoogleAdsScoreSection.QUALITY_SCORE ? 'duration90' : 'duration30'}
            sx={{ width: isMobile ? '100%' : OrionTheme.spacing(37) }}
            fullWidth={isMobile}
            size="small"
            onChange={(event) => onDurationChange(event.target.value as DatasetDuration, section)}
          >
            <MenuItem value={'duration30'}>{t('common:duration30')}</MenuItem>
            <MenuItem value={'duration90'}>{t('common:duration90')}</MenuItem>
          </StyledSelect>
        )}
      </ResponsiveRow>
      {qualityScoreData?.dataset && qualityScoreLabels && (
        <>
          <FlexBoxColumn data-testid="qualityScoreChart" alignSelf={'stretch'} gap={2}>
            <Typography variant="body1" sx={{ alignSelf: 'flex-start' }}>
              {t('grade.qualityScore.chart.message')}
            </Typography>
            <LineGraph
              dataset={{ ...qualityScoreData.dataset }}
              options={commonChartOptions}
              labels={qualityScoreLabels}
              xAxisText={t('grade.qualityScore.chart.xAxis')}
              yAxisText={t('grade.qualityScore.chart.yAxis')}
              isMobile={isMobile}
            />
            <CommonChartLabels responsive={true} />
          </FlexBoxColumn>
        </>
      )}
      {accountActivityData?.dataset && (
        <>
          <Typography variant="body1">{t('grade.accountActivity.chart.message')}</Typography>
          <FlexBoxColumn data-testid="accountActivityChart" alignSelf={'stretch'} gap={2}>
            <VerticalStackedGraph
              dataset={{ ...accountActivityData.dataset }}
              options={commonChartOptions}
              labels={acountActivityLabels}
              xAxisText={t('grade.accountActivity.chart.xAxis')}
              isMobile={isMobile}
            />
            <CommonChartLabels responsive={false} />
          </FlexBoxColumn>
        </>
      )}
      {impressionsData?.dataset && (
        <>
          <Typography variant="body1" sx={{ alignSelf: 'flex-start' }}>
            {t('grade.impressionShare.chart.message')}
          </Typography>
          <ResponsiveRow breakpoint="sm" gap={isMobile ? 3 : 15} data-testid="impressionsChart">
            <Box sx={{ maxHeight: '100%' }}>
              <DoughnutChart
                dataset={[...impressionsData.dataset]}
                doughnutStyling={doughnutStyling}
                labels={doughnutLabelOptions}
                options={{
                  ...commonChartOptions,
                  maintainAspectRatio: false,
                  responsive: true,
                }}
                isMobile={isMobile}
              />
            </Box>
            <ImpressionShareLabels />
          </ResponsiveRow>
        </>
      )}
      {wastedSpendData?.dataset && (
        <>
          <FlexBoxColumn data-testid="wastedSpendChart" alignSelf={'stretch'} gap={isMobile ? 1 : 2}>
            <Typography variant="body1">{t('grade.wastedSpend.chart.message')}</Typography>
            <HorizontalBarGraph
              dataset={[...wastedSpendData.dataset]}
              options={commonChartOptions}
              labels={wastedSpendLabels}
              xAxisText={t('grade.wastedSpend.chart.xAxis')}
              isMobile={isMobile}
            />
            <CommonChartLabels responsive={true} />
          </FlexBoxColumn>
        </>
      )}
    </FlexBoxColumn>
  );
};
