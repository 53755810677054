import { styled, TablePagination, useMediaQuery } from '@mui/material';
import { useContext, useState } from 'react';
import { AlertSnackbar } from 'src/components/common/AlertSnackbar';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { KeywordsContext } from 'src/components/providers/keywords';
import { IEmailKeywordResponse } from 'src/types/keywords/KeywordResponse';
import { EmailKeywordsModal } from '../emailModal/EmailKeywordsModal';
import { OrionTheme } from 'src/components/theme/orion/Theme';

const StyledPagination = styled(TablePagination)`
  button:not([disabled]) > * {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  overflow: hidden;
  border-bottom: 0;
` as typeof TablePagination;

const KeywordPagination = (): JSX.Element => {
  const { keywords } = useContext(KeywordsContext);
  const [openKwdModal, setOpenKwdModal] = useState(false);
  const [modalResponse, setModalResponse] = useState<IEmailKeywordResponse | null>(null);
  const isMobile: boolean = useMediaQuery(OrionTheme.breakpoints.down(500));

  return keywords && keywords.keywordCount > 0 ? (
    <ResponsiveRow breakpoint={840} sx={{ justifyContent: 'flex-end' }}>
      <StyledPagination
        count={keywords.keywordCount}
        page={0}
        component="div"
        showFirstButton={true}
        showLastButton={true}
        onPageChange={() => setOpenKwdModal(true)}
        rowsPerPage={25}
        rowsPerPageOptions={isMobile ? [] : [25, 50, 100]}
        SelectProps={{
          inputProps: {
            'aria-label': 'rows per page',
            style: { color: OrionTheme.palette.primary.main },
          },
          native: true,
        }}
        onRowsPerPageChange={() => setOpenKwdModal(true)}
      />
      <EmailKeywordsModal
        open={openKwdModal}
        onClose={() => setOpenKwdModal(false)}
        onResponse={(emailResponse: IEmailKeywordResponse) => setModalResponse(emailResponse)}
      />
      {modalResponse && <AlertSnackbar message={modalResponse.emailResponse} onClose={() => setModalResponse(null)} />}
    </ResponsiveRow>
  ) : (
    <></>
  );
};

export default KeywordPagination;
