import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { MainSection } from 'src/components/common/grade/header/MainSection';
import { PopOverSection } from 'src/components/common/grade/header/PopOverSection';
import { TypedGrid } from 'src/components/common/Grid';
import { GridType } from 'src/enums/GridType';
import { FadeInTransition } from 'src/components/common/FadeInTransition';
import { Typography, useMediaQuery } from '@mui/material';
import { ContactUsSection } from 'src/components/common/grade/header/ContactUsSection';
import { useTranslation } from 'react-i18next';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionTheme } from 'src/components/theme/orion/Theme';

export const Header = (): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));

  return (
    <TypedGrid type={GridType.TAB_PANEL_CONTENT}>
      <FadeInTransition>
        <FlexBoxColumn sx={{ gap: isMobile ? 3 : 6 }}>
          <Typography variant="h1" sx={{ color: OrionTheme.palette.common.white }}>
            {t('headerTitle')}
          </Typography>
          <FlexBoxColumn sx={{ alignSelf: 'stretch', position: 'relative', justifyContent: 'flex-end', height: 228 }}>
            <PopOverSection />
            <MainSection />
          </FlexBoxColumn>
          <ContactUsSection stretched={false} />
        </FlexBoxColumn>
      </FadeInTransition>
    </TypedGrid>
  );
};
