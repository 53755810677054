import {useEffect, useState} from 'react';
import {DisplayGradeScoreType, useDisplayGradeScore} from 'src/hooks/display/useDisplayGradeScore';
import {GGradeScoreType, useGGradeScore} from 'src/hooks/google/useGGradeScore';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {OverallGradeType, useOverallGradeScore} from 'src/hooks/overall/userOverallGradeScore';

declare global {
  interface Date {
    addDays: (days: number) => Date;
  }
}

Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const DateFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
};

type Props = {
  adType: GoogleAdType;
}

const getDate = (googleAdType: GoogleAdType): string => {
  let result: DisplayGradeScoreType | GGradeScoreType | OverallGradeType;
  switch (googleAdType) {
    case GoogleAdType.DISPLAY:
      result = useDisplayGradeScore({sectionName: undefined});
      return result.date;
    case GoogleAdType.SEARCH:
      result = useGGradeScore({sectionName: undefined});
      return result.date;
    default:
    case GoogleAdType.OVERALL:
      result = useOverallGradeScore();
      return result.date;
  }
};

export const ReportDateRange = ({adType}: Props): {dateRange: string} => {
  const date = getDate(adType);
  const [dateRange, setDateRange] = useState('');

  useEffect(() => {
    if (!date) return;
    const previousDay = new Date(date).addDays(-1);
    const prior90Days = new Date(date).addDays(-91);
    const to = previousDay.toLocaleDateString(process.env.REACT_APP_LOCALE, DateFormatOptions);
    const from = prior90Days.toLocaleDateString(process.env.REACT_APP_LOCALE, DateFormatOptions);

    setDateRange(`${from} - ${to}`);
  }, [date]);

  return {dateRange};
};
