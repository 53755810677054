import {useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {IHorizontalBarGraphProps} from 'src/components/common/grade/charts/HorizontalBarGraph';
import {useTranslation} from 'react-i18next';
import {
  DatasetDuration,
  getShoppingAccountStructureDataset,
  getShoppingWastedSpendDataset,
  getShoppingCampaignRankingsDataSet,
} from 'src/utils/datasetHelper';
import {GradeUtils} from 'src/utils/GradeUtils';
import {useShoppingGrade} from 'src/hooks/shopping/useShoppingGrade';
import {GoogleShoppingScoreSection} from 'src/enums/google/ScoreSection';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {IVerticalStackedChartProps} from 'src/components/common/grade/charts/VerticalStackedGraph';
import {IScatterChartProps} from 'src/components/common/grade/charts/ScatterChart';

type Props = {
  section: GoogleShoppingScoreSection;
}

type ReturnType = {
  title: string;
  onDurationChange: (selectedDuration: DatasetDuration, section: GoogleShoppingScoreSection) => void;
  wastedSpendData: Pick<IHorizontalBarGraphProps, 'dataset'> | undefined;
  accountStructureData: Pick<IVerticalStackedChartProps, 'dataset'> | undefined;
  campaignRankingsData: Pick<IScatterChartProps, 'dataset'> | undefined;
  currencyCode: string | undefined;
}

export const useSectionManager = ({section}: Props): ReturnType => {
  const {UUID} = useParams();
  const {shoppingGrade} = useShoppingGrade(UUID);
  const {t} = useTranslation([GoogleAdType.SHOPPING]);

  const sectionKey = GradeUtils.getSectionKey(section);
  const title = t(`grade.${sectionKey}.chart.title`);

  const [wastedSpendData, setWastedSpendData] = useState<Pick<IHorizontalBarGraphProps, 'dataset'> | undefined>();
  const [accountStructureData, setAccountStructureData] = useState<Pick<IVerticalStackedChartProps, 'dataset'> | undefined>();
  const [campaignRankingsData, setCampaignsRankingData] = useState<Pick<IScatterChartProps, 'dataset'> | undefined>();

  const onDurationChange = (selectedDuration: DatasetDuration, section: GoogleShoppingScoreSection): void => {
    if (shoppingGrade) {
      switch (section) {
        case GoogleShoppingScoreSection.WASTED_SPEND:
          setWastedSpendData(getShoppingWastedSpendDataset(shoppingGrade, selectedDuration));
          break;
      }
    }
  };

  useEffect(() => {
    if (shoppingGrade && section === GoogleShoppingScoreSection.WASTED_SPEND) {
      setWastedSpendData({...getShoppingWastedSpendDataset(shoppingGrade)});
    }
    if (shoppingGrade && section === GoogleShoppingScoreSection.ACCOUNT_STRUCTURE) {
      setAccountStructureData({...getShoppingAccountStructureDataset(shoppingGrade)});
    }
    if (shoppingGrade && section === GoogleShoppingScoreSection.CAMPAIGN_RANKINGS) {
      setCampaignsRankingData({...getShoppingCampaignRankingsDataSet(shoppingGrade)});
    }
  }, [shoppingGrade]);

  return {title, onDurationChange, wastedSpendData, accountStructureData, campaignRankingsData, currencyCode: shoppingGrade?.overallScore.currencyCode};
};
