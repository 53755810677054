import { LabelOptions } from 'chartjs-plugin-datalabels/types/options';
import { ChartDataset } from 'chart.js';
import { Platform } from 'src/enums/facebook/Platform';
import { IFacebookGrade, IPlacement, IPlacementByDevice, IPlacementByPlatform } from 'src/types/facebook/FacebookGrade';
import { DeviceType } from 'src/enums/facebook/Device';

import FacebookIcon from 'src/assets/platformIcons/FacebookIcon.png';
import InstagramIcon from 'src/assets/platformIcons/InstagramIcon.svg';
import MessengerIcon from 'src/assets/platformIcons/MessengerIcon.png';

import PhoneIconLegend from 'src/assets/deviceIcons/PhoneIconSmall.svg';
import DesktopIconLegend from 'src/assets/deviceIcons/DesktopIconSmall.svg';
import TabletIconLegend from 'src/assets/deviceIcons/TabletIconSmall.svg';
import FillerIconLegend from 'src/assets/deviceIcons/FillerIconSmall.svg';

import PhoneIcon from 'src/assets/deviceIcons/PhoneIcon.svg';
import DesktopIcon from 'src/assets/deviceIcons/DesktopIcon.svg';
import TabletIcon from 'src/assets/deviceIcons/TabletIcon.svg';

import { OrionColors } from 'src/components/theme/orion/common/colors';

export const LegendColors = [
  OrionColors.chart.orange,
  OrionColors.chart.green,
  OrionColors.chart.blue,
  OrionColors.chart.purple,
];

export const DoughnutLabelOptions: LabelOptions = {
  display: false,
};

export const DoughnutStyling: Partial<ChartDataset<'doughnut'>> = {
  backgroundColor: LegendColors,
  borderWidth: 1,
  borderColor: '#ffffff',
};

export const getPlatformIcon = (platform: Platform): string => {
  switch (platform) {
    case Platform.FACEBOOK:
      return FacebookIcon;
    case Platform.INSTAGRAM:
      return InstagramIcon;
    case Platform.MESSENGER:
      return MessengerIcon;
  }
};

export const getLegendIcon = (name: string): string => {
  switch (name) {
    case 'phone':
      return PhoneIconLegend;
    case 'desktop':
      return DesktopIconLegend;
    case 'tablet':
      return TabletIconLegend;
    default:
      return FillerIconLegend;
  }
};

export const getPlacementsByDevice = (grade: IFacebookGrade): IPlacementByDevice | undefined =>
  grade.platformOverview && {
    [DeviceType.MOBILE]: {
      ...grade.platformOverview.placementsByDevice.MOBILE,
      deviceDisplayName: 'phone',
      icon: PhoneIcon,
    },
    [DeviceType.DESKTOP]: {
      ...grade.platformOverview.placementsByDevice.DESKTOP,
      deviceDisplayName: 'desktop',
      icon: DesktopIcon,
    },
    [DeviceType.TABLET]: {
      ...grade.platformOverview.placementsByDevice.TABLET,
      deviceDisplayName: 'tablet',
      icon: TabletIcon,
    },
  };

export const getPlacementsByPlatform = (grade: IFacebookGrade): IPlacementByPlatform =>
  Object.values(Platform).map(
    (platform) =>
      ({
        icon: getPlatformIcon(platform),
        platform: platform.toLowerCase(),
        ...grade.platformOverview?.metricsByPlatform.find((item) => item.platform === platform.toLocaleLowerCase()),
      }) as IPlacement,
  );

export const getAdSetPlacement = (grade: IFacebookGrade): number =>
  grade.accountSummary.automaticPlacementsCount !== undefined
    ? grade.accountSummary.automaticPlacementsCount / grade.accountSummary.activeAdSets
    : 0;
