import {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {IErrorResponse} from 'src/types/common/Error';

interface ResponseType extends IErrorResponse {
  gradeUuid: string;
}

export const useFAGradeRefresh = (): void => {
  const {UUID} = useParams();
  const [searchParams] = useSearchParams();
  const salesOnly = searchParams.get('salesOnly');
  const navigate = useNavigate();
  const [response, setResponse] = useState<ResponseType>();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/facebook/refresh`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        gradeUuid: UUID,
        salesOnly: salesOnly !== null ? salesOnly : true
      })
    })
      .then(async (response) => {
        setResponse(await response.json());
      });
  }, []);

  useEffect(() => {
    if (response && response.gradeUuid) {
      navigate(`/facebook-grader/report/${response.gradeUuid}`);
    }

    if (response && response.error) {
      navigate('/facebook-grader/error');
    }
  }, [response]);
};
