import React, { Component } from 'react';
import { Chart, ChartOptions, ChartDataset } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { LabelOptions } from 'chartjs-plugin-datalabels/types/options';
import { GradeUtils } from 'src/utils/GradeUtils';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { getResponsiveChartFont } from 'src/utils/helpers';

export interface IVerticalStackedChartProps {
  dataset: {
    you: number[];
    industry: number[];
  };
  labels: string[];
  xAxisText?: string;
  options?: ChartOptions;
  isMobile?: boolean;
}

const barStyling = (type: 'you' | 'industry', isMobile: boolean): Partial<ChartDataset<'bar'>> => {
  const color = type === 'you' ? OrionColors.chart.orange : OrionColors.chart.blue;

  return {
    borderWidth: 1,
    borderRadius: {
      topLeft: 24,
      topRight: 24,
      bottomLeft: 0,
      bottomRight: 0,
    },
    maxBarThickness: isMobile ? 16 : 24,
    barThickness: 'flex',
    backgroundColor: color,
    borderColor: 'rgba(215, 215, 215, 0.25)',
    categoryPercentage: isMobile ? 0.65 : 0.45,
  };
};

const labelOptions: LabelOptions = {
  anchor: 'end',
  align: 'top',
  color: '#000',
  padding: 0,
  offset: 0,
  font: (ctx) => {
    return {
      family: 'Unify Sans',
      weight: 'bolder',
      size: ctx.chart.width > 600 ? 16 : 12,
      lineHeight: ctx.chart.width > 600 ? '24px' : '16px',
    };
  },
  formatter: (value: number) => {
    return value > -1 ? GradeUtils.roundoff(value) : null;
  },
};

export class VerticalStackedGraph extends Component<IVerticalStackedChartProps> {
  canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();

  chart: Chart | undefined;

  options: ChartOptions = {
    ...this.props.options,
    layout: {
      padding: {
        left: 0,
        bottom: 0,
        top: 24,
        right: 0,
      },
    },
    scales: {
      x: {
        grid: {
          drawTicks: false,
          drawOnChartArea: false,
          drawBorder: true,
          borderColor: '#737373',
          color: '#737373',
        },
        title: {
          display: this.props.xAxisText ? true : false,
          text: this.props.xAxisText,
          color: '#000',
          font: getResponsiveChartFont,
        },
        ticks: {
          padding: this.props.isMobile ? 4 : 16,
          color: '#000',
          font: {
            size: this.props.isMobile ? 10 : 14,
            lineHeight: '16px',
            family: 'Unify Sans',
            weight: 'normal',
          },
        },
      },
      y: {
        grid: {
          drawTicks: false,
          drawOnChartArea: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  drawChart(): void {
    const canvasContext = this.canvasRef.current?.getContext('2d') as CanvasRenderingContext2D;

    this.chart = new Chart(canvasContext, {
      type: 'bar',
      data: {
        labels: this.props.labels,
        datasets: [
          {
            type: 'bar',
            data: this.props.dataset.you,
            ...barStyling('you', this.props.isMobile as boolean),
            datalabels: {
              ...labelOptions,
            },
          },
          {
            type: 'bar',
            data: this.props.dataset.industry,
            ...barStyling('industry', this.props.isMobile as boolean),
            datalabels: {
              ...labelOptions,
            },
          },
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        ...this.options,
      },
    });
  }

  componentDidMount(): void {
    this.chart?.destroy();
    this.drawChart();
  }

  componentDidUpdate(): void {
    this.chart?.destroy();
    this.drawChart();
  }

  render(): JSX.Element {
    return (
      <div style={{ height: this.props.isMobile ? '280px' : '340px', width: '100%' }}>
        <canvas id="chart" ref={this.canvasRef} />
      </div>
    );
  }
}
