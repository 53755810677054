import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ResponsiveRow} from 'src/components/common/ResponsiveRow';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {AdditionalMetric, AdditionalMetricKey} from 'src/enums/display/DisplayAds';
import {toLocale} from 'src/components/facebook/grade/table/utils';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {StyledBorderedColumn as StyledColumn} from 'src/components/common/styled/StyledBorderedColumn';

type Props = {
  metrics: Record<AdditionalMetric, number>;
}

export const AdditionalMetrics = ({metrics}: Props): JSX.Element => {
  const {t} = useTranslation([GoogleAdType.DISPLAY]);

  return (
    <ResponsiveRow breakpoint={600} sx={{width: '100%'}}>
      <FlexBoxColumn sx={{alignItems: 'flex-start', alignSelf: 'stretch', width: '100%', gap: 2}}>
        <BoldTypography variant='h6'>
          {t('grade.displayAdTypes.additionalMetrics.title')}
        </BoldTypography>
        <FlexBoxRow sx={{width: '100%', gap: 1}}>
          {
            Object.keys(AdditionalMetric)
              .map((metricKey) => <StyledColumn key={metricKey}>
                <BoldTypography variant='caption' textAlign={'center'}>
                  {t(`grade.displayAdTypes.additionalMetrics.${AdditionalMetric[`${metricKey}` as AdditionalMetricKey]}`)}
                </BoldTypography>
                <Typography variant="body1" >
                  {toLocale(metrics[AdditionalMetric[metricKey as AdditionalMetricKey]])}
                </Typography>
              </StyledColumn>)
          }
        </FlexBoxRow>
      </FlexBoxColumn>
    </ResponsiveRow>
  );
};
