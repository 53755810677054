import {createContext, useState, Dispatch, SetStateAction, ReactNode} from 'react';

export interface ILoadingContext {
  isApiComplete: boolean;
  isProgressBarComplete: boolean;
  isProgressBarRunning: boolean;
  setApiComplete: Dispatch<SetStateAction<boolean>>;
  setProgressBarComplete: Dispatch<SetStateAction<boolean>>;
  setProgressBarRunning: Dispatch<SetStateAction<boolean>>;
}

export const LoadingContext = createContext<ILoadingContext>({
  isApiComplete: false,
  isProgressBarComplete: false,
  isProgressBarRunning: false,
} as ILoadingContext);

type Props = {
  children: ReactNode;
}

export const LoadingProvider = ({children}: Props): JSX.Element => {
  const [isApiComplete, setApiComplete] = useState(false);
  const [isProgressBarComplete, setProgressBarComplete] = useState(false);
  const [isProgressBarRunning, setProgressBarRunning] = useState(false);
  
  return (
    <LoadingContext.Provider value={{isApiComplete, isProgressBarRunning, isProgressBarComplete, setApiComplete, setProgressBarComplete, setProgressBarRunning}}>
      {children}
    </LoadingContext.Provider>
  );
};
