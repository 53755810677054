import {useEffect} from 'react';
import useSWR from 'swr';
import {IErrorResponse} from 'src/types/common/Error';
import {IDisplayGrade} from 'src/types/display/DisplayGrade';
import {useGGContext} from 'src/components/providers/googleAds';

type ReturnType = {
  displayGrade: IDisplayGrade | undefined;
  displayError: IErrorResponse | undefined;
}

export const useDisplayGrade = (uuid: string | undefined): ReturnType => {
  const context = useGGContext();
  const URL = `${process.env.REACT_APP_BACKEND_URL}/google/grade/display`;

  const {data: gradeData, error: gradeError} = useSWR<IDisplayGrade>(
    uuid ? `${URL}/${uuid}` : null
  );

  useEffect(() => {
    if (gradeData !== undefined) {
      let displayName = gradeData.overallScore.accountId;
      if (gradeData.overallScore.accountName) {
        displayName = gradeData.overallScore.accountName + ' (' + gradeData.overallScore.accountId + ')';
      }
      context.setGoogleAdsAccount({accountId: gradeData.overallScore.accountId!, accountName: displayName});
    }
  }, [gradeData]);

  return {
    displayGrade: gradeData,
    displayError: gradeError
  };
};
