import React, {useMemo} from 'react';
import {OverallMetrics} from './OverallMetrics';
import {PerformanceTracker} from './tracker/PerformanceTracker';
import {OverallMetricsTabName} from 'src/enums/display/MetricsTabName';
import {SpendBreakdown} from './SpendBreakdown';

type IProps = {
  selected: OverallMetricsTabName;
}

type ReturnType = {
  TabsPanels: JSX.Element[];
}

const renderPanelContent = (tabKey: OverallMetricsTabName): JSX.Element => {
  let value: JSX.Element;
  switch (tabKey) {
    case OverallMetricsTabName.PerformanceMetrics:
      value = <OverallMetrics />;
      break;
    case OverallMetricsTabName.SpendBreakdown:
      value = <SpendBreakdown />;
      break;
    case OverallMetricsTabName.PerformanceTracker:
      value = <PerformanceTracker />;
      break;
  }
  return value!;
};

export const useTabPanels = ({selected}: IProps): ReturnType => {
  const TabsPanels = useMemo(() => {
    return Object.values(OverallMetricsTabName).map((tabKey) => {
      return <React.Fragment key={tabKey}>
        {selected === tabKey && renderPanelContent(tabKey)}
      </React.Fragment>;
    }) as JSX.Element[];

  }, [selected]);

  return {TabsPanels};
};
