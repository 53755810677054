import {useSignIn} from 'src/hooks/common/useSignIn';
import {Box} from '@mui/material';
import {GoogleIconSignIn} from './GoogleIconSignIn';

const GoogleSignIn = (): JSX.Element => {
  const {signIn} = useSignIn({signTo: 'google'});

  return (
    <Box onClick={signIn}>
      <GoogleIconSignIn />
    </Box>
  );
};

export default GoogleSignIn;
