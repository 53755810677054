import { Typography, TableRow, TableCell, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableRowMobile } from '../TableRowMobile';
import { toLocale } from '../utils';
import { ServiceType } from 'src/enums/ServiceType';
import { FontWeight } from 'src/components/theme/orion/overrides/FontWeight';
import { OrionColors } from 'src/components/theme/orion/common/colors';

type Props = {
  title: string;
  campaigns: number;
  boldTitle?: boolean;
  impressions?: number;
  boldImpressions?: boolean;
  clicks?: number;
  boldClicks?: boolean;
  results?: number;
  boldResults?: boolean;
  sx?: SxProps;
};

export const CustomerJourneyRow = ({
  title,
  campaigns,
  impressions,
  boldImpressions,
  clicks,
  boldClicks,
  results,
  boldResults,
  sx,
}: Props): JSX.Element => {
  return (
    <>
      <TableRow>
        <TableCell sx={{ ...sx, pb: 0, fontWeight: 600 }} colSpan={4}>
          {title}
        </TableCell>
      </TableRow>
      <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
        <TableCell sx={{ ...sx, pt: 0 }}>
          {campaigns} {campaigns > 1 || campaigns === 0 ? 'Campaigns' : 'Campaign'}
        </TableCell>
        <TableCell sx={{ ...sx, pt: 0, fontWeight: boldImpressions ? 600 : 400 }} align="right">
          {toLocale(impressions)}
        </TableCell>
        <TableCell sx={{ ...sx, pt: 0, fontWeight: boldClicks ? 600 : 400 }} align="right">
          {toLocale(clicks)}
        </TableCell>
        <TableCell sx={{ ...sx, pt: 0, fontWeight: boldResults ? 600 : 400 }} align="right">
          {toLocale(results)}
        </TableCell>
      </TableRow>
    </>
  );
};

export const CustomerJourneyRowMobile = ({
  title,
  boldTitle,
  campaigns,
  impressions,
  boldImpressions,
  clicks,
  boldClicks,
  results,
  boldResults,
  sx,
}: Props): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);

  return (
    <>
      <TableRow>
        <TableCell colSpan={2} sx={{ ...sx }}>
          <Typography variant="body2" fontWeight={boldTitle ? FontWeight.SemiBold : FontWeight.Regular}>
            {title}
          </Typography>
          <Typography variant="body2">
            {campaigns} {campaigns > 1 ? 'Campaigns' : 'Campaign'}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRowMobile
        text={t('grade.campaignGoals.table.customerJourney.awarenessStage')}
        caption={t('grade.campaignGoals.table.customerJourney.impressions')}
        value={toLocale(impressions)}
        isBold={boldImpressions}
      />
      <TableRowMobile
        text={t('grade.campaignGoals.table.customerJourney.considerationStage')}
        caption={t('grade.campaignGoals.table.customerJourney.clicks')}
        value={toLocale(clicks)}
        isBold={boldClicks}
      />
      <TableRowMobile
        text={t('grade.campaignGoals.table.customerJourney.conversionStage')}
        caption={t('grade.campaignGoals.table.customerJourney.results')}
        value={toLocale(results)}
        isBold={boldResults}
      />
    </>
  );
};
