import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import googleGraderEnTranslations from './locale/googleAds/en.json';
import commonEnTranslations from './locale/common/en.json';
import keywordsEnTranslations from './locale/keywords/en.json';
import facebookGraderTranslations from './locale/facebook/en.json';
import displayAdsTranslations from './locale/displayAds/en.json';
import shoppingTranslations from './locale/shopping/en.json';
import adGeneratorTranslations from './locale/adGenerator/en.json';

const resources = {
  en: {
    google: googleGraderEnTranslations,
    common: commonEnTranslations,
    keywords: keywordsEnTranslations,
    facebook: facebookGraderTranslations,
    display: displayAdsTranslations,
    shopping: shoppingTranslations,
    adGenerator: adGeneratorTranslations,
  },
};

i18n.use(initReactI18next).init({
  lng: 'en',
  resources,
  interpolation: {
    escapeValue: true, // not needed for react as it escapes by default
  },
});

export default i18n;
