export enum DisplayMetricsTabName {
  PerformanceMetrics = 'performanceMetrics',
  SizeOfAccount = 'sizeOfAccount',
  PerformanceTracker = 'performanceTracker'
}

export enum OverallMetricsTabName {
  PerformanceMetrics = 'performanceMetrics',
  SpendBreakdown = 'spendBreakdown',
  PerformanceTracker = 'performanceTracker'
}
