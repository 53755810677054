import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ScoreName} from 'src/enums/ScoreName';
import {GoogleAdsScoreSection} from 'src/enums/google/ScoreSection';
import {GradeUtils} from 'src/utils/GradeUtils';
import {useGoogleGrade} from 'src/hooks/google/useGoogleGrade';
import {ServiceType} from 'src/enums/ServiceType';
import {IGoogleGrade} from 'src/types/google/GoogleGrade';
import {GoogleAdTypeContext} from 'src/components/providers/adType';
import {ISODateParser} from 'src/utils/helpers';
import {useOverallGrade} from 'src/hooks/overall/useOverallGrade';

const EMPTY_CHAR = '\u00a0';

type Props = {
  sectionName?: GoogleAdsScoreSection;
}

export type GGradeScoreType = {
  scoreName: ScoreName | undefined;
  scoreValue: number | string;
  searchGrade: IGoogleGrade | undefined;
  date: string;
}

const getAverageQualityScore = (UUID: string | undefined, doFetch: boolean) => {
  return doFetch ? useOverallGrade(UUID) : undefined;
};

export const useGGradeScore = ({sectionName}: Props): GGradeScoreType => {
  const {UUID} = useParams();
  const {grade} = useGoogleGrade(UUID);
  const {googleAdType} = useContext(GoogleAdTypeContext);
  const [scoreName, setScoreName] = useState<ScoreName | undefined>();
  const [scoreValue, setScoreValue] = useState<number | string>(EMPTY_CHAR);
  const [date, setDate] = useState<string>(new Date().toLocaleDateString(process.env.REACT_APP_LOCALE));

  const shouldFetchOverallGrade = sectionName === GoogleAdsScoreSection.QUALITY_SCORE;
  const overallGradeData = getAverageQualityScore(UUID, !!grade && shouldFetchOverallGrade);

  useEffect(() => {
    if (!grade) return;

    if (sectionName) {
      setScoreName(GradeUtils.getScoreName(grade, ServiceType.GOOGLE, sectionName, googleAdType));
    } else {
      setScoreName(GradeUtils.getScoreName(grade, ServiceType.GOOGLE, undefined, googleAdType));
    }

    if (sectionName) {
      setScoreValue(GradeUtils.getScoreValue(sectionName, grade, ServiceType.GOOGLE, googleAdType));
    } else {
      setScoreValue(GradeUtils.roundoff(grade.overallScore.overallScore, 0));
    }

    if (grade!.overallScore.reportDate) {
      setDate(ISODateParser(grade.overallScore.reportDate).toLocaleDateString(process.env.REACT_APP_LOCALE));
    }

    if (overallGradeData) {
      const averageQualityScore = overallGradeData.overallGrade?.searchScores.averageQualityScore;
      if (averageQualityScore !== undefined) {
        setScoreValue(averageQualityScore);
      }
    }
  }, [grade, overallGradeData]);

  return {scoreName, scoreValue, searchGrade: grade, date};
};
