import { NavBarContainer } from 'src/components/common/NavBarContainer';
import { FlexBoxColumn } from '../common/FlexBoxColumn';
import { useMediaQuery } from '@mui/material';
import { OrionTheme } from '../theme/orion/Theme';
import React from 'react';
import styled from 'styled-components';

const BackgroundLocal = styled(FlexBoxColumn)(({ theme }) => ({
  background: `
  linear-gradient(
    142deg,
    ${theme.palette.info.dark} 0%,
    ${theme.palette.primary.main} 29.25%,
    #04009D 82.27%
  )
  `,
}));

export const KeywordsBackground: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }): JSX.Element => {
  const isMobileOrTablet = useMediaQuery(OrionTheme.breakpoints.down('md'));
  return (
    <NavBarContainer>
      <BackgroundLocal sx={{ alignSelf: 'stretch', py: isMobileOrTablet ? 5 : 10 }}>{children}</BackgroundLocal>
    </NavBarContainer>
  );
};
