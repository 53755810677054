import {useCallback, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

type ReturnType = {
  signIn: () => void;
}

type Props = {
  signTo: string;
}

export const useSignIn = ({signTo}: Props): ReturnType => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const cid = searchParams.get('cid');
  const reformedEmail = useMemo(() => email ? email.replaceAll(' ', '%2B') : email, [email]);
  let URL = `${process.env.REACT_APP_BACKEND_URL}/${signTo}/auth?email=${reformedEmail}`;
  
  if (cid) URL = `${URL}&cid=${cid}`;

  const signIn = useCallback(() => {
    if (reformedEmail) {
      window.location.href = URL;
    }
  }, [reformedEmail, URL]);

  return {signIn};
};
