import {ChartOptions} from 'chart.js';
import {getResponsiveChartFont} from 'src/utils/helpers';

// Settings applied to all Charts
export const commonChartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: 0
  },
  scales: {
    x: {
      ticks: {
        padding: 16,
        font: getResponsiveChartFont
      }
    },
    y: {
      ticks: {
        padding: 16,
        font: getResponsiveChartFont
      }
    }
  },
  plugins: {
    legend: { // not using in-built legends
      display: false
    },
    tooltip: {
      enabled: false
    }
  }
};

