import {useTranslation} from 'react-i18next';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {styled, Typography} from '@mui/material';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';

const LegendIcon = styled(Typography)`
  display: inline-block;
  min-width: ${({theme}) => theme.spacing(3)};
  min-height: ${({theme}) => theme.spacing(3)};
  border-radius: 50%;
  background: ${({bgcolor}: {bgcolor: string}) => bgcolor};
`;

interface IDataItem {
  name: string;
  icon: string;
  color: string;
  percent: number;
}

type Props = {
  data: IDataItem[];
}

export const PlacementByDeviceLegends = ({data}: Props): JSX.Element => {
  const {t} = useTranslation(['facebook']);

  return (
    <FlexBoxColumn sx={{mb: 2}}>
      <FlexBoxRow>
        <FlexBoxColumn>
          <BoldTypography variant='body1' sx={{p: 1}}>
            {t('metrics.platformOverview.platformPlacement.legendHeader')}
          </BoldTypography>
          <Typography variant='body1' sx={{p: 1, textAlign: 'center'}}>
            {t('metrics.platformOverview.platformPlacement.legendSubheader')}
          </Typography>
        </FlexBoxColumn>
      </FlexBoxRow>
      <FlexBoxRow gap={1}>
        <>
          {
            data.map((item: IDataItem, index: number) => <FlexBoxColumn key={index} sx={{p: 2}}>
              <img src={item.icon} />
              <BoldTypography variant='caption' sx={{py: 0.5}}>
                {t(`metrics.platformOverview.platformPlacement.${item.name}`)}
              </BoldTypography>
              <LegendIcon bgcolor={item.color} />
              <Typography variant='h6' sx={{py: 0.5}}>
                {item.percent.toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 0})}%
              </Typography>
            </FlexBoxColumn>)
          }
        </>
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
