import React from 'react';
import {createRoot} from 'react-dom/client';
import TagManager from 'react-gtm-module';
import {App} from './App';
import './index.scss';
import './i18n';
import {HelmetProvider} from 'react-helmet-async';

if (process.env.REACT_APP_INSTANCE !== 'dev') {
  TagManager.initialize({
    gtmId: 'GTM-W7WSGJ3'
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<React.StrictMode>
  <HelmetProvider>
    <App />
  </HelmetProvider>
</React.StrictMode>);
