import i18n from 'src/i18n';
import {ServiceType} from 'src/enums/ServiceType';

/*
 Leaving this structure to reduce the tips and assign backgrounds even though we now have a static gradient bg
 It's impact on performance is negligible and we might want to go back to varying bg's
 */

const tipsData = Object.keys(i18n.t('tips.messages', {returnObjects: true, ns: ServiceType.GOOGLE}));
const gradientBackground = 'linear-gradient(97deg, #0046FF 7.27%, #04009D 36.73%, #000 100%)';

export const Tips = Array.from(tipsData).reduce((acc, curr) => {
  acc.push({message: curr, background: gradientBackground});
  return acc;
}, Array<{message: string; background: string}>());
