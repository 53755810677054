import {Typography} from '@mui/material';
import {SyntheticEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {useTabList} from './useTabList';
import {useTabPanels} from './useTabPanels';
import {DisplayMetrics} from './DisplayMetrics';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {PerformanceTracker} from './tracker/PerformanceTracker';
import {useParams} from 'react-router-dom';
import {useDisplayGrade} from 'src/hooks/display/useDisplayGrade';
import {SizeOfAccount} from 'src/components/common/metrics/SizeOfAccount';
import {TabsWrapper} from 'src/components/common/styled/TabsWrapper';
import {Tabs} from 'src/components/common/styled/Tabs';
import {DisplayMetricsTabName} from 'src/enums/display/MetricsTabName';
import {GoogleDisplayMetricsTabs} from 'src/enums/MetricTabType';
import {GradeAccordion} from 'src/components/common/grade/Accordion';

export const MetricsTabs = (): JSX.Element => {
  const {UUID} = useParams();
  const {displayGrade} = useDisplayGrade(UUID);
  const [selected, setSelected] = useState<DisplayMetricsTabName>(DisplayMetricsTabName.PerformanceMetrics);
  const handleChange = (event: SyntheticEvent<Element, Event>, value: DisplayMetricsTabName) => setSelected(value);
  const {TabList} = useTabList({onChange: handleChange});
  const {TabPanels} = useTabPanels({displayGrade, selected});

  return <TabsWrapper>
    <Tabs value={selected} onChange={handleChange}>
      {TabList}
    </Tabs>
    <>{TabPanels}</>
  </TabsWrapper>;
};

export const MetricsAccordions = (): JSX.Element => {
  const [expanded, setExpanded] = useState<string | false>(GoogleDisplayMetricsTabs.PERFORMANCE_METRICS);
  const {UUID} = useParams();
  const {displayGrade} = useDisplayGrade(UUID);
  const {t} = useTranslation([GoogleAdType.DISPLAY]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);

      setTimeout(() => {
        if (!isExpanded) return;
        document.getElementById(panel)?.
          scrollIntoView({behavior: 'smooth', block: 'start'});
      }, 100);
    };

  return <FlexBoxColumn sx={{width: '100%', gap: 2, mb: 2}}>
    <GradeAccordion
      id={t('metrics.performanceMetrics')}
      expanded={expanded === GoogleDisplayMetricsTabs.PERFORMANCE_METRICS}
      handleChange={handleChange(GoogleDisplayMetricsTabs.PERFORMANCE_METRICS)}
      header={<Typography variant="h3">{t('metrics.performanceMetrics')}</Typography>}
    >
      <DisplayMetrics />
    </GradeAccordion>
    <GradeAccordion
      id={t('metrics.sizeOfAccount')}
      expanded={expanded === GoogleDisplayMetricsTabs.SIZE_OF_ACCOUNT}
      handleChange={handleChange(GoogleDisplayMetricsTabs.SIZE_OF_ACCOUNT)}
      header={<Typography variant="h3">{t('metrics.sizeOfAccount')}</Typography>}
    >
      {
        displayGrade
          ? <SizeOfAccount
            activeAds={displayGrade.googleGradeDisplayAccountSize.activeAds}
            activeDisplayCampaigns={displayGrade.googleGradeDisplayAccountSize.activeDisplayCampaigns}
            activeAdGroups={displayGrade.googleGradeDisplayAccountSize.activeAdGroups}
            activeAudiences={displayGrade.googleGradeDisplayAccountSize.activeAudiences}
            averageAdGroupsPerCampaign={displayGrade.googleGradeDisplayAccountSize.averageAdGroupsPerCampaign}
            averageAdsPerAdGroup={displayGrade.googleGradeDisplayAccountSize.averageAdsPerAdGroup}
          />
          : <></>
      }
    </GradeAccordion>
    <GradeAccordion
      id={t('metrics.performanceTracker')}
      expanded={expanded === GoogleDisplayMetricsTabs.PERFORMANCE_TRACKER}
      handleChange={handleChange(GoogleDisplayMetricsTabs.PERFORMANCE_TRACKER)}
      header={<Typography variant="h3">{t('metrics.performanceTracker')}</Typography>}
    >
      <PerformanceTracker />
    </GradeAccordion>
  </FlexBoxColumn>;
};
