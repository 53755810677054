import {useState} from 'react';
import {FadeInTransition} from '../FadeInTransition';
import {FlexBoxColumn} from '../FlexBoxColumn';
import {GradeAccordion} from './Accordion';
import {GradeHeader} from './GradeHeader';
import {ScoreSectionType} from 'src/enums/google/ScoreSection';

type AccordionsProps = {
  defaultExpandedSection: string | undefined;
  scoreSections: ScoreSectionType[];
  renderSectionContent: (sectionName: ScoreSectionType) => JSX.Element;
};

export const GradeAccordions = ({defaultExpandedSection, scoreSections, renderSectionContent}: AccordionsProps): JSX.Element => {
  const [expanded, setExpanded] = useState<string | false>(defaultExpandedSection ?? false);
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean): void => {
    setExpanded(isExpanded ? panel : false);
    setTimeout(() => {
      if (!isExpanded) return;
      document.getElementById(panel)?.scrollIntoView({behavior: 'smooth', block: 'start'});
    }, 100);
  };

  return (
    <FadeInTransition triggerAfter={750}>
      <FlexBoxColumn sx={{gap: 2}}>
        {
          scoreSections.map((sectionName) =>
            <GradeAccordion id={sectionName}
              expanded={expanded === sectionName}
              handleChange={handleChange(sectionName)}
              key={`${sectionName}-accordion`}
              header={<GradeHeader sectionName={sectionName} />}
            >
              {renderSectionContent(sectionName)}
            </GradeAccordion>
          )
        }
      </FlexBoxColumn>
    </FadeInTransition>
  );
};
