import { Button, Link, useMediaQuery } from '@mui/material';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { Trans, useTranslation } from 'react-i18next';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { useContext } from 'react';
import { ServiceContext } from 'src/components/providers/service';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { ResponsiveRow } from '../../ResponsiveRow';
import styled from 'styled-components';

const ExternalLink = styled(Button)<{ target?: string }>`
  text-decoration: none;
  text-align: center;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    white-space: nowrap;
  }
`;

ExternalLink.defaultProps = {
  target: '_blank',
};

interface IProps {
  stretched: boolean;
}

export const ContactUsSection = ({ stretched }: IProps): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const { t } = useTranslation([service]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));

  return (
    <ResponsiveRow
      breakpoint={'sm'}
      sx={{
        alignSelf: stretched ? 'stretch' : 'center',
        alignItems: isMobile ? 'flex-start' : 'center',
        mt: isMobile ? 2 : 0,
        gap: 3,
      }}
    >
      <FlexBoxColumn sx={{ flex: '1 1 0' }}>
        {/* To force break at specific point for large screens only & let flex handle for smaller screens */}
        <BoldTypography
          variant="h4"
          color={'common.white'}
          sx={{ width: '100%', whiteSpace: isMobile ? 'wrap' : 'pre' }}
        >
          <Trans t={t} i18nKey={'grade.header.contactUsMessage'} />
          {isMobile && <Trans t={t} i18nKey={'grade.header.contactUsMessage2'} />}
        </BoldTypography>
        {!isMobile && (
          <BoldTypography variant="h4" color={'common.white'} sx={{ width: '100%' }}>
            <Trans t={t} i18nKey={'grade.header.contactUsMessage2'} />
          </BoldTypography>
        )}
      </FlexBoxColumn>
      <ExternalLink
        variant="contained"
        color="white"
        LinkComponent={Link}
        size={isMobile ? 'medium' : 'large'}
        href={t('grade.header.improveYourGradeUrl')}
        aria-label="Improve Your Grade Link"
      >
        {t('grade.header.improveYourGrade')}
      </ExternalLink>
    </ResponsiveRow>
  );
};
