import {Typography} from '@mui/material';
import {SyntheticEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {useTabList} from './useTabList';
import {useTabPanels} from './useTabPanels';
import {PerformanceTracker} from './tracker/PerformanceTracker';
import {OverallMetrics} from './OverallMetrics';
import {TabsWrapper} from 'src/components/common/styled/TabsWrapper';
import {Tabs} from 'src/components/common/styled/Tabs';
import {OverallMetricsTabName} from 'src/enums/display/MetricsTabName';
import {GoogleAdsMetricsTabs, GoogleOverallMetricsTabs} from 'src/enums/MetricTabType';
import {GradeAccordion} from 'src/components/common/grade/Accordion';
import {SpendBreakdown} from './SpendBreakdown';
import {ServiceType} from 'src/enums/ServiceType';
import {useOverallSpend} from 'src/hooks/overall/useOverallSpend';
import {useParams} from 'react-router-dom';

export const MetricsTabs = (): JSX.Element => {
  const [selected, setSelected] = useState<OverallMetricsTabName>(OverallMetricsTabName.PerformanceMetrics);
  const handleChange = (event: SyntheticEvent<Element, Event>, value: OverallMetricsTabName) => setSelected(value);
  const {TabList} = useTabList({onChange: handleChange});
  const {TabsPanels} = useTabPanels({selected});

  return <TabsWrapper>
    <Tabs value={selected} onChange={handleChange}>
      {TabList}
    </Tabs>
    <div role='tabpanel' style={{minWidth: '500px'}}>{TabsPanels}</div>
  </TabsWrapper>;
};

export const MetricsAccordions = (): JSX.Element => {
  const [expanded, setExpanded] = useState<string | false>(GoogleAdsMetricsTabs.PERFORMANCE_METRICS);
  const {t} = useTranslation([ServiceType.GOOGLE]);
  const {UUID} = useParams();
  const {spendData} = useOverallSpend(UUID);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);

      setTimeout(() => {
        if (!isExpanded) return;
        document.getElementById(panel)?.
          scrollIntoView({behavior: 'smooth', block: 'start'});
      }, 100);
    };

  return <FlexBoxColumn sx={{width: '100%', gap: 2, mb: 2}}>
    <GradeAccordion
      id={t('metrics.performanceMetrics')}
      expanded={expanded === GoogleOverallMetricsTabs.PERFORMANCE_METRICS}
      handleChange={handleChange(GoogleOverallMetricsTabs.PERFORMANCE_METRICS)}
      header={<Typography variant="h3">{t('metrics.performanceMetrics')}</Typography>}
    >
      <OverallMetrics />
    </GradeAccordion>
    { spendData &&
      <GradeAccordion
        id={t('metrics.spendBreakdown')}
        expanded={expanded === GoogleOverallMetricsTabs.SPEND_BREAKDOWN}
        handleChange={handleChange(GoogleOverallMetricsTabs.SPEND_BREAKDOWN)}
        header={<Typography variant="h3">{t('metrics.spendBreakdown')}</Typography>}
      >
        <SpendBreakdown />
      </GradeAccordion>}
    <GradeAccordion
      id={t('metrics.performanceTracker')}
      expanded={expanded === GoogleOverallMetricsTabs.PERFORMANCE_TRACKER}
      handleChange={handleChange(GoogleOverallMetricsTabs.PERFORMANCE_TRACKER)}
      header={<Typography variant="h3">{t('metrics.performanceTracker')}</Typography>}
    >
      <PerformanceTracker />
    </GradeAccordion>
  </FlexBoxColumn>;
};
