import {useContext, useEffect, useState} from 'react';
import useSWR from 'swr';
import {useParams} from 'react-router-dom';
import {postSubscribers, SubscriptionInfo} from 'src/utils/helpers';
import {ServiceContext} from 'src/components/providers/service';

type ReturnType = {
  subscribed: boolean;
  onToggle: () => void;
};

export const useMetricsSubscribed = (): ReturnType => {
  const {UUID} = useParams();
  const [subscribed, setSubscribed] = useState(false);
  const [service] = useContext(ServiceContext);
  const {data} = useSWR(`${process.env.REACT_APP_BACKEND_URL}/${service}/${UUID}/subscribers`, {onError: () => null});
  const onToggle = () => {
    setSubscribed(!subscribed);
    if (UUID) postSubscribers(!subscribed, service, UUID);
  };

  useEffect(() => {
    if (data) {
      setSubscribed((data as SubscriptionInfo).subscribed);
    }
  }, [data]);

  return {subscribed, onToggle};
};
