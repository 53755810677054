import {useCallback, useContext, useEffect, useState} from 'react';
import {KeywordsContext} from 'src/components/providers/keywords';

type ReturnType = {
  open: boolean;
  onClose: () => void;
}

const useRefineModal = (): ReturnType => {
  const [open, setOpen] = useState(true);
  const {status: {isLoading, error}} = useContext(KeywordsContext);

  useEffect(() => {
    if (isLoading || error) {
      setOpen(false);
    }
  }, [isLoading, error]);

  const onClose = useCallback(() => setOpen(false), []);


  return {open, onClose};
};

export default useRefineModal;
