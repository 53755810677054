import styled from 'styled-components';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { OrionColors } from 'src/components/theme/orion/common/colors';

export const StyledBorderedColumn = styled(FlexBoxColumn)`
  flex: 1 0 0;
  padding: ${({ theme }) => theme.spacing(1)};
  border: ${({ theme }) => theme.spacing(0.125)} solid ${OrionColors.gray.neutral};
  gap: ${({ theme }) => theme.spacing(1)};
  align-self: stretch;
  justify-content: space-between;
`;
