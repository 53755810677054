import {Dispatch, SetStateAction, useCallback, useContext} from 'react';
import {KeywordsContext} from 'src/components/providers/keywords';
import {RequestStatus} from 'src/types/common/RequestStatus';
import useSWR, {SWRResponse} from 'swr';

const FIFTEEN_SECS = 15 * 1000;
const MAX_RETRIES = 1;

type ExecuteQueryProps = {
  options: RequestInit;
}

type ReturnType<ResultType> = {
  executeQuery: (options: ExecuteQueryProps) => void;
  response: ResultType | null | undefined;
}

type Props = {
  url: string;
}

// credit to https://gist.github.com/good-idea/b36f9715aedee44b1ba44b938dc02224
const ResponseHandler = (
  url: string,
  response: Response,
  swrResponse: SWRResponse,
  options: RequestInit,
  setStatus: Dispatch<SetStateAction<RequestStatus>>,
  retryCount: number
) => {
  if (response.status === 200) {
    swrResponse.mutate(response.json(), false);
  } else if (response.status === 429 && retryCount < MAX_RETRIES) {
    setTimeout(
      async () => ResponseHandler(
        url,
        await fetch(url, options as RequestInit),
        swrResponse,
        options,
        setStatus,
        retryCount
      ),
      FIFTEEN_SECS
    );
    retryCount += 1;
  } else {
    response.json()
      .then((json) =>
        setStatus({error: json.error ?? null, isLoading: false, isDone: false}));
  }
};

export const useLazyRequest = <ResultType extends unknown>({url}: Props): ReturnType<ResultType> => {
  const {setStatus} = useContext(KeywordsContext);
  const swrResponse = useSWR<ResultType | null>(url, () => null);
  const executeQuery = useCallback(async ({options}: ExecuteQueryProps) => {
    const result = await fetch(url, options);
    ResponseHandler(url, result, swrResponse, options, setStatus, 0);
  }, [url]);

  return {
    executeQuery,
    response: swrResponse.data
  };
};
