import {ContentCard} from 'src/components/common/ContentCard';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {GridType} from 'src/enums/GridType';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet-async';
import {FacebookAdsPage} from 'src/components/facebook/page/Page';
import {FacebookSignIn} from 'src/components/facebook/FacebookSignIn';
import {PageTitle} from 'src/enums/PageTitle';
import {ServiceType} from 'src/enums/ServiceType';
import BadgeFooter from 'src/components/common/BadgeFooter';
import MetaPartnerSVG from 'src/assets/MetaPartner.svg';

export const FacebookAdsHome = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);

  return (
    <>
      <Helmet>
        <title>{PageTitle.FACEBOOK} | HOME</title>
      </Helmet>
      <FacebookAdsPage
        gridType={GridType.FORM}
        additionalFooter={
          <BadgeFooter iconHref={t('facebookBadgeLink')}
            i18nNameSpace={ServiceType.FACEBOOK}
            badgeSVG={MetaPartnerSVG} />
        }>
        <FlexBoxColumn>
          <ContentCard title={t('start.title')} body={t('start.body')}>
            <FacebookSignIn />
          </ContentCard>
        </FlexBoxColumn>
      </FacebookAdsPage>
    </>
  );
};
