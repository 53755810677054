import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import FormInputSelect from '../../common/forms/inputs/FormInputSelect';
import {FormProvider, useForm} from 'react-hook-form';
import {StyledCardWithPadding} from '../../common/styled/StyledCard';
import {FlexBoxColumn} from '../../common/FlexBoxColumn';
import {StyledFormLabel} from '../../common/forms/FormLabel';
import {useResponseHandler} from './useResponseHandler';
import {memo} from 'react';
import {StyledRowStack} from 'src/components/common/styled/StyledRowStack';
import {FormStepsNextButton} from 'src/components/common/styled/FormStepsNextButton';
import {ServiceType} from 'src/enums/ServiceType';

export const ChooseAccount: React.FC = memo(function ChooseAccount (): JSX.Element {
  const {t} = useTranslation([ServiceType.FACEBOOK]);
  const methods = useForm({
    defaultValues: {
      customerAccount: ''
    },
    mode: 'onChange'
  });

  const {accounts, onSubmit} = useResponseHandler();

  return (
    <FormProvider {...methods}>
      <form style={{width: '100%'}}>
        <FlexBoxColumn>
          <StyledCardWithPadding sx={{gap: 3}}>
            <FlexBoxColumn sx={{gap: 2}}>
              <Typography variant='h3'>{t('chooseAccount.title')}</Typography>
              <Typography variant='body1' sx={{textAlign: 'center'}}>{t('chooseAccount.body')}</Typography>
            </FlexBoxColumn>
            <FlexBoxColumn sx={{width: '100%', gap: 1}}>
              <StyledFormLabel error={!!methods.formState.errors['customerAccount']} data-testid='fbAccountSelectLabel'>
                {t('chooseAccount.selectLabel')}
              </StyledFormLabel>
              <FormInputSelect
                testid="fbAccountSelect"
                name="customerAccount"
                required={true}
                options={accounts}
              />
            </FlexBoxColumn>
            <StyledRowStack sx={{justifyContent: 'center'}}>
              <FormStepsNextButton onSubmit={onSubmit} id="fb-choose-account-next" />
            </StyledRowStack>
          </StyledCardWithPadding>
        </FlexBoxColumn>
      </form>
    </FormProvider>
  );
});
