import {ResponsiveRow} from 'src/components/common/ResponsiveRow';
import {useTranslation} from 'react-i18next';
import {ServiceType} from 'src/enums/ServiceType';
import {colors, List, ListItem, ListItemText, ListSubheader, styled, Typography} from '@mui/material';
import {OverviewList} from 'src/enums/facebook/AdPerformance';

export const NoBoxList = styled(List)`
  && {
    border: none;
    box-shadow: none;
  }
`;


export const Overview = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);

  return (
    <ResponsiveRow sx={{width: '100%', justifyContent: 'start', mb: -2}} breakpoint={600}>
      <NoBoxList
        sx={{
          listStyleType: 'disc',
          pl: 2
        }}
        subheader={
          <ListSubheader
            disableGutters
            component={ResponsiveRow}
            sx={{ml: -2}}
          >
            <Typography variant='body1' sx={{color: colors.common.black}} >{t('metrics.adPerformance.overview.description')}</Typography>
          </ListSubheader>
        }
      >
        {
          Object.keys(OverviewList)
            .map((listItem) =>
              <ListItem sx={{display: 'list-item', padding: 0}} key={listItem}>
                <ListItemText>
                  <Typography variant='body1' sx={{color: colors.common.black}}>
                    {t(`metrics.adPerformance.overview.${OverviewList[`${listItem}` as keyof typeof OverviewList]}`)}
                  </Typography>
                </ListItemText>
              </ListItem>
            )
        }
      </NoBoxList>
    </ResponsiveRow>
  );
};
