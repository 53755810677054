import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContentCard } from 'src/components/common/ContentCard';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { StyledSignInButton } from 'src/components/common/styled/StyledSignIn';
import { KeywordsBackground } from 'src/components/keywords/Background';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { useSearchParams } from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';

export const KeywordsNoRoute = (): JSX.Element => {
  const { t } = useTranslation('keywords');
  const [searchParams] = useSearchParams();
  const redirect = () => window.location.replace('https://www.wordstream.com/keywords');

  let errorTitleTranslationKey = 'title_noroute';
  let errorBodyTranslationKey = 'body_noroute';
  let buttonTextTranslationKey = 'getKeywords';

  if (searchParams.get('event') === 'linkExpired') {
    errorTitleTranslationKey = 'title_linkexpired';
    errorBodyTranslationKey = 'body_linkexpired';
    buttonTextTranslationKey = 'newSearch';
  }

  return (
    <KeywordsBackground>
      <Box mt={4} p={4}>
        <ContentCard title={t(`error.${errorTitleTranslationKey}`)} body={t(`error.${errorBodyTranslationKey}`)}>
          <Box sx={{ pb: 2 }}>
            <StyledSignInButton variant="contained" onClick={redirect} data-testid="keywordsButton">
              <FlexBoxRow gap={1}>
                <BoldTypography variant="h5" color={OrionTheme.palette.common.white}>
                  {t(`error.${buttonTextTranslationKey}`)}
                </BoldTypography>
                <LaunchIcon sx={{ color: OrionTheme.palette.common.white }} />
              </FlexBoxRow>
            </StyledSignInButton>
          </Box>
        </ContentCard>
      </Box>
    </KeywordsBackground>
  );
};
