import {IMetric} from 'src/components/providers/metricsData';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {ServiceType} from 'src/enums/ServiceType';

// We want only the numeric properties of the object IMetric as they are possible graph selections
type GoogleMetricKeys<T> = {
  [K in keyof T]: T[K] extends number ? K : never;
}[keyof T];
export type GoogleMetricType = Exclude<GoogleMetricKeys<IMetric>, undefined>;
export type FacebookMetricType = 'overallScore' | 'totalActions';
export type MetricType = GoogleMetricType | FacebookMetricType;

export const yAxisTitles: Record<ServiceType, Partial<Record<GoogleAdType, Partial<Record<MetricType, string>>>>> = {
  [ServiceType.GOOGLE]: {
    [GoogleAdType.OVERALL]: {
      overallScore: 'Overall Score',
      spend: 'Graded Spend',
    },
    [GoogleAdType.SEARCH]: {
      overallScore: 'Search Score',
      wastedSpend: 'Wasted Spend',
      qualityScore: 'Quality Score',
      accountActivity: 'Total Actions',
      impressionShare: 'Impression Share',
      cpa: 'Cost per Action',
      spend: 'Graded Spend',
    },
    [GoogleAdType.DISPLAY]: {
      overallScore: 'Display Score',
      ctr: 'Display CTR',
      viewableImpressions: 'Display Viewable Impressions',
      cpm: 'Display CPM',
      spend: 'Graded Spend',
    },
    [GoogleAdType.SHOPPING]: {
      overallScore: 'Shopping Score',
      wastedSpend: 'Wasted Spend',
      impressionShare: 'Impression Share',
      ctr: 'Clickthrough Rate',
      spend: 'Graded Spend',
    },
    [GoogleAdType.NONE]: {},
  },
  [ServiceType.FACEBOOK]: {
    [GoogleAdType.NONE]: {
      overallScore: 'Overall Score',
      totalActions: 'Total Activity',
    },
  },
  [ServiceType.NONE]: {},
};
