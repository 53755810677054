import { Box, Card, List, useMediaQuery } from '@mui/material';
import { useFAGradeScore } from 'src/hooks/facebook/useFAGradeScore';
import { useTranslation } from 'react-i18next';
import { FacebookAdsScoreSection } from 'src/enums/facebook/ScoreSection';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { GradeUtils } from 'src/utils/GradeUtils';
import { useMemo } from 'react';
import { ServiceType } from 'src/enums/ServiceType';
import {
  HowToImproveInfoDivider,
  HowToImproveListItems,
  HowToImproveListSubHeader,
} from 'src/components/common/grade/howTo/HowToImprove';

import Wrench from 'src/assets/WrenchIcon.svg';

type Props = {
  sectionName: FacebookAdsScoreSection;
};

export const HowToImprove = ({ sectionName }: Props): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const { grade, scoreName } = useFAGradeScore({ sectionName });
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));
  const howToKey = useMemo(
    () => GradeUtils.getHowToKey({ grade, sectionName, scoreName }),
    [grade, sectionName, scoreName],
  );
  const title = useMemo(() => t(`grade.${GradeUtils.getSectionKey(sectionName)}.howTo.${howToKey}`), [howToKey]);

  const suggestions: string[] = useMemo(
    () => t(`grade.${GradeUtils.getSectionKey(sectionName)}.howToSuggestions.${howToKey}`, { returnObjects: true }),
    [howToKey],
  );

  /*
   ** For the Campaign Goals we have a requirement to show how to improve tips based on a missing type (awareness/consideration/conversion).
   ** The 'getHowToKey' function checks if it's in the Campaign Goals section and returns the appropriate ScoreName (howToKey).
   ** For CampaignGoals, a ScoreName is Intermediate if it has 1 or 2 missing types, the Intermediate suggestions array contains all tips,
   ** so in order to show tips only for the missing types, I need to filter the suggestions array.
   */
  const filteredSuggestions = useMemo(
    () => GradeUtils.filterHowToSuggestions({ suggestions, grade, sectionName, scoreName: howToKey }),
    [suggestions],
  );

  return (
    <Card sx={{ borderRadius: 0, borderColor: OrionTheme.palette.info.main, p: isMobile ? 3 : 4, width: '100%' }}>
      {isMobile ? (
        <Box sx={{ pb: 2 }}>
          <HowToImproveInfoDivider>
            <img src={Wrench} />
          </HowToImproveInfoDivider>
        </Box>
      ) : (
        <></>
      )}
      <List
        sx={{
          listStyleType: 'none',
          paddingBottom: 0,
          border: 0,
          boxShadow: 0,
        }}
        subheader={HowToImproveListSubHeader({ title, isMobile })}
      >
        {HowToImproveListItems({ items: filteredSuggestions, isMobile })}
      </List>
    </Card>
  );
};
