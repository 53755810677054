import {SyntheticEvent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {MetricsTab} from 'src/components/common/metrics/MetricsTab';
import {ShoppingMetricsTabName} from 'src/enums/shopping/MetricsTabName';
import {GoogleAdType} from 'src/enums/GoogleAdType';

type Props = {
  onChange: (event: SyntheticEvent<Element, Event>, value: ShoppingMetricsTabName) => void;
}

type ReturnType = {
  TabList: JSX.Element[];
}

export const useTabList = ({onChange}: Props): ReturnType => {
  const {t} = useTranslation([GoogleAdType.SHOPPING]);

  const TabList = useMemo(() => {
    return Object.values(ShoppingMetricsTabName).map((tabKey) => {
      return <MetricsTab name={t(`metrics.${tabKey}`)} key={tabKey} value={tabKey} onChange={() => onChange} splitLabelText={true} />;
    }) as JSX.Element[];

  }, []);

  return {TabList};
};
