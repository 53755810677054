import React, {Component} from 'react';
import {Chart, ChartDataset, ChartOptions} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {LabelOptions} from 'chartjs-plugin-datalabels/types/options';

export interface IDoughnutChartProps {
  dataset: number[];
  doughnutStyling?: Partial<ChartDataset<'doughnut'>>;
  isMobile?: boolean;
  labels?: LabelOptions;
  options?: ChartOptions;
}

export const labelOptions = (isMobile: boolean): LabelOptions => ({
  anchor: 'end',
  align: 'end',
  color: '#000',
  offset: 0,
  font: {
    family: 'Unify Sans',
    weight: 'bolder',
    size: isMobile
      ? 12 : 16,
    lineHeight: isMobile
      ? '16px'
      : '24px'
  }
});

export class DoughnutChart extends Component<IDoughnutChartProps> {
  canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();

  chart: Chart | undefined;

  drawChart (): void {
    const canvasContext = this.canvasRef.current?.getContext('2d') as CanvasRenderingContext2D;

    this.chart = new Chart(canvasContext, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            type: 'doughnut',
            data: this.props.dataset.filter((val) => val > 0),
            ...this.props.doughnutStyling,
            datalabels: {
              ...labelOptions(this.props.isMobile as boolean),
            }
          }
        ]
      },
      plugins: [
        ChartDataLabels
      ],
      options: {
        ...this.props.options,
        layout: {
          padding: {
            top: this.props.isMobile ? 16 : 24,
            bottom: this.props.isMobile ? 16 : 24,
            left: this.props.isMobile ? 16 : 24,
            right: this.props.isMobile ? 16 : 24
          },
        },
        plugins: {
          datalabels: {
            ...this.props.labels
          }
        },
        scales: {
          y: {
            grid: {
              drawTicks: false,
              drawOnChartArea: false,
              drawBorder: false
            },
            ticks: {
              display: false
            }
          }
        }
      }
    });
  }

  componentDidMount (): void {
    this.chart?.destroy();
    this.drawChart();
  }

  componentDidUpdate (): void {
    this.chart?.destroy();
    this.drawChart();
  }

  render (): JSX.Element {
    return (
      <canvas
        id='chart'
        ref={this.canvasRef}
        style={{width: '100%', height: '280px'}}
      />
    );
  }
}
