import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {IEmailReportResponse} from 'src/types/common/IEmailReport';

type ReturnType = {
  openModal: boolean;
  handleFacebookRefresh: () => void;
  handleGoogleRefresh: () => void;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  emailResponse: IEmailReportResponse | null;
  handleEmailResponse: (status: IEmailReportResponse) => void;
}

export const usePageContent = (): ReturnType => {
  const [openModal, setOpenModal] = useState(false);
  const [emailResponse, setEmailResponse] = useState<IEmailReportResponse | null>(null);
  const {UUID} = useParams();

  const handleEmailResponse = (emailStatus: IEmailReportResponse) => {
    setEmailResponse(emailStatus);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleFacebookRefresh = () => {
    window.location.replace(`/facebook-grader/report/${UUID}/refresh?salesOnly=false`);
  };

  const handleGoogleRefresh = () => {
    window.location.replace(`/google-grader/report/${UUID}/refresh?salesOnly=false`);
  };

  return {openModal, handleOpenModal, handleCloseModal, handleGoogleRefresh, handleFacebookRefresh, emailResponse, handleEmailResponse};
};
