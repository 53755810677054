import { useTranslation } from 'react-i18next';
import { Typography, useMediaQuery } from '@mui/material';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { LineHeight } from 'src/components/theme/orion/overrides/LineHeight';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { FlexBoxColumn } from '../../../common/FlexBoxColumn';
import { ChartLabel } from 'src/components/common/grade/charts/Labels';

export const ImpressionShareLabels = (): JSX.Element => {
  const { t } = useTranslation([ServiceType.GOOGLE]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));

  return (
    <FlexBoxColumn
      gap={isMobile ? 0.5 : 1}
      sx={{ alignItems: 'flex-start', alignSelf: 'stretch', pl: isMobile ? 4 : 0, mt: isMobile ? -2 : 0 }}
    >
      <ChartLabel bcolor={OrionColors.chart.green}>
        <Typography sx={{ pl: 1.5 }} fontSize={isMobile ? '0.75rem' : '1rem'} lineHeight={LineHeight.LOOSE}>
          {t('grade.impressionShare.chart.shareAcquired')}
        </Typography>
      </ChartLabel>
      <ChartLabel bcolor={OrionColors.chart.blue}>
        <Typography sx={{ pl: 1.5 }} fontSize={isMobile ? '0.75rem' : '1rem'} lineHeight={LineHeight.LOOSE}>
          {t('grade.impressionShare.chart.shareLostToBudget')}
        </Typography>
      </ChartLabel>
      <ChartLabel bcolor={OrionColors.chart.orange}>
        <Typography sx={{ pl: 1.5 }} fontSize={isMobile ? '0.75rem' : '1rem'} lineHeight={LineHeight.LOOSE}>
          {t('grade.impressionShare.chart.shareLostToAdRank')}
        </Typography>
      </ChartLabel>
    </FlexBoxColumn>
  );
};
