import {createContext, Dispatch, SetStateAction, useState} from 'react';

export type IMetric = {
  uuid: string;
  reportDate: string;
  overallScore: number;
  wastedSpend: number;
  qualityScore: number;
  accountActivity: number;
  impressionShare: number;
  cpa: number;
  ctr: number;
  cpm: number;
  viewableImpressions: number;
  spend: number;
}

export type MetricsResponse = {
  userId: string;
  companyId: string;
  customerId: number;
  grades: IMetric[];
}

export const MetricsDataContext = createContext<[MetricsResponse | undefined | null, Dispatch<SetStateAction<MetricsResponse | undefined>>]>([undefined, () => null]);

type Props = {
  children: JSX.Element | JSX.Element[];
}

export const MetricsDataProvider = ({children}: Props): JSX.Element => {
  const state = useState<MetricsResponse | undefined>();

  return <MetricsDataContext.Provider value={state}>{children}</MetricsDataContext.Provider>;
};
