import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ContentCard} from 'src/components/common/ContentCard';
import {GradeMyAccountBtn} from 'src/components/common/GradeMyAccountBtn';
import {KeywordsBackground} from 'src/components/keywords/Background';
import {ServiceType} from 'src/enums/ServiceType';

export const NotFound = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.GOOGLE]);

  return (
    <KeywordsBackground>
      <Box mt={4} p={4}>
        <ContentCard title={t('error.title_noroute')} body={t('error.body_noroute')}>
          <GradeMyAccountBtn />
        </ContentCard>
      </Box>
    </KeywordsBackground>
  );
};
