import {styled, Table, Typography, TableBody, TableContainer, TableHead} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {KeywordTHead, KeywordTRow} from './KeywordTRow';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {useContext} from 'react';
import {KeywordsContext} from 'src/components/providers/keywords';

const StyledTableContainer = styled(TableContainer)({
  boxShadow: 'unset'
});

const StyledNoDataCaption = styled(FlexBoxRow)(({theme}) => ({
  marginTop: theme.spacing(2)
}));

const KeywordTable = (): JSX.Element => {
  const {t} = useTranslation(['keywords']);
  const {keywords} = useContext(KeywordsContext);

  return (
    <StyledTableContainer>
      <Table>
        <TableHead>
          <KeywordTHead />
        </TableHead>
        <TableBody>
          {keywords && keywords.keywordCount > 0 && keywords.keywords.map((row) => <KeywordTRow key={row.keywordText} row={row} />)}
        </TableBody>
      </Table>
      {(!keywords || keywords.keywordCount === 0) && <StyledNoDataCaption>
        <Typography variant='caption' color='primary.main' fontWeight={'fontWeightMedium'}>{t('table.body.empty')}</Typography>
      </StyledNoDataCaption>}
    </StyledTableContainer>
  );
};

export default KeywordTable;
