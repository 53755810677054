import { Divider, Typography, useMediaQuery } from '@mui/material';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { StyledCard } from 'src/components/common/styled/StyledCard';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';

type Props = {
  caption: string;
  score: string | number | undefined;
  color: string;
  text: string;
};

export const CTRResultRow = ({ caption, score, color, text }: Props): JSX.Element => {
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));

  return (
    <StyledCard sx={{ width: '100%', p: 0 }}>
      <ResponsiveRow
        breakpoint={600}
        sx={{ width: '100%', height: '100%', justifyContent: 'stretch', alignItems: 'stretch' }}
      >
        <FlexBoxColumn
          gap={1}
          sx={{ width: isMobile ? '100%' : '35%', px: isMobile ? 0 : 5, py: isMobile ? 2 : 3, background: color }}
        >
          <BoldTypography variant="h5" textAlign={'center'} sx={{ whiteSpace: isMobile ? 'normal' : 'pre' }}>
            {caption}
          </BoldTypography>
          <BoldTypography variant="h5">{score ?? '-'}%</BoldTypography>
        </FlexBoxColumn>
        <Divider
          orientation={isMobile ? 'horizontal' : 'vertical'}
          flexItem={true}
          sx={{ borderColor: OrionColors.gray.dark }}
        />
        <FlexBoxRow>
          <Typography textAlign={isMobile ? 'center' : 'left'} flex={1} variant="body1" sx={{ px: 3, py: 5 }}>
            {text}
          </Typography>
        </FlexBoxRow>
      </ResponsiveRow>
    </StyledCard>
  );
};
