import React from 'react';
import {Card, Theme, useMediaQuery, useTheme} from '@mui/material';
import {KeywordAccordion} from './accordion/KeywordAccordion';
import Header from './header/Header';
import KeywordPagination from './Pagination';
import KeywordTable from './table/KeywordTable';
import useScrollToElement from './useScrollToElement';

const SearchResult = (): JSX.Element => {
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery((theme as Theme).breakpoints.down(600));
  const {elementRef} = useScrollToElement();

  return (
    <Card sx={{p: isMobile ? 3 : 5, width: '100%'}} ref={elementRef as React.RefObject<HTMLDivElement>}>
      <Header />
      {
        isMobile
          ? <KeywordAccordion />
          : <KeywordTable />
      }
      <KeywordPagination />
    </Card>
  );
};

export default SearchResult;
