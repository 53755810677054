import { Typography, useMediaQuery } from '@mui/material';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ServiceType } from 'src/enums/ServiceType';
import { PerformanceMetrics } from 'src/components/common/metrics/PerformanceMetrics';
import { useOverallGrade } from 'src/hooks/overall/useOverallGrade';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { OrionTheme } from 'src/components/theme/orion/Theme';

export const OverallMetrics = (): JSX.Element => {
  const { UUID } = useParams();
  const { overallGrade } = useOverallGrade(UUID);
  const { t } = useTranslation([ServiceType.GOOGLE]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));

  return (
    <FlexBoxColumn gap={isMobile ? 3 : 4.5} sx={{ width: '100%' }}>
      {overallGrade && (
        <PerformanceMetrics
          metrics={{
            cpa: overallGrade.cpa,
            clicks: overallGrade.clicks,
            impressions: overallGrade.impressions,
            ctr: overallGrade.ctr / 100,
            conversions: overallGrade.conversions,
            // eslint-disable-next-line  @typescript-eslint/no-unnecessary-condition
            conversionRate: overallGrade.conversionRate ?? overallGrade.conversions / overallGrade.clicks,
            roas: overallGrade.roas,
            currencySymbol: overallGrade.currencySymbol,
            spend: overallGrade.spend,
          }}
          adType={GoogleAdType.OVERALL}
        />
      )}
      {/*  Footer Starts */}
      <Typography variant="caption" sx={{ color: 'text.disabled' }} textAlign={isMobile ? 'center' : 'left'}>
        {t('metrics.performanceFooter', { context: 'overall' })}
      </Typography>
      {/*  Footer Ends */}
    </FlexBoxColumn>
  );
};
