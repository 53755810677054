import {IQualityScoreChartProps} from 'src/components/googleAds/grade/charts/LineGraph';
import {IFacebookGrade, IPlacement} from 'src/types/facebook/FacebookGrade';
import {IGoogleGrade} from 'src/types/google/GoogleGrade';
import {IShoppingGrade} from 'src/types/shopping/ShoppingGrade';
import {IVerticalStackedChartProps} from 'src/components/common/grade/charts/VerticalStackedGraph';
import {IScatterChartProps} from '../components/common/grade/charts/ScatterChart';

export type DatasetDuration = 'duration30' | 'duration90';

type DatasetReturnType = {
  dataset: number[];
}

type AADatasetReturnType = {
  dataset: {
    you: never[];
    industry: never[];
  };
}

export const getQualityScoreDataset = (
  grade: IGoogleGrade,
  duration = 'duration30' as DatasetDuration
): Pick<IQualityScoreChartProps, 'dataset'> => {
  return {
    dataset: {
      you: grade.qualityScore[duration].buckets?.map((bucket) => bucket.totalImpressions) || [],
      industry: grade.qualityScore[duration].buckets?.map((bucket) => bucket.benchmarkImpressions) || []
    }
  };
};

export const getAccountActivityDataset = (
  grade: IGoogleGrade,
  duration = 'duration30' as DatasetDuration
): AADatasetReturnType => {
      const types = ['accountStats' as 'accountStats', 'benchmarkStats' as 'benchmarkStats']; // eslint-disable-line
  const emptyDatSet = {
    dataset: {
      you: [],
      industry: []
    }
  };
  return types.reduce((result, type) => {
    result.dataset = {
      ...result.dataset,
      [type === 'accountStats' ? 'you' : 'industry']: [
        grade.accountActivity[duration][type].newCampaigns + grade.accountActivity[duration][type].editedCampaigns,
        grade.accountActivity[duration][type].adActions,
        grade.accountActivity[duration][type].newKeywords + grade.accountActivity[duration][type].removedCriterions,
        grade.accountActivity[duration][type].newAdGroups + grade.accountActivity[duration][type].editedAdGroups,
        grade.accountActivity[duration][type].placementActions
      ]
    };
    return result;
  }, emptyDatSet);
};

export const getImpressionsDataset = (grade: IGoogleGrade): DatasetReturnType => {
  return {
    dataset: [
      grade.impressionShare.impressionShare,
      grade.impressionShare.budgetLostImpressionShare,
      grade.impressionShare.rankLostImpressionShare
    ]
  };
};

export const getWastedSpendDataset = (
  grade: IGoogleGrade,
  duration = 'duration30' as DatasetDuration
): DatasetReturnType => {
  return duration === 'duration30'
    ? {
      dataset: [
        grade.negativeKeyword.negativeKeywordsAddedLastMonth,
        grade.negativeKeyword.benchmarkKeywordsAddedLastMonth
      ]
    }
    : {
      dataset: [
        grade.negativeKeyword.negativeKeywordsAddedLastQuarter,
        grade.negativeKeyword.benchmarkKeywordsAddedLastQuarter
      ]
    };
};

export const getShoppingWastedSpendDataset = (
  grade: IShoppingGrade,
  duration = 'duration30' as DatasetDuration
): DatasetReturnType => {
  return duration === 'duration30'
    ? {
      dataset: [
        grade.negativeKeywords.negativeKeywordsAddedLastMonth,
        grade.negativeKeywords.benchmarkKeywordsAddedLastMonth
      ]
    }
    : {
      dataset: [
        grade.negativeKeywords.negativeKeywordsAddedLastQuarter,
        grade.negativeKeywords.benchmarkKeywordsAddedLastQuarter
      ]
    };
};

export const getShoppingAccountStructureDataset = (
  grade: IShoppingGrade,
): Pick<IVerticalStackedChartProps, 'dataset'> => {
  return {
    dataset: {
      you: [
        grade.accountStructureMetrics.activeProducts,
        grade.accountStructureMetrics.activeProductGroups,
        grade.accountStructureMetrics.activeAdGroups,
        grade.accountStructureMetrics.activeCampaigns,
      ],
      industry: [
        grade.accountStructureMetrics.benchmarkTopProducts,
        grade.accountStructureMetrics.benchmarkTopProductGroups,
        grade.accountStructureMetrics.benchmarkTopAdGroups,
        grade.accountStructureMetrics.benchmarkTopCampaigns,
      ]
    }
  };
};

export const getShoppingCampaignRankingsDataSet = (
  grade: IShoppingGrade,
): Pick<IScatterChartProps, 'dataset'> => {

  const formattedCampaignData = grade.campaignRankings.campaigns.map(campaign => ({
    ...campaign,
    ctr: campaign.ctr * 100,
  }));
  const formattedBenchmarkData = grade.campaignRankings.benchmarks.map(campaign => ({
    ...campaign,
    ctr: campaign.ctr * 100,
  }));

  return {
    dataset: {
      campaigns: formattedCampaignData,
      benchmarks: formattedBenchmarkData
    }
  };
};

export const getPlatformPlacementData = (
  grade: IFacebookGrade
): {dataset: number[]} => {

  if (!grade.platformOverview) return {dataset: [0, 0, 0, 0]};

  const sum = Object.values(grade.platformOverview.placementsByDevice)
    .reduce((sum, item: IPlacement) => sum += item.impressions, 0);

  const first2Percents = [
    Math.round(grade.platformOverview.placementsByDevice.MOBILE.impressions! / sum * 100),
    Math.round(grade.platformOverview.placementsByDevice.DESKTOP.impressions! / sum * 100)
  ];

  return {
    dataset: [
      ...first2Percents,
      100 - first2Percents.reduce((total, num) => total += num, 0),
      0
    ]
  };
};
