import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ScoreName} from 'src/enums/ScoreName';
import {GoogleShoppingScoreSection} from 'src/enums/google/ScoreSection';
import {GradeUtils} from 'src/utils/GradeUtils';
import {useShoppingGrade} from './useShoppingGrade';
import {IShoppingGrade} from '../../types/shopping/ShoppingGrade';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {ServiceType} from 'src/enums/ServiceType';
import {ISODateParser} from 'src/utils/helpers';

const EMPTY_CHAR = '\u00a0';

type Props = {
  sectionName?: GoogleShoppingScoreSection;
}

export type ShoppingGradeScoreType = {
  shoppingGrade: IShoppingGrade | undefined;
  scoreName: ScoreName | undefined;
  scoreValue: number | string;
  date: string;
  currencySymbol: string;
  currencyCode: string;
}

export const useShoppingGradeScore = ({sectionName}: Props): ShoppingGradeScoreType => {
  const {UUID} = useParams();
  const {shoppingGrade} = useShoppingGrade(UUID);
  const [scoreName, setScoreName] = useState<ScoreName | undefined>();
  const [scoreValue, setScoreValue] = useState<number | string>(EMPTY_CHAR);
  const [date, setDate] = useState<string>(new Date().toLocaleDateString(process.env.REACT_APP_LOCALE));
  const [currencySymbol, setCurrencySymbol] = useState<string>('$');
  const [currencyCode, setCurrencyCode] = useState<string>('USD');

  useEffect(() => {
    if (!shoppingGrade) return;

    setScoreName(GradeUtils.getScoreName(shoppingGrade, ServiceType.GOOGLE, sectionName, GoogleAdType.SHOPPING));

    if (sectionName) {
      setScoreValue(GradeUtils.getScoreValue(sectionName, shoppingGrade, ServiceType.GOOGLE, GoogleAdType.SHOPPING));
    } else {
      setScoreValue(GradeUtils.roundoff(shoppingGrade.overallScore.overallScore, 0));
    }

    // eslint-disable-next-line
    if (shoppingGrade && shoppingGrade.overallScore.reportDate) {
      setDate(ISODateParser(shoppingGrade.overallScore.reportDate).toLocaleDateString(process.env.REACT_APP_LOCALE));
    }

    if (shoppingGrade.overallScore.currencySymbol) {
      setCurrencySymbol(shoppingGrade.overallScore.currencySymbol);
    }

    if (shoppingGrade.overallScore.currencyCode) {
      setCurrencyCode(shoppingGrade.overallScore.currencyCode);
    }
  }, [shoppingGrade]);

  return {shoppingGrade, scoreName, scoreValue, date, currencySymbol, currencyCode};
};
