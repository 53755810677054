import React, {useMemo} from 'react';
import {SizeOfAccount} from 'src/components/common/metrics/SizeOfAccount';
import {IDisplayGrade} from 'src/types/display/DisplayGrade';
import {DisplayMetrics} from './DisplayMetrics';
import {PerformanceTracker} from './tracker/PerformanceTracker';
import {DisplayMetricsTabName} from 'src/enums/display/MetricsTabName';

type Props = {
  selected: DisplayMetricsTabName;
  displayGrade: IDisplayGrade | undefined;
}

type ReturnType = {
  TabPanels: JSX.Element[];
}

type ContentProps = {
  tabKey: DisplayMetricsTabName;
  displayGrade: IDisplayGrade | undefined;
}

const renderPanelContent = ({tabKey, displayGrade}: ContentProps) => {
  let value: JSX.Element;
  switch (tabKey) {
    case DisplayMetricsTabName.PerformanceMetrics:
      value = <DisplayMetrics />;
      break;
    case DisplayMetricsTabName.PerformanceTracker:
      value = <PerformanceTracker />;
      break;
    case DisplayMetricsTabName.SizeOfAccount:
      value = displayGrade
        ? <SizeOfAccount
          activeAds={displayGrade.googleGradeDisplayAccountSize.activeAds}
          activeDisplayCampaigns={displayGrade.googleGradeDisplayAccountSize.activeDisplayCampaigns}
          activeAdGroups={displayGrade.googleGradeDisplayAccountSize.activeAdGroups}
          activeAudiences={displayGrade.googleGradeDisplayAccountSize.activeAudiences}
          averageAdGroupsPerCampaign={displayGrade.googleGradeDisplayAccountSize.averageAdGroupsPerCampaign}
          averageAdsPerAdGroup={displayGrade.googleGradeDisplayAccountSize.averageAdsPerAdGroup}
        />
        : <></>;
      break;
  }
  return value!;
};

export const useTabPanels = ({displayGrade, selected}: Props): ReturnType => {
  const TabPanels = useMemo(() => {
    return Object.values(DisplayMetricsTabName).map((tabKey) => {
      return <React.Fragment key={tabKey}>
        {selected === tabKey && renderPanelContent({tabKey, displayGrade})}
      </React.Fragment>;
    }) as JSX.Element[];

  }, [selected]);

  return {TabPanels};
};
