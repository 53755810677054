import {Typography} from '@mui/material';
import React, {SyntheticEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {ShoppingMetrics} from './ShoppingMetrics';
import {useParams} from 'react-router-dom';
import {useTabList} from './useTabList';
import {useTabPanels} from './useTabPanels';
import {SizeOfAccount} from 'src/components/common/metrics/SizeOfAccount';
import {Tabs} from 'src/components/common/styled/Tabs';
import {TabsWrapper} from 'src/components/common/styled/TabsWrapper';
import {GradeAccordion} from 'src/components/common/grade/Accordion';
import {useShoppingGrade} from 'src/hooks/shopping/useShoppingGrade';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {PerformanceTracker} from 'src/components/shopping/metrics/tracker/PerformanceTracker';
import {ShoppingMetricsTabName} from 'src/enums/shopping/MetricsTabName';
import {GoogleShoppingMetricsTabs} from 'src/enums/MetricTabType';

export const MetricsTabs = (): JSX.Element => {
  const {UUID} = useParams();
  const {shoppingGrade} = useShoppingGrade(UUID);
  const [selected, setSelected] = useState<ShoppingMetricsTabName>(ShoppingMetricsTabName.PerformanceMetrics);
  const handleChange = (event: SyntheticEvent<Element, Event>, value: ShoppingMetricsTabName) => setSelected(value);
  const {TabList} = useTabList({onChange: handleChange});
  const {TabPanels} = useTabPanels({shoppingGrade, selected});

  return <TabsWrapper>
    <Tabs value={selected} onChange={handleChange}>
      {TabList}
    </Tabs>
    <>{TabPanels}</>
  </TabsWrapper>;
};

export const MetricsAccordions = (): JSX.Element => {
  const [expanded, setExpanded] = useState<string | false>(GoogleShoppingMetricsTabs.PERFORMANCE_METRICS);
  const {UUID} = useParams();
  const {shoppingGrade} = useShoppingGrade(UUID);
  const {t} = useTranslation([GoogleAdType.SHOPPING]);

  const handleChange =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);

        setTimeout(() => {
          if (!isExpanded) return;
          document.getElementById(panel)?.
            scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 100);
      };

  return <FlexBoxColumn sx={{width: '100%', gap: 2, mb: 2}}>
    <GradeAccordion
      id={t('metrics.performanceMetrics')}
      expanded={expanded === GoogleShoppingMetricsTabs.PERFORMANCE_METRICS}
      handleChange={handleChange(GoogleShoppingMetricsTabs.PERFORMANCE_METRICS)}
      header={<Typography variant="h3">{t('metrics.performanceMetrics')}</Typography>}
    >
      <ShoppingMetrics />
    </GradeAccordion>
    <GradeAccordion
      id={t('metrics.sizeOfAccount')}
      expanded={expanded === GoogleShoppingMetricsTabs.SIZE_OF_ACCOUNT}
      handleChange={handleChange(GoogleShoppingMetricsTabs.SIZE_OF_ACCOUNT)}
      header={<Typography variant="h3">{t('metrics.sizeOfAccount')}</Typography>}
    >
      {
        shoppingGrade
          ? <SizeOfAccount
            activeCampaigns={shoppingGrade.accountStructureMetrics.activeCampaigns}
            activeAdGroups={shoppingGrade.accountStructureMetrics.activeAdGroups}
            averageAdGroupsPerCampaign={shoppingGrade.accountStructureMetrics.averageAdGroupsPerCampaign}
            activeProductGroups={shoppingGrade.accountStructureMetrics.activeProductGroups}
            averageProductGroupsPerAdGroup={shoppingGrade.accountStructureMetrics.averageProductGroupsPerAdGroup}
            activeProducts={shoppingGrade.accountStructureMetrics.activeProducts}
          />
          : <></>
      }
    </GradeAccordion>
    <GradeAccordion
      id={t('metrics.performanceTracker')}
      expanded={expanded === GoogleShoppingMetricsTabs.PERFORMANCE_TRACKER}
      handleChange={handleChange(GoogleShoppingMetricsTabs.PERFORMANCE_TRACKER)}
      header={<Typography variant="h3">{t('metrics.performanceTracker')}</Typography>}
    >
      <PerformanceTracker />
    </GradeAccordion>
  </FlexBoxColumn>;
};
