import { Typography, useMediaQuery, Box, Divider, Link } from '@mui/material';
import { ScoreSectionType } from 'src/enums/google/ScoreSection';
import { GradeUtils } from 'src/utils/GradeUtils';
import { Trans, useTranslation } from 'react-i18next';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { Score } from './Score';
import { useContext } from 'react';
import { GoogleAdTypeContext } from 'src/components/providers/adType';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { FlexBoxColumn } from '../../FlexBoxColumn';
import { ServiceType } from 'src/enums/ServiceType';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { HelperTooltip } from '../Tooltip';
import { FontWeight } from 'src/components/theme/orion/overrides/FontWeight';
import { ServiceContext } from 'src/components/providers/service';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { useGradeSectionScore } from 'src/hooks/common/useGradeSectionScore';

type Props = {
  sectionName: ScoreSectionType;
};

const OpenIcon = ({ href }: { href: string }) => {
  return (
    <OpenInNewIcon onClick={() => window.open(href, '_blank')} sx={{ color: 'primary.main', cursor: 'pointer' }} />
  );
};

const GetHelperTooltip = ({ title }: { title: string }): JSX.Element => {
  if (title) {
    return <HelperTooltip key="scoreDescriptionTooltip" title={title} />;
  }
  return <></>;
};

export const KeyScore = ({ sectionName }: Props): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const { isSearch, isDisplay, isShopping } = useContext(GoogleAdTypeContext);
  const { t } =
    service === ServiceType.FACEBOOK
      ? useTranslation(['facebook'])
      : isDisplay
        ? useTranslation([GoogleAdType.DISPLAY])
        : isShopping
          ? useTranslation(GoogleAdType.SHOPPING)
          : useTranslation([ServiceType.GOOGLE]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));
  const sectionKey = GradeUtils.getSectionKey(sectionName);
  const { scoreValue } = useGradeSectionScore({ service, sectionName });

  return (
    <FlexBoxColumn gap={5} sx={{ width: '100%' }}>
      <ResponsiveRow breakpoint={600} sx={{ width: '100%', gap: isMobile ? 3 : 6, justifyContent: 'flex-start' }}>
        <Box sx={{ width: isMobile ? '100%' : 'auto' }}>
          <Score sectionName={sectionName} />
        </Box>
        <FlexBoxColumn sx={{ alignItems: 'flex-start' }} gap={2}>
          <Typography variant="h3">
            {sectionName}
            {GetHelperTooltip({ title: t(`grade.keyScore.scoreTooltip_${sectionKey}`) })}
          </Typography>
          <Typography variant="h4" sx={{ textAlign: 'left', fontWeight: FontWeight.SemiBold }}>
            <Trans t={t} i18nKey={`grade.keyScore.scoreDescription_${sectionKey}`} values={{ rank: scoreValue }} />
            {GetHelperTooltip({ title: t('grade.keyScore.scoreDescriptionTooltip') })}
          </Typography>
          <Typography variant="body1">{t(`grade.keyScore.keytakeaway_${sectionKey}`)}</Typography>
          <Link
            key={'link'}
            href={t(`grade.keyScore.url_${sectionKey}`)}
            variant={'body1'}
            color={'primary.main'}
            target={'_blank'}
            component={'a'}
            sx={{ textDecorationColor: OrionTheme.palette.primary.main, textTransform: 'capitalize' }}
          >
            {t(`grade.keyScore.linktext_${sectionKey}`)}
            <OpenIcon key="link_icon" href={t(`grade.keyScore.url_${sectionKey}`)} />
          </Link>
        </FlexBoxColumn>
      </ResponsiveRow>
      {service === ServiceType.GOOGLE && isSearch && (
        <Divider sx={{ borderBottom: `1px solid ${OrionColors.gray.legacyTint}`, width: '100%' }} />
      )}
    </FlexBoxColumn>
  );
};
