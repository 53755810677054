import {useContext, useEffect, useState} from 'react';
import {MetricsDataContext} from 'src/components/providers/metricsData';

const DateFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
};

const useDateRange = (): {dateRange: string} => {
  const [data] = useContext(MetricsDataContext);
  const [dateRange, setDateRange] = useState('');

  useEffect(() => {
    if (data && data.grades.length > 1) {
      const gradeCopy = data.grades.slice(-6);
      const firstEl = gradeCopy.shift()?.reportDate.replaceAll('-', '/');
      const lastEl = gradeCopy.pop()?.reportDate.replaceAll('-', '/');

      if (!firstEl || !lastEl) return;

      const from = new Date(lastEl).toLocaleDateString(process.env.REACT_APP_LOCALE, DateFormatOptions);
      const to = new Date(firstEl).toLocaleDateString(process.env.REACT_APP_LOCALE, DateFormatOptions);

      setDateRange(`${from} - ${to}`);
    }
  }, [data]);

  return {dateRange};
};

export default useDateRange;
