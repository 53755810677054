import {Card} from '@mui/material';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  box-sizing: border-box;
  border-radius: ${({theme}) => theme.spacing(1)};
`;

export const GradientCard = styled(StyledCard)`
  :before {
    content: '';
    width: 100%;
    height: ${({theme}) => theme.spacing(1)};
    background: ${({theme}) => theme.palette.gradient};
  }
`;

export const StyledCardWithPadding = styled(StyledCard)`
  width: 100%;
  padding: ${({theme}) => theme.spacing(4)} ${({theme}) => theme.spacing(10)};
  ${({theme}) => theme.breakpoints.down('sm')} {
    padding: ${({theme}) => theme.spacing(3)};
  }
`;

export const StyledCardWithPaddingForErrors = styled(StyledCard)`
  width: 100%;
  padding: ${({theme}) => theme.spacing(8)} ${({theme}) => theme.spacing(10)};
  ${({theme}) => theme.breakpoints.down('sm')} {
    padding: ${({theme}) => theme.spacing(3)};
  }
`;
