
export enum CampaignType {
  RANKING_WORST = 'RANKING_WORST',
  RANKING_BEST = 'RANKING_BEST',
  RANKING = 'RANKING',
  BENCHMARK = 'BENCHMARK',
  INDUSTRY_BENCHMARK = 'INDUSTRY_BENCHMARK',
}

export enum OptimizationLevelType {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  NOT_AVAILABLE = 'NOT_AVAILABLE'
}
