import {Typography, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {StyledLinearProgress} from './LinearProgress';
import {FlexBoxColumn} from '../FlexBoxColumn';
import {FlexBoxRow} from '../FlexBoxRow';
import {useGGContext} from 'src/components/providers/googleAds';
import {ServiceType} from '../../../enums/ServiceType';
import {useFAContext} from '../../providers/facebookAds';

type Props = {
  serviceType: ServiceType;
}

export const Progress = ({serviceType}: Props): JSX.Element => {
  const {t} = useTranslation(['common']);
  const ggContext = useGGContext();
  const faContext = useFAContext();
  const context = serviceType === ServiceType.FACEBOOK ? faContext : ggContext;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FlexBoxColumn gap={5}>
      <Typography variant='h3' color={'common.white'} sx={{whiteSpace: 'pre-wrap', textAlign: 'center'}}>
        {context.isNewReport ? t('progressbar.analyzingText') : t('progressbar.loadingText')}
      </Typography>
      <FlexBoxRow sx={{alignItems: 'center', width: isMobile ? '100%' : '592px'}}>
        <StyledLinearProgress throttle={true} />
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
