import {useEffect} from 'react';
import useSWR from 'swr';
import {IErrorResponse} from 'src/types/common/Error';
import {IShoppingGrade} from 'src/types/shopping/ShoppingGrade';
import {useGGContext} from 'src/components/providers/googleAds';

type ReturnType = {
  shoppingGrade: IShoppingGrade | undefined;
  shoppingError: IErrorResponse | undefined;
}

export const useShoppingGrade = (uuid: string | undefined): ReturnType => {
  const context = useGGContext();
  const URL = `${process.env.REACT_APP_BACKEND_URL}/google/grade/shopping`;

  const {data: gradeData, error: gradeError} = useSWR<IShoppingGrade>(
    uuid ? `${URL}/${uuid}` : null
  );

  useEffect(() => {
    if (gradeData !== undefined) {
      let shoppingName = gradeData.overallScore.accountId;
      if (gradeData.overallScore.accountName) {
        shoppingName = gradeData.overallScore.accountName + ' (' + gradeData.overallScore.accountId + ')';
      }
      context.setGoogleAdsAccount({accountId: gradeData.overallScore.accountId!, accountName: shoppingName});
    }
  }, [gradeData]);

  return {
    shoppingGrade: gradeData,
    shoppingError: gradeError
  };
};
