export enum GoogleAdsMetricsTabs {
  PERFORMANCE_METRICS = 'Performance Metrics',
  SIZE_OF_ACCOUNT = 'Size of Account',
  TEXT_ADS = 'Text Ads',
  PERFORMANCE_TRACKER = 'Performance Tracker',
}

export enum GoogleDisplayMetricsTabs {
  PERFORMANCE_METRICS = 'Performance Metrics',
  SIZE_OF_ACCOUNT = 'Size of Account',
  PERFORMANCE_TRACKER = 'Performance Tracker',
}

export enum GoogleShoppingMetricsTabs {
  PERFORMANCE_METRICS = 'Performance Metrics',
  SIZE_OF_ACCOUNT = 'Size of Account',
  PERFORMANCE_TRACKER = 'Performance Tracker',
}

export enum GoogleOverallMetricsTabs {
  PERFORMANCE_METRICS = 'Performance Metrics',
  SPEND_BREAKDOWN = 'Spend Breakdown',
  PERFORMANCE_TRACKER = 'Performance Tracker',
}

export enum FacebookAdsMetricsTabs {
  ACCOUNT_SUMMARY = 'Account Summary',
  PLATFORM_OVERVIEW = 'Platform Overview',
  AD_PERFORMANCE = 'Ad Performance',
  PERFORMANCE_TRACKER = 'Performance Tracker',
}

export type MetricTabTypes = GoogleAdsMetricsTabs | GoogleDisplayMetricsTabs | GoogleShoppingMetricsTabs | FacebookAdsMetricsTabs;
