import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OrionColors } from 'src/components/theme/orion/common/colors';

const DownloadButton = ({ onClick, hasUuid }: { onClick: () => void; hasUuid: boolean }): JSX.Element => {
  const { t } = useTranslation(['keywords']);

  return (
    <Button
      variant="contained"
      color="secondary"
      size="medium"
      style={{ alignSelf: 'flex-start' }}
      onClick={onClick}
      disabled={!hasUuid}
      disableRipple={true}
      id="download-keywords"
      sx={{ color: OrionColors.common.white }}
    >
      {t('result.download')}
    </Button>
  );
};

export default DownloadButton;
