import {Fetcher, SWRConfig} from 'swr';
import {Logger} from 'src/utils/Logger';

const fetcher: Fetcher = async (URL: string, options?: RequestInit) => {
  const response: Response = await fetch(URL, options ? options : undefined);
  if (response.ok) {
    return response.json();
  } else {
    const error = await response.json();
    Logger.error('fetcher:error', {error}, SWRProvider.name);
    throw error;
  }
};

const configuration = {
  refreshInterval: 0,
  revalidateOnFocus: false,
  shouldRetryOnError: false,
  fetcher
};

type Props = {
  children: JSX.Element | JSX.Element[];
}

export const SWRProvider = ({children}: Props): JSX.Element =>
  <SWRConfig value={{...configuration}}>{children}</SWRConfig>;
