import {TableHead, TableRow, TableCell} from '@mui/material';
import {AudiencePerformanceStat} from './APTableCellMobile';

export const THead = (): JSX.Element => {
  return <TableHead>
    <TableRow sx={{verticalAlign: 'bottom'}}>
      <TableCell size='small' align='right' colSpan={2}>
        {AudiencePerformanceStat.CTR}
      </TableCell>
      <TableCell size='small' align='right'>
        {AudiencePerformanceStat.IMPRESSIONS}
      </TableCell>
      <TableCell size='small' align='right'>
        {AudiencePerformanceStat.CLICKS}
      </TableCell>
      <TableCell size='small' align='right'>
        {AudiencePerformanceStat.SPEND}
      </TableCell>
      <TableCell size='small' align='right'>
        {AudiencePerformanceStat.RESULTS}
      </TableCell>
    </TableRow>
  </TableHead>;
};
