import { PaletteOptions } from '@mui/material';
import { OrionColors } from '../common/colors';

export const OrionPalette: PaletteOptions = {
  primary: {
    main: OrionColors.marine.primary,
    light: OrionColors.marine.tint,
    dark: OrionColors.marine.interactive,
    contrastText: OrionColors.common.white,
  },
  secondary: {
    main: OrionColors.accent.primary,
    dark: OrionColors.accent.primary,
    light: OrionColors.accent.tint,
    contrastText: OrionColors.common.white,
  },
  info: {
    main: OrionColors.blue.lightBackground,
    light: OrionColors.blue.lightBackground,
    dark: OrionColors.blue.darkBackground,
  },
  success: {
    main: OrionColors.green.lightBackground,
    light: OrionColors.green.lightBackground,
    dark: OrionColors.green.darkBackground,
  },
  warning: {
    main: OrionColors.orange.lightBackground,
    light: OrionColors.orange.lightBackground,
    dark: OrionColors.orange.darkBackground,
  },
  error: {
    main: OrionColors.red.lightBackground,
    light: OrionColors.red.lightBackground,
    dark: OrionColors.red.darkBackground,
  },
  // grey: {
  //   50: '',
  //   500: '',
  // },
  text: {
    primary: '#000000',
    // secondary: OrionColors.common.white,
    disabled: OrionColors.gray.mid,
  },
  background: {
    default: OrionColors.cream.background,
    paper: OrionColors.common.white,
    gray: OrionColors.gray.light,
  },
  common: {
    white: OrionColors.common.white,
    black: OrionColors.common.black,
  },
  black: {
    main: '#000000',
    light: '#000000',
    dark: '#000000C2',
    contrastText: '#fff',
  },
  white: {
    main: '#FFFFFF',
    light: '#FFFFFF',
    dark: '#FFFFFFC2',
    contrastText: '',
  },
  destructive: {
    main: '#C20F1E',
    light: '#C20F1E',
    dark: '#930B17',
    contrastText: '',
  },
  // TODO: Remove this
  brand: {
    blue: '#F2FAFF',
    greenAccent: '#4B772C', // Not using #50902D due to low color contrast with white text -> 3.91
    greenTint: '#DAF0CD',
    navyAccent: '#688595',
    unselectedTab: 'rgba(0, 0, 0, 0.25)',
    unselectedTabAccent: 'rgba(0, 0, 0, 0.35)',
    unselectedTabText: '#EAEBED',
  },
  // TODO: Remove this
  facebook: {
    main: '#1877F2',
    background: '#3C4861',
    navyAccent: '#688595',
    lightNavy: '#EBF4FF',
    navyCore: '#03334E',
    highlightedRow: 'rgba(215, 215, 215, 0.25)',
    phoneBlue: '#0165D1',
    tabletBlue: '#01448C',
    desktopBlue: '#002247',
    faintGrey: '#F9F9F9',
  },
  // TODO: Remove this
  graph: {
    you: '#CB6905',
    industry: '#D0A700',
  },
};
