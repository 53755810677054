import { Autocomplete, inputBaseClasses, outlinedInputClasses } from '@mui/material';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import styled from 'styled-components';

export const StyledAutoComplete = styled(Autocomplete).attrs((props) => ({
  ...props,
  disableClearable: true,
  disablePortal: true,
  forcePopupIcon: false,
}))({
  [`.${outlinedInputClasses.input}.MuiAutocomplete-input`]: {
    padding: 0,
    fontSize: OrionTheme.spacing(2.5),
    lineHeight: '24px',
    letterSpacing: '0.25px',
    height: 'auto',
  },
  [`.${outlinedInputClasses.inputSizeSmall}.MuiAutocomplete-input`]: {
    // couldn't find the right class to make input padding 0, hence using important here
    // this is just for instance when the autocomplete size is small
    padding: '0 !important',
    fontSize: OrionTheme.spacing(2),
    lineHeight: '24px',
    letterSpacing: '0.25px',
    height: 'auto',
  },
  [`.${outlinedInputClasses.root}.${inputBaseClasses.sizeSmall}`]: {
    padding: `${OrionTheme.spacing(1)} ${OrionTheme.spacing(2)}`,
    fontSize: OrionTheme.spacing(2),
    letterSpacing: '0.25px',
  },
});
