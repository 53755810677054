import { Divider, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { DeviceType } from 'src/enums/facebook/Device';
import { ServiceType } from 'src/enums/ServiceType';
import { IPlacementByDevice, IPlacementByPlatform } from 'src/types/facebook/FacebookGrade';
import { TableRowMobile } from '../../grade/table/TableRowMobile';
import { withSymbol } from '../../grade/table/utils';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { OrionTheme } from 'src/components/theme/orion/Theme';

interface Props {
  data: IPlacementByDevice | IPlacementByPlatform | undefined;
  type: DataType;
  currencySymbol: string;
}

export enum DataType {
  BY_DEVICE,
  BY_PLATFORM,
}

const PlacementDisplayAttrs = ['MOBILE', 'DESKTOP', 'TABLET'];

export const PlatformOverviewTableMobile = ({ data, type, currencySymbol }: Props): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);

  return (
    <div>
      {type === DataType.BY_PLATFORM && (
        <FlexBoxRow sx={{ width: '100%', justifyContent: 'flex-start', p: 2 }}>
          <BoldTypography variant="h5">{t('metrics.platformOverview.platformPlacement.heading')}</BoldTypography>
        </FlexBoxRow>
      )}
      {PlacementDisplayAttrs.map((attr, index) => (
        <Table
          key={attr}
          sx={{ tableLayout: 'fixed', borderSpacing: `0 ${OrionTheme.spacing(1)}`, borderCollapse: 'separate' }}
        >
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} padding="none" sx={{ px: 1 }}>
                {type === DataType.BY_DEVICE ? (
                  <FlexBoxColumn gap={1} width="100%" sx={{ alignItems: 'flex-start' }}>
                    <FlexBoxRow gap={2}>
                      <img src={(data as IPlacementByDevice)[attr as DeviceType].icon} height={40} width={40} />
                      <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                        {(data as IPlacementByDevice)[attr as DeviceType].deviceDisplayName.toLowerCase()}
                      </Typography>
                    </FlexBoxRow>
                    <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
                  </FlexBoxColumn>
                ) : (
                  <FlexBoxColumn gap={1} width="100%" sx={{ alignItems: 'flex-start' }}>
                    <FlexBoxRow gap={2}>
                      <img src={(data as IPlacementByPlatform)[index].icon} height={40} width={40} />
                      <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                        {(data as IPlacementByPlatform)[index].platform}
                      </Typography>
                    </FlexBoxRow>
                    <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
                  </FlexBoxColumn>
                )}
              </TableCell>
            </TableRow>
            <TableRowMobile
              text={t('metrics.platformOverview.platformPlacement.spend')}
              value={
                type === DataType.BY_DEVICE
                  ? withSymbol((data as IPlacementByDevice)[attr as DeviceType].spend ?? 0, currencySymbol, 'Not Used')
                  : withSymbol((data as IPlacementByPlatform)[index].spend ?? 0, currencySymbol, 'Not Used')
              }
            />
            <TableRowMobile
              text={t('metrics.platformOverview.platformPlacement.reach')}
              value={
                type === DataType.BY_DEVICE
                  ? (data as IPlacementByDevice)[attr as DeviceType].reach
                    ? ((data as IPlacementByDevice)[attr as DeviceType].reach as number).toLocaleString(
                        process.env.REACT_APP_LOCALE,
                        { minimumFractionDigits: 0 },
                      )
                    : ((data as IPlacementByDevice)[attr as DeviceType].spend as number) > 0
                      ? 0
                      : 'Not Used'
                  : (data as IPlacementByPlatform)[index].reach
                    ? ((data as IPlacementByPlatform)[index].reach as number).toLocaleString(
                        process.env.REACT_APP_LOCALE,
                        { minimumFractionDigits: 0 },
                      )
                    : ((data as IPlacementByPlatform)[index].spend as number) > 0
                      ? 0
                      : 'Not Used'
              }
            />
            <TableRowMobile
              text={t('metrics.platformOverview.platformPlacement.ctr')}
              value={
                type === DataType.BY_DEVICE
                  ? (data as IPlacementByDevice)[attr as DeviceType].ctr
                    ? (((data as IPlacementByDevice)[attr as DeviceType].ctr as number) / 100).toLocaleString(
                        process.env.REACT_APP_LOCALE,
                        { minimumFractionDigits: 2, style: 'percent' },
                      )
                    : ((data as IPlacementByDevice)[attr as DeviceType].spend as number) > 0
                      ? ((data as IPlacementByDevice)[attr as DeviceType].ctr ?? '0%')
                      : 'Not Used'
                  : (data as IPlacementByPlatform)[index].ctr
                    ? (((data as IPlacementByPlatform)[index].ctr as number) / 100).toLocaleString(
                        process.env.REACT_APP_LOCALE,
                        { minimumFractionDigits: 2, style: 'percent' },
                      )
                    : ((data as IPlacementByPlatform)[index].spend as number) > 0
                      ? '0%'
                      : 'Not Used'
              }
            />
          </TableBody>
        </Table>
      ))}
    </div>
  );
};
