import React from 'react';
import { Table, TableBody, TableCell, TableCellProps, TableRow } from '@mui/material';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { pxToRem } from 'src/components/theme/orion/overrides/Typography';
import styled from 'styled-components';

type ExpandedRowProps = {
  label: string | JSX.Element;
  value: string | JSX.Element;
  labelVariant?: 'head' | 'body';
};

const LeftTableCell = styled(TableCell).attrs((props): TableCellProps => ({ ...props }))({
  fontSize: pxToRem(12),
  lineHeight: pxToRem(16),
  padding: 0,
  paddingRight: OrionTheme.spacing(1),
  verticalAlign: 'top',
});

const RightTableCell = styled(TableCell).attrs((props): TableCellProps => ({ ...props }))({
  fontSize: pxToRem(12),
  lineHeight: pxToRem(16),
  padding: 0,
  paddingLeft: OrionTheme.spacing(1),
  verticalAlign: 'top',
});

export const ExpandedRow: React.FC<ExpandedRowProps> = ({ label, value, labelVariant = 'head' }) => {
  return (
    <Table style={{ width: '100%' }}>
      <TableBody>
        <TableRow>
          <LeftTableCell sx={{ width: '45%', minWidth: '30%' }} variant={labelVariant}>
            {label}
          </LeftTableCell>
          <RightTableCell sx={{ minWidth: '55%' }}>{value}</RightTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
