import {Box, CircularProgress, styled, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ServiceType} from 'src/enums/ServiceType';
import {useDynamicChart} from './useDynamicChart';

const ChartContainer = styled('div')`
  width: 100%;
  height: 420px;

  ${({theme}) => theme.breakpoints.down(600)} {
    width: calc(100vw - ${({theme}) => theme.spacing(4)});
    height: 300px;
  }
`;

type Props = {
  currencySymbol: string;
}

export const DynamicChart = ({currencySymbol}: Props): JSX.Element => {
  const {t} = useTranslation([ServiceType.GOOGLE]);
  const {canvasRef, isLoading, hasHistoryData} = useDynamicChart({currencySymbol});

  return (
    <Box sx={{width: '100%', display: 'flex'}} data-testid="dynamicChart">
      {isLoading && !hasHistoryData && <CircularProgress data-testid="chartLoading" sx={{p: 6, m: 'auto'}} color="secondary" />}
      <ChartContainer sx={{display: hasHistoryData ? 'block' : 'none'}}>
        <canvas data-testid="chart-canvas" ref={canvasRef} />
      </ChartContainer>
      {!hasHistoryData && !isLoading &&
        <Typography data-testid="noHistoryData" sx={{p: 6, margin: 'auto'}}>{t('tracker.no_history')}</Typography>}
    </Box>
  );
};
