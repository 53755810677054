import {SyntheticEvent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {MetricsTab} from 'src/components/common/metrics/MetricsTab';
import {DisplayMetricsTabName} from 'src/enums/display/MetricsTabName';
import {ServiceType} from 'src/enums/ServiceType';

type Props = {
  onChange: (event: SyntheticEvent<Element, Event>, value: DisplayMetricsTabName) => void;
}

type ReturnType = {
  TabList: JSX.Element[];
}

export const useTabList = ({onChange}: Props): ReturnType => {
  const {t} = useTranslation([ServiceType.GOOGLE]);

  const TabList = useMemo(() => {
    return Object.values(DisplayMetricsTabName).map((tabKey) => {    
      return <MetricsTab name={t(`metrics.${tabKey}`)} key={tabKey} value={tabKey} onChange={() => onChange} splitLabelText={true} />;
    }) as JSX.Element[];

  }, []);

  return {TabList};
};
