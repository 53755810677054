import {createContext, Dispatch, SetStateAction, useState} from 'react';
import {GoogleAdType} from 'src/enums/GoogleAdType';

interface IGoogleAdTypeContext {
  googleAdType: GoogleAdType;
  setGoogleAdType: Dispatch<SetStateAction<GoogleAdType>>;
  isSearch: boolean;
  isDisplay: boolean;
  isShopping: boolean;
  isOverall: boolean;
}

// Dummy func to get the Linter to shut up
const defaultSetGoogleAdType: Dispatch<SetStateAction<GoogleAdType>> = () => { /* noop */ };

export const GoogleAdTypeContext = createContext<IGoogleAdTypeContext>({
  googleAdType: GoogleAdType.SEARCH,
  setGoogleAdType: defaultSetGoogleAdType,
  isSearch: true,
  isDisplay: false,
  isShopping: false,
  isOverall: false,
});

type Props = {
  children: JSX.Element | JSX.Element[];
  googleAdType: GoogleAdType;
}

export const GoogleAdTypeProvider = ({children, googleAdType}: Props): JSX.Element => {
  const [currentGoogleAdType, setGoogleAdType] = useState<GoogleAdType>(googleAdType);

  const contextValue = {
    googleAdType: currentGoogleAdType,
    setGoogleAdType,
    isSearch: currentGoogleAdType === GoogleAdType.SEARCH,
    isDisplay: currentGoogleAdType === GoogleAdType.DISPLAY,
    isShopping: currentGoogleAdType === GoogleAdType.SHOPPING,
    isOverall: currentGoogleAdType === GoogleAdType.OVERALL,
  };

  return (
    <GoogleAdTypeContext.Provider value={contextValue}>
      {children}
    </GoogleAdTypeContext.Provider>
  );
};
