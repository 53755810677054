import {SyntheticEvent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {MetricsTabKey} from 'src/enums/google/MetricsTabKey';
import {MetricsTab} from '../../common/metrics/MetricsTab';
import {ServiceType} from 'src/enums/ServiceType';

type Props = {
  gradeWithTextAds: boolean;
  onChange: (event: SyntheticEvent<Element, Event>, value: MetricsTabKey) => void;
}

type ReturnType = {
  TabList: JSX.Element[];
}

export const useTabList = ({gradeWithTextAds, onChange}: Props): ReturnType => {
  const {t} = useTranslation([ServiceType.GOOGLE]);

  const TabList = useMemo(() => {
    return Object.values(MetricsTabKey).map((tabKey) => {
      if (tabKey === MetricsTabKey.TextAds) {
        return gradeWithTextAds
          ? <MetricsTab name={t(`metrics.${tabKey}.title`)} value={tabKey} key={tabKey} onChange={() => onChange} splitLabelText={true} />
          : null;
      }
      return <MetricsTab name={t(`metrics.${tabKey}`)} value={tabKey} key={tabKey} splitLabelText={true} />;
    }).filter((tablistJsxElement) => !!tablistJsxElement) as JSX.Element[];
  }, [gradeWithTextAds]);

  return {TabList};
};
