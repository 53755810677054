import {useState, useEffect} from 'react';

const MIN_CHARS_TO_BE_TYPED = 3; // start debouncing only after user types 3 chars
const DEBOUNCE_TIME = 500; // ms

export const useDebounce = (value: string): string => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (value && value.length >= MIN_CHARS_TO_BE_TYPED) {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, DEBOUNCE_TIME);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [value]);

  return debouncedValue;
};
