import {useCallback} from 'react';
import {UseFormGetValues} from 'react-hook-form';
import {FormStepOneFields, IFormStepOne} from 'src/components/common/styled/FormStepOne';
import {useGGContext} from 'src/components/providers/googleAds';
import {ClientBusinessTypeKey} from 'src/enums/BusinessType';
import {Step} from 'src/enums/Step';
import {IUserDetails} from 'src/types/common/UserDetails';
import {Logger} from 'src/utils/Logger';

type ReturnType = {
  onSubmit: () => void;
}

export const useSubmit = (getValues: UseFormGetValues<IFormStepOne>): ReturnType => {
  const context = useGGContext();

  const onSubmit = useCallback(() => {
    const userDetails = FormStepOneFields.reduce(
      (result, fieldName) => {
        const fieldValue = getValues(fieldName as keyof IFormStepOne);
        if (fieldName === 'businessType' && fieldValue) {
          const isClient = getValues(fieldName as keyof IFormStepOne) === ClientBusinessTypeKey;
          return {
            ...result,
            isClient
          };
        }
        return {
          ...result,
          [fieldName]: fieldValue
        };
      },
      {}
    ) as IUserDetails;
    Logger.trace('onSubmit:userDetails', {userDetails}, 'FormStepOne');
    context.setUserDetails({...userDetails});
    context.setCurrentStep(Step.FILL_BUSINESS_INFO);
  }, [context, getValues]);

  return {onSubmit};
};
