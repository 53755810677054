import {FacebookAdsScoreSection} from 'src/enums/facebook/ScoreSection';
import {useState} from 'react';
import {FadeInTransition} from '../common/FadeInTransition';
import {FlexBoxColumn} from '../common/FlexBoxColumn';
import {GradeHeader} from '../common/grade/GradeHeader';
import {HowToImprove} from './grade/howTo/HowToImprove';
import {ImprovementTip} from './grade/howTo/ImprovementTip';
import {TabsWrapper} from '../common/styled/TabsWrapper';
import {Tabs} from '../common/styled/Tabs';
import {Tab} from '../common/styled/Tab';
import {KeyScore} from '../common/grade/keyScore/KeyScore';

import {AccountActivityResults, AccountActivityResultsMobile} from './grade/results/AccountActivityResults';
import {AudiencePerformanceResults, AudiencePerformanceResultsMobile} from './grade/results/AudiencePerformanceResults';
import {AudienceTypesResults, AudienceTypesResultsMobile} from './grade/results/AudienceTypesResults';
import {CampaignGoalsResult, CampaignGoalsResultMobile} from './grade/results/CampaignGoalsResults';

const getTable = (sectionName: FacebookAdsScoreSection, mobile: boolean) => {
  switch (sectionName) {
    case FacebookAdsScoreSection.ACCOUNT_ACTIVITY:
      return mobile ? <AccountActivityResultsMobile /> : <AccountActivityResults />;
    case FacebookAdsScoreSection.AUDIENCE_PERFORMANCE:
      return mobile ? <AudiencePerformanceResultsMobile /> : <AudiencePerformanceResults />;
    case FacebookAdsScoreSection.AUDIENCE_TYPES:
      return mobile ? <AudienceTypesResultsMobile /> : <AudienceTypesResults />;
    case FacebookAdsScoreSection.CAMPAIGN_GOALS:
      return mobile ? <CampaignGoalsResultMobile /> : <CampaignGoalsResult />;
    default:
      return <></>;
  }
};

export const SectionContent = ({sectionName, isMobile}: {sectionName: FacebookAdsScoreSection; isMobile: boolean}): JSX.Element =>
  <FlexBoxColumn width={'100%'} gap={5} sx={{justifyContent: 'flex-start'}}>
    <KeyScore sectionName={sectionName} key={`${sectionName}-about-score`} />
    {getTable(sectionName, isMobile)}
    <ImprovementTip sectionName={sectionName} />
    <HowToImprove sectionName={sectionName} />
  </FlexBoxColumn>;

export const FacebookGradesTabs = (): JSX.Element => {
  const [selected, setSelected] = useState<FacebookAdsScoreSection>(FacebookAdsScoreSection.CAMPAIGN_GOALS);

  return (
    <TabsWrapper>
      <Tabs
        value={selected}
        onChange={(event, newValue) => setSelected(newValue)}
      >
        {
          Object.values(FacebookAdsScoreSection)
            .map((section) =>
              <Tab icon={<GradeHeader sectionName={section} />} value={section} key={section} />
            )
        }
      </Tabs>
      <>
        {
          Object.values(FacebookAdsScoreSection)
            .map((sectionName: FacebookAdsScoreSection) =>
              <div role='tabpanel' key={`${sectionName}-tab-panel`}>
                {
                  selected === sectionName && <FadeInTransition key={sectionName}>
                    <SectionContent sectionName={sectionName} isMobile={false} key={`${sectionName}-about-score`} />
                  </FadeInTransition>
                }
              </div>
            )
        }
      </>
    </TabsWrapper>
  );
};
