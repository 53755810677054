import {useEffect, useState} from 'react';
import {useGGContext} from 'src/components/providers/googleAds';
import {ScoreName} from '../../enums/ScoreName';
import {GradeUtils} from '../../utils/GradeUtils';
import {ISODateParser} from 'src/utils/helpers';
import {useOverallGrade} from './useOverallGrade';
import {IOverallGrade} from 'src/types/overall/OverallGrades';
import {useParams} from 'react-router-dom';
import {IErrorResponse} from 'src/types/common/Error';

const EMPTY_CHAR = '\u00a0';

const DateFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'short',
  year: 'numeric'
};

export type OverallGradeType = {
  overallGrade: IOverallGrade | undefined;
  overallError: IErrorResponse | undefined;
  scoreName: ScoreName | undefined;
  scoreValue: string | number;
  date: string;
  formattedDate: string;
}

export const useOverallGradeScore = (): OverallGradeType => {
  const {UUID} = useParams();
  const context = useGGContext();
  const {overallGrade, overallError} = useOverallGrade(UUID);
  const [scoreName, setScoreName] = useState<ScoreName | undefined>();
  const [scoreValue, setScoreValue] = useState<number | string>(EMPTY_CHAR);
  const [date, setDate] = useState<string>(new Date().toLocaleDateString(process.env.REACT_APP_LOCALE));
  const [formattedDate, setFormattedDate] = useState<string>(new Date().toLocaleDateString(process.env.REACT_APP_LOCALE));

  useEffect(() => {
    if (overallGrade !== undefined) {
      let displayName = overallGrade.accountId;

      if (overallGrade.accountName) {
        displayName = overallGrade.accountName + ' (' + overallGrade.accountId + ')';
      }

      context.setGoogleAdsAccount({accountId: overallGrade.accountId!, accountName: displayName});

      setScoreName(GradeUtils.getScoreNameFromNumber(overallGrade.overallScore));
      setScoreValue(overallGrade.overallScore > 0 ? overallGrade.overallScore : '-');

      if (overallGrade.reportDate) {
        setDate(ISODateParser(overallGrade.reportDate).toLocaleDateString(process.env.REACT_APP_LOCALE));
        setFormattedDate(ISODateParser(overallGrade.reportDate).toLocaleDateString(process.env.REACT_APP_LOCALE, DateFormatOptions));
      }
    }
  }, [overallGrade]);

  return {scoreName, scoreValue, overallGrade, overallError, date, formattedDate};
};
