import {Helmet} from 'react-helmet-async';
import {PageTitle} from 'src/enums/PageTitle';
import {useReport} from './useReport';
import {FacebookAdsPage} from 'src/components/facebook/page/Page';
import {GridType} from 'src/enums/GridType';
import {SectionContent, FacebookGradesTabs} from 'src/components/facebook/Grades';
import {FacebookAdsNoSpend} from './NoSpend';
import {MetricsAccordions, MetricsTabs} from 'src/components/facebook/metrics/Metrics';
import {useDriftChat} from '../../hooks/useDriftChat';
import {Heading} from '../googleAds/Report';
import {ServiceType} from 'src/enums/ServiceType';
import {useTranslation} from 'react-i18next';
import BadgeFooter from 'src/components/common/BadgeFooter';
import MetaPartnerSVG from 'src/assets/MetaPartner.svg';
import {GradeAccordions as FBGradeAccordions} from 'src/components/common/grade/Accordions';
import {FacebookAdsScoreSection} from 'src/enums/facebook/ScoreSection';

const chatCode = useDriftChat();

export const FacebookAdsReport = (): JSX.Element => {
  const {isMobile, isZeroSpend} = useReport();
  const {t} = useTranslation([ServiceType.FACEBOOK]);

  return (
    <>
      <Helmet>
        <title>{PageTitle.FACEBOOK} | Report</title>
        <script>{chatCode}</script>
      </Helmet>
      {!isZeroSpend ?
        <FacebookAdsPage
          gridType={GridType.TAB}
          additionalFooter={
            <BadgeFooter
              iconHref={t('facebookBadgeLink')}
              i18nNameSpace={ServiceType.FACEBOOK}
              badgeSVG={MetaPartnerSVG} />
          }
        >
          {
            <>
              {
                isMobile
                  ? <FBGradeAccordions
                    defaultExpandedSection={FacebookAdsScoreSection.CAMPAIGN_GOALS}
                    scoreSections={Object.values(FacebookAdsScoreSection)}
                    renderSectionContent={(sectionName) => <SectionContent sectionName={sectionName as FacebookAdsScoreSection} isMobile={true} />}
                  />
                  : <FacebookGradesTabs />
              }
              <Heading whiteSpace='normal' text={t('metrics.deepDive')} isMobile={isMobile} />
              {
                isMobile
                  ? <MetricsAccordions />
                  : <MetricsTabs />
              }
            </>
          }
        </FacebookAdsPage> : <FacebookAdsNoSpend />}
    </>
  );
};
