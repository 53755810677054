import { Box, Card, Typography, useMediaQuery } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { FacebookAdsScoreSection } from 'src/enums/facebook/ScoreSection';
import { useFAGradeScore } from 'src/hooks/facebook/useFAGradeScore';
import { GradeUtils } from 'src/utils/GradeUtils';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { ServiceType } from 'src/enums/ServiceType';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { ColoredDivider, GetIcon } from 'src/components/common/grade/summaries/ScoreSummary';

type Props = {
  sectionName: FacebookAdsScoreSection;
};

export const ImprovementTip = ({ sectionName }: Props): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const { grade, scoreName } = useFAGradeScore({ sectionName });
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));
  const scoreKey = GradeUtils.getImprovementTipKey({ grade, sectionName, scoreName });
  const headerKey = `grade.${GradeUtils.getSectionKey(sectionName)}.improvementTipHeadline.${scoreKey}`;
  const bodyKey = `grade.${GradeUtils.getSectionKey(sectionName)}.improvementTipContent.${scoreKey}`;

  return (
    <Card sx={{ borderColor: GradeUtils.getScoreColor(scoreName, OrionTheme), borderRadius: 0, p: 4, width: '100%' }}>
      <ResponsiveRow breakpoint={'sm'} sx={{ alignItems: 'flex-start', gap: 2, justifyContent: 'flex-start' }}>
        {isMobile ? (
          <Box sx={{ width: '100%' }}>
            <ColoredDivider bordercolor={GradeUtils.getScoreColor(scoreName, OrionTheme)}>
              {GetIcon({ scoreName })}
            </ColoredDivider>
          </Box>
        ) : (
          <Box>{GetIcon({ scoreName })}</Box>
        )}
        <FlexBoxColumn sx={{ alignItems: 'flex-start', gap: isMobile ? 2 : 1 }}>
          <BoldTypography variant={'h5'}>
            <Trans t={t} i18nKey={t(headerKey)} />
          </BoldTypography>
          <Typography variant="body1">
            <Trans t={t} i18nKey={t(bodyKey)} />
          </Typography>
        </FlexBoxColumn>
      </ResponsiveRow>
    </Card>
  );
};
