import { Box, Divider, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery } from '@mui/material';
import { TableRow as CustomTableRow } from 'src/components/facebook/grade/table/TableRow';
import { useTranslation } from 'react-i18next';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { getScoreName } from 'src/components/facebook/grade/table/getScoreName';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { withSymbol } from '../../grade/table/utils';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { YouVsCompetitorMobile } from 'src/components/facebook/grade/table/YouVsCompetitorMobile';
import { OrionColors } from 'src/components/theme/orion/common/colors';

type Props = {
  ctr: number;
  competitorCtr: number;
  pctDiff: number;
  adId: string;
};

export const KeyMetric = ({ ctr, competitorCtr, pctDiff, adId }: Props): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));
  const scoreName = getScoreName({
    you: ctr,
    benchmark: competitorCtr,
    difference: pctDiff,
  });

  return (
    <Box sx={{ width: '100%' }}>
      {!isMobile && (
        <table>
          <tbody>
            <FlexBoxRow sx={{ justifyContent: 'flex-start', pb: 2, width: '100%' }}>
              <BoldTypography variant="h6">
                {t('metrics.adPerformance.keyMetric.adId')} {adId}
              </BoldTypography>
            </FlexBoxRow>
            <Divider sx={{ width: '100%', borderColor: 'colors.gray.neutral', pt: 2 }} />
          </tbody>
        </table>
      )}
      <FlexBoxRow
        sx={{ justifyContent: isMobile ? 'center' : 'flex-start', pt: 2, pb: isMobile ? 1 : 2, width: '100%' }}
      >
        <BoldTypography variant="body2">{t('metrics.adPerformance.keyMetric.title')}</BoldTypography>
      </FlexBoxRow>
      {isMobile ? (
        <Box>
          <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
          <table>
            <tbody>
              <YouVsCompetitorMobile
                title={t('metrics.adPerformance.keyMetric.ctr')}
                scoreName={getScoreName({
                  you: ctr,
                  benchmark: competitorCtr,
                  difference: pctDiff,
                })}
                you={withSymbol(ctr, '%')}
                competitors={withSymbol(competitorCtr, '%')}
              />
            </tbody>
          </table>
          <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
        </Box>
      ) : (
        <Table sx={{ width: '100%' }}>
          <TableHead>
            <TableRow sx={{ justifyContent: 'flex-start' }}>
              <TableCell sx={{ width: '50%' }} />
              <TableCell>
                <BoldTypography variant="caption">{t('grade.you')}</BoldTypography>
              </TableCell>
              <TableCell align="right" sx={{ borderBottom: 'none' }}>
                <BoldTypography variant="caption">{t('grade.competitorAvg')}</BoldTypography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <CustomTableRow
              text={t('metrics.adPerformance.keyMetric.ctr')}
              you={withSymbol(ctr, '%')}
              competitors={withSymbol(competitorCtr, '%')}
              scoreName={scoreName}
            />
          </TableBody>
        </Table>
      )}
    </Box>
  );
};
