import { Tabs as MuiTabs, TabsProps } from '@mui/material';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import styled from 'styled-components';

const Props: TabsProps = {
  key: `tabs-${Math.random()}`,
  variant: 'fullWidth',
  TabIndicatorProps: {
    sx: {
      height: OrionTheme.spacing(1),
      backgroundColor: 'primary.main',
    },
  },
};

const Tabs = styled(MuiTabs)`
  border-bottom: ${({ theme }) => theme.spacing(0.125)} solid ${({ theme }) => theme.palette.common.black};
`;

Tabs.defaultProps = Props;

export { Tabs };
