import {Typography, useMediaQuery, Theme, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';

export const KeywordsPageTitle = (): JSX.Element => {
  const {t} = useTranslation(['keywords']);
  const theme = useTheme();
  const isScreenLarge = useMediaQuery((theme as Theme).breakpoints.up('md'));

  return (
    <FlexBoxColumn sx={{py: 3}} width={'100%'} gap={3}>
      <Typography className={isScreenLarge ? 'display' : ''} variant='h1' color={'common.white'}>
        {t('title')}
      </Typography>
      <Typography variant='body1' fontWeight={700} sx={{letterSpacing: '0.15px', textAlign: 'center'}} color={'common.white'}>
        {t('tagline')}
      </Typography>
    </FlexBoxColumn>
  );
};
