import { Typography, Box, styled, useMediaQuery } from '@mui/material';
import { FlexBoxRow } from './FlexBoxRow';
import { OrionTheme } from '../theme/orion/Theme';
import logo from 'src/assets/WordStreamLogo.png';

const StyledRow = styled(FlexBoxRow)`
  height: ${({ theme }) => theme.spacing(8)};
  width: 100%;
  background-color: ${(props) => props.theme.palette.background.default};
  flex-wrap: wrap;
`;

const ImageContainer = styled(Box)`
  flex: 0.96;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: center;
  }
`;

const Link = styled('a')`
  cursor: pointer;
`;

const StyledImage = styled('img')`
  height: 60px;
  width: auto;
  display: block; 
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 50px;
  }
`;

type Props = {
  accountName?: string;
};

export const NavBar = ({ accountName }: Props): JSX.Element => {
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));

  return (
    <StyledRow id="nav-bar">
      <ImageContainer>
        <Link href="https://www.wordstream.com/" target="_blank" tabIndex={-1} aria-label="WordStream by LOCALiQ">
          <StyledImage src={logo} id="logo" alt="WordStream By LOCALiQ" />
        </Link>
      </ImageContainer>
      {!isMobile && accountName && (
        <Typography variant="subtitle1" color="common.black">
          {accountName}
        </Typography>
      )}
    </StyledRow>
  );
};
