import { Box, Link, styled, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useDownloadPage } from './useDownloadPage';
import { KeywordsBackground } from '../Background';
import { StyledSignInButton } from 'src/components/common/styled/StyledSignIn';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import LaunchIcon from '@mui/icons-material/Launch';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { StyledCardWithPadding } from 'src/components/common/styled/StyledCard';

const StyledLink = styled(Link)(({ theme }) => ({
  fontFamily: theme.typography.h6.fontFamily,
  fontSize: '1rem',
  fontWeight: theme.typography.h6.fontWeight,
  lineHeight: theme.typography.h6.lineHeight,
  letterSpacing: theme.typography.h6.letterSpacing,
}));

export const DownloadPage = (): JSX.Element => {
  const { t } = useTranslation(['keywords']);
  const { file } = useDownloadPage();
  const keywordRedirect = () => window.location.replace('https://www.wordstream.com/keywords');

  return (
    <KeywordsBackground>
      <Box mt={4} p={4}>
        <StyledCardWithPadding sx={{ gap: 5 }}>
          <FlexBoxColumn sx={{ gap: 2 }}>
            <Typography textAlign="center" variant="h3">
              {t('download.header')}
            </Typography>
            <div style={{ textAlign: 'center' }}>
              <Trans
                t={t}
                i18nKey={'keywords:download.manual_link'}
                components={[
                  <Typography key="manual_download_text" component="span" variant="h6" sx={{ pr: 0.75 }} />,
                  <StyledLink
                    data-testid="manualDownloadLink"
                    key="manual_download_link"
                    href={file}
                    target="_blank"
                    download={'Keywords'}
                  />,
                ]}
              />
            </div>
          </FlexBoxColumn>
          <StyledSignInButton variant="contained" onClick={keywordRedirect}>
            <FlexBoxRow gap={1}>
              <BoldTypography variant="h5" color={OrionTheme.palette.common.white}>
                {t('download.newSearch')}
              </BoldTypography>
              <LaunchIcon sx={{ color: OrionTheme.palette.common.white }} />
            </FlexBoxRow>
          </StyledSignInButton>
        </StyledCardWithPadding>
      </Box>
    </KeywordsBackground>
  );
};
