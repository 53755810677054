import {ServiceType} from 'src/enums/ServiceType';
import {
  GoogleAdsScoreSection, GoogleDisplayScoreSection,
  GoogleShoppingScoreSection
} from 'src/enums/google/ScoreSection';
import {FacebookAdsScoreSection} from 'src/enums/facebook/ScoreSection';
import {useGGradeScore} from 'src/hooks/google/useGGradeScore';
import {useDisplayGradeScore} from 'src/hooks/display/useDisplayGradeScore';
import {useShoppingGradeScore} from 'src/hooks/shopping/useShoppingGradeScrore';
import {useFAGradeScore} from 'src/hooks/facebook/useFAGradeScore';
import {ScoreName} from 'src/enums/ScoreName';
import {useContext} from 'react';
import {GoogleAdTypeContext} from 'src/components/providers/adType';

export type GradeSectionScore = {
  scoreName: ScoreName | undefined;
  scoreValue: number | string | undefined;
};

export const useGradeSectionScore = ({
  service,
  sectionName
}: {
  service: ServiceType;
  sectionName: string;
}): GradeSectionScore => {
  const context = useContext(GoogleAdTypeContext);
  let gradeDataWithSectionScore;
 
  if (service === ServiceType.FACEBOOK) {
    gradeDataWithSectionScore = useFAGradeScore({sectionName: sectionName as FacebookAdsScoreSection});
  } else if (context.isSearch) {
    gradeDataWithSectionScore = useGGradeScore({sectionName: sectionName as GoogleAdsScoreSection});
  } else if (context.isDisplay) {
    gradeDataWithSectionScore = useDisplayGradeScore({sectionName: sectionName as GoogleDisplayScoreSection});
  } else if (context.isShopping) {
    gradeDataWithSectionScore = useShoppingGradeScore({sectionName: sectionName as GoogleShoppingScoreSection});
  }
  return {scoreName: gradeDataWithSectionScore?.scoreName, scoreValue: gradeDataWithSectionScore?.scoreValue};
};
