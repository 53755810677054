import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { StyledBorderedColumn } from 'src/components/common/styled/StyledBorderedColumn';
import { useShoppingGradeScore } from 'src/hooks/shopping/useShoppingGradeScrore';
import { GoogleShoppingScoreSection } from 'src/enums/google/ScoreSection';
import { OrionTheme } from 'src/components/theme/orion/Theme';

export const IndustryBenchmarkMetrics = (): JSX.Element => {
  const { shoppingGrade } = useShoppingGradeScore({ sectionName: GoogleShoppingScoreSection.CAMPAIGN_RANKINGS });
  const { t } = useTranslation([GoogleAdType.SHOPPING]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));
  const data = shoppingGrade
    ? [
        {
          label: t('grade.campaignRankings.industryBenchmark.spend'),
          value: shoppingGrade.campaignRankings.industryBenchmark.spend.toLocaleString(process.env.REACT_APP_LOCALE, {
            minimumFractionDigits: 2,
            style: 'currency',
            currency: shoppingGrade.overallScore.currencyCode,
          }),
        },
        {
          label: t('grade.campaignRankings.industryBenchmark.impressionShare'),
          value: (shoppingGrade.campaignRankings.industryBenchmark.impressionShare / 100).toLocaleString(
            process.env.REACT_APP_LOCALE,
            { maximumFractionDigits: 0, style: 'percent' },
          ),
        },
        {
          label: t('grade.campaignRankings.industryBenchmark.ctr'),
          value: shoppingGrade.campaignRankings.industryBenchmark.ctr.toLocaleString(process.env.REACT_APP_LOCALE, {
            maximumFractionDigits: 2,
            style: 'percent',
          }),
        },
      ]
    : [];

  return (
    <FlexBoxColumn sx={{ alignSelf: 'stretch', gap: 2, alignItems: 'stretch' }}>
      <BoldTypography variant="h6">{t('grade.campaignRankings.industryBenchmark.title')}</BoldTypography>
      <FlexBoxRow sx={{ alignItems: 'space-between', gap: 1 }}>
        {data.map((item, index) => (
          <StyledBorderedColumn key={index}>
            <BoldTypography variant="caption" textAlign={'center'} sx={{ px: isMobile ? 1 : 0, pt: isMobile ? 1 : 0 }}>
              {item.label}
            </BoldTypography>
            <Typography variant="body1" sx={{ pb: isMobile ? 1 : 0 }}>
              {item.value}
            </Typography>
          </StyledBorderedColumn>
        ))}
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};
