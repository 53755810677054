import {Button} from '@mui/material';
import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {ServiceContext} from '../providers/service';
import LaunchIcon from '@mui/icons-material/Launch';
import {useSearchParams} from 'react-router-dom';
import {ErrorEvent} from 'src/hooks/common/useErrorContent';

export const GradeMyAccountBtn = (): JSX.Element => {
  const {t} = useTranslation(['common']);
  const [service] = useContext(ServiceContext);
  const [searchParams] = useSearchParams();

  const redirect = () => {
    if (service) {
      window.location.href = t(`gradeMyAccount.${service}`);
    } else {
      window.location.href = t('gradeMyAccount.default');
    }
  };

  return (
    <Button
      variant='contained'
      color='secondary'
      size='medium'
      onClick={redirect}
      data-testid='facebookButton'
      endIcon={<LaunchIcon />}
    >
      {searchParams.get('event') === ErrorEvent.EXPIRED_TOKEN ? t('gradeMyAccount.btn_runNewGrader') : t('gradeMyAccount.btn_default')}
    </Button>
  );
};
