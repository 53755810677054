import {useContext, useEffect} from 'react';
import {useForm, UseFormReturn} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useEmailReport} from 'src/hooks/common/useEmailReport';
import {useParams} from 'react-router-dom';
import {IEmailReportResponse} from 'src/types/common/IEmailReport';
import {ServiceContext} from 'src/components/providers/service';
import {ServiceType} from 'src/enums/ServiceType';
import {useFacebookGrade} from 'src/hooks/facebook/useFacebookGrade';
import {useOverallGrade} from 'src/hooks/overall/useOverallGrade';
import {IFacebookGrade} from 'src/types/facebook/FacebookGrade';
import {IOverallGrade} from 'src/types/overall/OverallGrades';

type Props = {
  open: boolean;
  onClose: () => void;
  onResponse: (response: IEmailReportResponse) => void;
}

type ReturnType = {
  onSubmit: () => Promise<void>;
  methods: UseFormReturn<{
    shareEmail: string;
    fromEmail: string;
    messageText: string;
    //Typescript requirement to use object type instead of Record
    // eslint-disable-next-line @typescript-eslint/ban-types
  }, object>;
}

const useShareModal = ({open, onClose, onResponse}: Props): ReturnType => {
  const {UUID} = useParams();
  const [service] = useContext(ServiceContext);
  const {grade} = service === ServiceType.FACEBOOK ? useFacebookGrade(UUID) : {grade: useOverallGrade(UUID).overallGrade};
  const validationSchema = Yup.object().shape({
    shareEmail: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
    fromEmail: Yup.string()
      .email('From email is invalid')
      .required('From email is required'),
    messageText: Yup.string()
      .max(256, 'Message can not exceed 256 characters')
  });

  const defaultInputValues = {
    shareEmail: '',
    fromEmail: '',
    messageText: ''
  };

  const {...methods} = useForm({
    defaultValues: defaultInputValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const {sendEmail, response} = useEmailReport({reportTo: service, reportFrom: service, message: service});

  const onSubmit = methods.handleSubmit((data) => {
    onClose();
    sendEmail({
      shareEmail: data.shareEmail,
      fromEmail: data.fromEmail,
      messageText: data.messageText,
      uuid: UUID
    });
  });

  useEffect(() => {
    if (grade) {
      const gradeEmail = service === ServiceType.FACEBOOK
        ? (grade as IFacebookGrade).overallScore.email!
        : (grade as IOverallGrade).email!;
      if (gradeEmail) {
        methods.setValue('fromEmail', gradeEmail);
      }
    }
  }, [grade]);

  useEffect(() => {
    if (open) {
      methods.clearErrors();
    }
  }, [open]);

  useEffect(() => {
    if (!response) return;

    onResponse(response);
  }, [response]);

  return {methods, onSubmit};
};

export default useShareModal;
