import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, accordionSummaryClasses } from '@mui/material';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { FlexBoxColumn } from '../FlexBoxColumn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';

type Props = {
  header: JSX.Element;
  expanded: boolean;
  handleChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  children: JSX.Element | JSX.Element[];
  id: string;
};

const GradeAccordionSummary = styled(AccordionSummary)({
  padding: OrionTheme.spacing(3),
  [`${accordionSummaryClasses.expandIconWrapper}`]: {
    color: OrionTheme.palette.primary.main,
  },
});

const GradeAccordionDetails = styled(AccordionDetails)({
  padding: `${OrionTheme.spacing(0)} ${OrionTheme.spacing(3)}`,
  paddingBottom: OrionTheme.spacing(3),
});

export const GradeAccordion = ({ children, header, expanded, handleChange, id }: Props): JSX.Element => {
  return (
    <Accordion
      id={id}
      elevation={0}
      disableGutters={true}
      sx={{ width: '100%' }}
      expanded={expanded}
      onChange={handleChange}
      TransitionProps={{
        timeout: {
          enter: 0,
          exit: 0,
        },
      }}
    >
      <GradeAccordionSummary
        id={`accordion-summary-${id}`}
        expandIcon={<ExpandMoreIcon sx={{ color: 'primary.main', height: 24, width: 24 }} />}
      >
        {header}
      </GradeAccordionSummary>
      <GradeAccordionDetails id={`accordion-details-${id}`}>
        <FlexBoxColumn gap={3} sx={{ width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            <Divider sx={{ borderBottomWidth: OrionTheme.spacing(1), borderBottomColor: 'primary.main' }} />
          </Box>
          {children}
        </FlexBoxColumn>
      </GradeAccordionDetails>
    </Accordion>
  );
};
