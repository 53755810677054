import { styled, Theme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { observer } from 'src/utils/helpers';
import BackdropUnstyled from './styled/BackdropUnstyled';

type BackdropProps = {
  $isintersecting: boolean;
  intersectionratio: number;
};

const calculateSpacing = (theme: Theme, ratio: number) => {
  return theme.spacing(8 * ratio); // nav-bar height is theme.spacing(8)
};

const StyledBackDrop = styled(BackdropUnstyled)<BackdropProps>(({ theme, $isintersecting, intersectionratio }) => ({
  zIndex: '-1',
  position: 'fixed',
  right: '0',
  bottom: '0',
  top: $isintersecting ? calculateSpacing(theme, intersectionratio) : '0',
  left: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  '-webkit-tap-highlight-color': 'transparent',
}));

const Backdrop = (): JSX.Element => {
  const navEl = useMemo(() => document.getElementById('nav-bar'), []);
  const [navProps, setNavProps] = useState<BackdropProps>({ $isintersecting: false, intersectionratio: 0 });
  const intersectionObserver = observer(setNavProps);

  useEffect(() => {
    if (!navEl) return;
    intersectionObserver.observe(navEl);
    return () => intersectionObserver.disconnect();
  }, [navEl]);

  return <StyledBackDrop $isintersecting={navProps.$isintersecting} intersectionratio={navProps.intersectionratio} />;
};

export default Backdrop;
