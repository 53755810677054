import {KeywordsForm} from '../form/Form';
import {useTranslation} from 'react-i18next';
import {BaseDialog} from 'src/components/common/styled/StyledDialog';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {FontWeight} from 'src/components/theme/orion/overrides/FontWeight';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {DialogContent, Typography} from '@mui/material';
import {GradientLine} from 'src/components/common/GradientLine';
import useRefineModal from './useRefineModal';
import Backdrop from 'src/components/common/Backdrop';

export const RefineModal = (): JSX.Element => {
  const {open} = useRefineModal();
  const {t} = useTranslation(['keywords']);

  return <BaseDialog BackdropComponent={Backdrop} maxWidth={'md'} disableAutoFocus={true} open={open}>
    <FlexBoxColumn sx={{p: 0, pb: 3.5}}>
      <FlexBoxRow sx={{width: '100%', paddingBottom: 3}}>
        <Typography variant='h4' fontWeight={FontWeight.SemiBold}>
          {t('form.label', {context: 'refine'})}
        </Typography>
      </FlexBoxRow>
      <GradientLine />
    </FlexBoxColumn>
    <DialogContent><KeywordsForm textColor='common.black' modalFormat={true} /></DialogContent>
  </BaseDialog>;
};
