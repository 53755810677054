
export enum SpendDataKey {
  Discovery = 'discovery',
  Local = 'local',
  Hotel = 'hotel',
  LocalServices = 'localServices',
  MultiChannel = 'multiChannel',
  PerformanceMax = 'performanceMax',
  Smart = 'smart',
  Unknown = 'unknown',
  Video = 'video',
  Unspecified = 'unspecified'
}
