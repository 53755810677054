import {useState} from 'react';
import {GoogleShoppingScoreSection} from 'src/enums/google/ScoreSection';
import {TabsWrapper} from 'src/components/common/styled/TabsWrapper';
import {Tabs} from 'src/components/common/styled/Tabs';
import {Tab} from '@mui/material';
import {GradeHeader} from 'src/components/common/grade/GradeHeader';
import {FadeInTransition} from 'src/components/common/FadeInTransition';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {KeyScore} from 'src/components/common/grade/keyScore/KeyScore';
import {KeyMetrics} from 'src/components/common/grade/keyMetrics/KeyMetrics';
import {ChartRenderer} from './grade/charts/ChartRenderer';
import {ScoreSummary} from 'src/components/common/grade/summaries/ScoreSummary';
import {HowToImprove} from 'src/components/common/grade/howTo/HowToImprove';
import {CampaignResults} from './grade/CampaignResults';
import {IndustryBenchmarkMetrics} from './grade/IndustryBenchmarkMetrics';

interface Props {
  sectionName: GoogleShoppingScoreSection;
  isMobile: boolean;
}

export const SectionContent = ({sectionName, isMobile}: Props): JSX.Element =>
  <FlexBoxColumn width={'100%'} gap={isMobile ? 3 : 5} key={`${sectionName}-section-content`}>
    <KeyScore key={`${sectionName}-about-score`} sectionName={sectionName} />
    <KeyMetrics key={`${sectionName}-key-metric`} sectionName={sectionName} />
    <ChartRenderer key={`${sectionName}-shopping-chart`} section={sectionName} />
    {sectionName === GoogleShoppingScoreSection.CAMPAIGN_RANKINGS && <IndustryBenchmarkMetrics />}
    {sectionName === GoogleShoppingScoreSection.CAMPAIGN_RANKINGS && <CampaignResults />}
    <ScoreSummary key={`${sectionName}-score-summary`} sectionName={sectionName} />
    <HowToImprove key={`${sectionName}-howToImprove`} sectionName={sectionName} />
  </FlexBoxColumn>;

export const GoogleShoppingTabs = (): JSX.Element => {
  const [selected, setSelected] = useState<GoogleShoppingScoreSection>(GoogleShoppingScoreSection.WASTED_SPEND);

  return (
    <TabsWrapper>
      <Tabs
        value={selected}
        onChange={(event, newValue) => setSelected(newValue)}
      >
        {
          Object.values(GoogleShoppingScoreSection)
            .map((sectionName) =>
              <Tab icon={<GradeHeader sectionName={sectionName} />} value={sectionName} key={`${sectionName}-tab`} />
            )
        }
      </Tabs>
      <div role='tab-panel'>
        {
          Object.values(GoogleShoppingScoreSection)
            .map((sectionName) =>
              sectionName === selected && <FadeInTransition key={`${sectionName}-panel-transition`}>
                <SectionContent sectionName={sectionName} isMobile={false} key={`${sectionName}-panel`} />
              </FadeInTransition>
            )
        }
      </div>
    </TabsWrapper>
  );
};
