import {IEmailKeywordResponse} from '../../../types/keywords/KeywordResponse';
import {StyledDialog} from 'src/components/common/styled/StyledDialog';
import {useTranslation} from 'react-i18next';
import EmailForm from './EmailForm';

type Props = {
  open: boolean;
  onClose: () => void;
  onResponse: (response: IEmailKeywordResponse) => void;
};

export const EmailKeywordsModal = ({open, onClose, onResponse}: Props): JSX.Element => {
  const {t} = useTranslation(['keywords']);
  return (
    <StyledDialog disableAutoFocus={true} open={open} onClose={onClose} headertext={t('emailModal.download')}>
      <EmailForm onClose={onClose} onResponse={onResponse} />
    </StyledDialog>
  );
};
