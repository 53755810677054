import {useContext, useEffect, useRef} from 'react';
import {KeywordsContext} from 'src/components/providers/keywords';

type ReturnType = {
  elementRef: React.RefObject<HTMLElement>;
}

const useScrollToElement = (): ReturnType => {
  const elementRef = useRef<HTMLElement>(null);
  const {status} = useContext(KeywordsContext);
  
  useEffect(() => {
    if (!elementRef.current) return;

    if (status.isDone) {
      elementRef.current.scrollIntoView();
    }

  }, [status]);

  return {elementRef};
};

export default useScrollToElement;
