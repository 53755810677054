import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { useGoogleGrade } from 'src/hooks/google/useGoogleGrade';
import { RowContent } from './RowContent';
import { useTranslation } from 'react-i18next';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionTheme } from 'src/components/theme/orion/Theme';

export const TextAds = (): JSX.Element => {
  const { t } = useTranslation([ServiceType.GOOGLE]);
  const { UUID } = useParams();
  const { grade } = useGoogleGrade(UUID);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));

  return (
    <FlexBoxColumn gap={isMobile ? 3 : 5} sx={{ alignSelf: 'stretch', alignItems: 'flex-start' }}>
      <FlexBoxRow>
        <Typography variant="h4" fontWeight={'fontWeightBold'}>
          {t('metrics.textAds.subTitle')}
        </Typography>
      </FlexBoxRow>
      {grade && (
        <>
          <Box sx={{ width: '100%' }}>
            <RowContent stat={grade.stats.adsStats[0]} />
          </Box>
          <Divider flexItem />
          <Box sx={{ width: '100%' }}>
            <RowContent stat={grade.stats.adsStats[1]} />
          </Box>
        </>
      )}
      <Typography variant="caption" color={'text.disabled'} textAlign={isMobile ? 'center' : 'left'}>
        {t('metrics.textAds.footerText')}
      </Typography>
    </FlexBoxColumn>
  );
};
