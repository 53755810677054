import {Typography} from '@mui/material';
import {SyntheticEvent, useContext, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from '../../common/FlexBoxColumn';
import {SearchMetrics} from './SearchMetrics';
import {TextAds} from './TextAds/TextAds';
import {useParams} from 'react-router-dom';
import {useGoogleGrade} from 'src/hooks/google/useGoogleGrade';
import {PerformanceTracker} from './tracker/PerformanceTracker';
import {IGoogleGrade} from 'src/types/google/GoogleGrade';
import {useFetchHistory} from 'src/hooks/common/useFetchHistory';
import {useTabList} from './useTabList';
import {useTabPanels} from './useTabPanels';
import {ServiceType} from 'src/enums/ServiceType';
import {GoogleAdTypeContext} from 'src/components/providers/adType';
import {SizeOfAccount} from 'src/components/common/metrics/SizeOfAccount';
import {Tabs} from 'src/components/common/styled/Tabs';
import {TabsWrapper} from 'src/components/common/styled/TabsWrapper';
import {MetricsTabKey} from 'src/enums/google/MetricsTabKey';
import {GoogleAdsMetricsTabs} from 'src/enums/MetricTabType';
import {GradeAccordion} from 'src/components/common/grade/Accordion';
import {FontWeight} from 'src/components/theme/orion/overrides/FontWeight';

const gradeHasTextAds = (grade: IGoogleGrade | undefined): boolean => !!grade && Array.isArray(grade.stats.adsStats) && grade.stats.adsStats.length > 0;

export const MetricsTabs = (): JSX.Element => {
  const {UUID} = useParams();
  const {googleAdType} = useContext(GoogleAdTypeContext);
  useFetchHistory({UUID, googleAdType}); // Prefetch PerformanceTracker data for improved User Experience
  const {grade} = useGoogleGrade(UUID);
  const gradeWithTextAds = useMemo(() => gradeHasTextAds(grade), [grade]);
  const [selected, setSelected] = useState<MetricsTabKey>(MetricsTabKey.PerformanceMetrics);
  const handleChange = (event: SyntheticEvent<Element, Event>, value: MetricsTabKey) => setSelected(value);
  const {TabList} = useTabList({gradeWithTextAds, onChange: handleChange});
  const {TabsPanels} = useTabPanels({gradeWithTextAds, grade, selected});

  return <TabsWrapper>
    <Tabs value={selected} onChange={handleChange}>
      {TabList}
    </Tabs>
    <div role='tabpanel'>{TabsPanels}</div>
  </TabsWrapper>;
};

export const MetricsAccordions = (): JSX.Element => {
  const [expanded, setExpanded] = useState<string | false>(GoogleAdsMetricsTabs.PERFORMANCE_METRICS);
  const {t} = useTranslation([ServiceType.GOOGLE]);
  const {UUID} = useParams();
  const {grade} = useGoogleGrade(UUID);
  const gradeWithTextAds = gradeHasTextAds(grade);

  const handleChange =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);

        setTimeout(() => {
          if (!isExpanded) return;
          document.getElementById(panel)?.
            scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 100);
      };

  return <FlexBoxColumn sx={{gap: 2, mb: 2, width: '100%'}}>
    <GradeAccordion
      id={t('metrics.performanceMetrics')}
      key={`${t('metrics.performanceMetrics')}-accordion`}
      expanded={expanded === GoogleAdsMetricsTabs.PERFORMANCE_METRICS}
      handleChange={handleChange(GoogleAdsMetricsTabs.PERFORMANCE_METRICS)}
      header={<Typography variant="h4" fontWeight={FontWeight.SemiBold}>{t('metrics.performanceMetrics')}</Typography>}>
      <SearchMetrics />
    </GradeAccordion>
    <GradeAccordion
      id={t('metrics.sizeOfAccount')}
      key={`${t('metrics.sizeOfAccount')}-accordion`}
      expanded={expanded === GoogleAdsMetricsTabs.SIZE_OF_ACCOUNT}
      handleChange={handleChange(GoogleAdsMetricsTabs.SIZE_OF_ACCOUNT)}
      header={<Typography variant="h4" fontWeight={FontWeight.SemiBold}>{t('metrics.sizeOfAccount')}</Typography>}>
      {
        grade
          ? <SizeOfAccount
            activeAds={grade.accountSizeMetrics.activeAds}
            activeSearchCampaigns={grade.accountSizeMetrics.activeSearchCampaigns}
            activeAdGroups={grade.accountSizeMetrics.activeAdGroups}
            activeKeywords={grade.accountSizeMetrics.activeKeywords}
            averageAdGroupsPerCampaign={grade.accountSizeMetrics.averageAdGroups}
            averageActiveKeywordsPerAdGroup={grade.accountSizeMetrics.averageAdGroupKeywords}
            activeResponsiveSearchAds={grade.accountSizeMetrics.activeResponsiveSearchAds}
            responsiveSearchAdsPercentage={grade.accountSizeMetrics.responsiveSearchAdsPercentage}
          />
          : <></>
      }
    </GradeAccordion>
    {gradeWithTextAds && <GradeAccordion
      id={t('metrics.textAds.title')}
      key={`${t('metrics.textAds.title')}-accordion`}
      expanded={expanded === GoogleAdsMetricsTabs.TEXT_ADS}
      handleChange={handleChange(GoogleAdsMetricsTabs.TEXT_ADS)}
      header={<Typography variant="h4" fontWeight={FontWeight.SemiBold}>{t('metrics.textAds.title')}</Typography>}>
      <TextAds />
    </GradeAccordion>}
    <GradeAccordion
      id={t('metrics.performanceTracker')}
      key={`${t('metrics.performanceTracker')}-accordion`}
      expanded={expanded === GoogleAdsMetricsTabs.PERFORMANCE_TRACKER}
      handleChange={handleChange(GoogleAdsMetricsTabs.PERFORMANCE_TRACKER)}
      header={<Typography variant="h4" fontWeight={FontWeight.SemiBold}>{t('metrics.performanceTracker')}</Typography>}>
      <PerformanceTracker />
    </GradeAccordion>
  </FlexBoxColumn>;
};
