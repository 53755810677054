import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useLocation, useParams} from 'react-router-dom';
import {TypedGrid} from 'src/components/common/Grid';
import {StyledPageContent} from 'src/components/common/styled/StyledPageElements';
import {GridType} from 'src/enums/GridType';
import {useFacebookGrade} from 'src/hooks/facebook/useFacebookGrade';
import {ServiceType} from 'src/enums/ServiceType';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {FlexBoxRowWithBackground, FooterDesktopHeight, NavBarHeight} from 'src/components/googleAds/page/PageContent';
import {Header} from '../grade/header/Header';
import {HeaderActions} from 'src/components/common/grade/header/headerActions';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {ResponsiveRow} from '../../common/ResponsiveRow';
import {Progress} from 'src/components/common/progress/Progress';
import {useContext, useEffect} from 'react';
import {LoadingContext} from '../../providers/loading';
import {FadeInTransition} from 'src/components/common/FadeInTransition';

type Props = {
  gridType: GridType;
  children: JSX.Element | JSX.Element[];
};

export const PageContent = ({gridType, children}: Props): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const {UUID} = useParams();
  const {isProgressBarComplete, isProgressBarRunning, setApiComplete, setProgressBarComplete} = useContext(LoadingContext);
  const {grade, error} = useFacebookGrade(UUID);
  const location = useLocation();
  const errord = !!error || location.pathname.includes('/error');

  useEffect(() => {
    setProgressBarComplete(false);
    setApiComplete(false);
  }, []);

  useEffect(() => {
    if (grade !== undefined || error !== undefined) {
      const timeout = setTimeout(() => setApiComplete(true), 500);
      return () => clearTimeout(timeout);
    }
  }, [grade, error]);

  return (
    <>
      <FlexBoxColumn gap={gridType === GridType.TAB ? isMobile ? 2 : 7 : 0}>
        <FlexBoxRowWithBackground gridType={gridType} errord={errord} isMobile={isMobile} isLoading={isProgressBarRunning} >
          <StyledPageContent
            sx={{
              // 64px - 64px -> paddingTop - paddingBottom
              height: !isProgressBarComplete && gridType === GridType.TAB
                ? isMobile
                  ? '100%'
                  : `calc(100vh - ${NavBarHeight} - 64px - 64px - ${FooterDesktopHeight})`
                : '100%',
              alignItems: gridType === GridType.TAB ? 'center' : 'stretch',
              alignSelf: gridType === GridType.TAB ? 'flex-start' : 'stretch',
              paddingTop: gridType === GridType.TAB ? isMobile ? 2 : !isProgressBarComplete ? 8 : 5 : 0,
              paddingBottom: gridType === GridType.TAB ? isMobile ? 5 : 8 : 0,
              gap: !isProgressBarComplete && gridType === GridType.TAB ? isMobile ? 3 : 0 : isMobile ? 5 : 6
            }}>
            {
              gridType === GridType.TAB
                ? <>
                  <ResponsiveRow
                    breakpoint={'sm'}
                    sx={{width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      py: isMobile ? 0 : 3,
                      gap: 3
                    }}
                  >
                    <FlexBoxColumn sx={{alignItems: 'flex-start', flex: '1 1 0'}} gap={1}>
                      <Typography variant="h3" color="common.white">
                        {t('title1')}
                      </Typography>
                      <Typography variant="h4" color="common.white" fontWeight={'600'}>
                        {t('title2')}
                      </Typography>
                    </FlexBoxColumn>
                    {
                      isProgressBarComplete && <Box sx={{alignSelf: isMobile ? 'stretch' : 'center'}}>
                        <HeaderActions googleAdType={GoogleAdType.NONE} />
                      </Box>
                    }
                  </ResponsiveRow>
                  <FlexBoxColumn sx={{alignSelf: 'stretch', flex: '1 0 0', pb: isProgressBarComplete ? 0 : 5}}>
                    {
                      !isProgressBarComplete
                        ? <FadeInTransition><Progress serviceType={ServiceType.FACEBOOK} /></FadeInTransition>
                        : <FadeInTransition><Header /></FadeInTransition>
                    }
                  </FlexBoxColumn>
                </>
                : <TypedGrid type={gridType}>
                  <FlexBoxColumn gap={7}>
                    <Typography variant='h1' color="common.white" >{t('title1')}&nbsp;Grader</Typography>
                    {children}
                  </FlexBoxColumn>
                </TypedGrid>
            }
          </StyledPageContent>
        </FlexBoxRowWithBackground>
        {
          !isProgressBarComplete
            ? <></>
            : <StyledPageContent data-testid="pageContent" sx={{justifyContent: 'flex-start'}}>
              {gridType === GridType.TAB && <TypedGrid type={gridType}>{children}</TypedGrid>}
            </StyledPageContent>
        }
      </FlexBoxColumn>
    </>
  );
};
