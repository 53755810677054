import { FormProvider } from 'react-hook-form';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { StyledFormLabel } from '../../common/forms/FormLabel';
import { FormInputText } from '../../common/forms/inputs/FormInputText';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { useKeywordForm } from './useKeywordForm';
import LoadingModal from '../loadingModal/LoadingModal';
import { LocationAutoComplete } from '../../common/forms/autocomplete/AutoComplete';
import FormInputSelect from '../../common/forms/inputs/FormInputSelect';
import { useContext, useEffect } from 'react';
import { KeywordsContext } from '../../providers/keywords';
import { ResponsiveRow } from '../../common/ResponsiveRow';
import { Box, Button, Link, styled, Typography, useMediaQuery } from '@mui/material';
import { FlexBoxRow } from '../../common/FlexBoxRow';
import { OrionTheme } from 'src/components/theme/orion/Theme';

const FlexBoxColumnFullWidth = styled(FlexBoxColumn)`
  width: 100%;
`;

interface IReCaptchaProps {
  insideModal: boolean;
  t: TFunction;
}

const ReCaptcha = ({ insideModal, t }: IReCaptchaProps): JSX.Element => {
  const color = insideModal ? OrionTheme.palette.common.black : OrionTheme.palette.common.white;
  const textDecorationColor = insideModal ? OrionTheme.palette.common.black : OrionTheme.palette.common.white;
  return (
    <Typography variant="body1" sx={{ fontSize: 14, color }}>
      <Trans i18nKey={'recaptcha'} ns={'common'}>
        <Link sx={{ color, textDecorationColor }} href="https://policies.google.com/privacy" target="_blank">
          {t('privacy', { ns: 'common' })}
        </Link>
        <Link sx={{ color, textDecorationColor }} href="https://policies.google.com/terms" target="_blank">
          {t('terms', { ns: 'common' })}
        </Link>
      </Trans>
    </Typography>
  );
};

interface IFormProps {
  textColor: string;
  modalFormat: boolean;
}

export const KeywordsForm = ({ textColor, modalFormat }: IFormProps): JSX.Element => {
  const { t } = useTranslation(['keywords', 'common']);
  const { methods, searchKeywords, categories } = useKeywordForm(modalFormat);
  const { keywordFormState } = useContext(KeywordsContext);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));
  const isTabletAndMobile = useMediaQuery(OrionTheme.breakpoints.down('md'));

  useEffect(() => {
    methods.setValue('websiteURLOrKeyword', keywordFormState.websiteURLOrKeyword);
    methods.setValue('industry', keywordFormState.industry);
    methods.setValue('location', keywordFormState.location);
  }, [keywordFormState]);

  return (
    <FormProvider {...methods}>
      <form style={{ width: '100%' }}>
        <>
          <Box sx={{ width: '100%' }}>
            <FlexBoxColumn sx={{ alignItems: 'flex-start', width: '100%' }} gap={2}>
              <ResponsiveRow
                sx={{ alignSelf: 'stretch', alignItems: 'flex-start', gap: isMobile ? 2.5 : 3.5 }}
                breakpoint={'md'}
                flexDirection={modalFormat ? 'column' : 'row'}
              >
                <FlexBoxColumnFullWidth sx={{ alignItems: 'flex-start' }} gap={1}>
                  <StyledFormLabel sx={{ color: `${textColor}`, fontWeight: 'fontWeightMedium', whiteSpace: 'normal' }}>
                    {t('form.label', { context: 'keywordsEnter' })}
                  </StyledFormLabel>
                  <FormInputText
                    data-testid="websiteURLOrKeyword"
                    sx={{ backgroundColor: 'common.white' }}
                    name={'websiteURLOrKeyword'}
                    size={modalFormat ? 'medium' : 'small'}
                    errorcolor={modalFormat ? 'error' : 'common.white'}
                  />
                </FlexBoxColumnFullWidth>
                <FlexBoxColumnFullWidth gap={1}>
                  <StyledFormLabel sx={{ color: `${textColor}`, fontWeight: 'fontWeightMedium' }}>
                    {t('form.label', { context: 'industry' })}
                  </StyledFormLabel>
                  <FlexBoxRow sx={{ alignSelf: 'stretch', backgroundColor: 'common.white', borderRadius: 0.5 }}>
                    <FormInputSelect
                      name="industry"
                      required={true}
                      options={[...categories]}
                      size={modalFormat ? 'medium' : 'small'}
                    />
                  </FlexBoxRow>
                </FlexBoxColumnFullWidth>
                <FlexBoxColumnFullWidth gap={1}>
                  <StyledFormLabel sx={{ color: `${textColor}`, fontWeight: 'fontWeightMedium' }}>
                    {t('form.label', { context: 'location' })}
                  </StyledFormLabel>
                  <FlexBoxRow sx={{ alignSelf: 'stretch', backgroundColor: 'common.white', borderRadius: 0.5 }}>
                    <LocationAutoComplete name={'location'} size={modalFormat ? 'medium' : 'small'} />
                  </FlexBoxRow>
                </FlexBoxColumnFullWidth>
                <FlexBoxColumn
                  sx={{
                    alignSelf:
                      isTabletAndMobile || modalFormat ? 'stretch' : methods.formState.isValid ? 'flex-end' : 'center',
                    alignItems: 'stretch',
                  }}
                >
                  {modalFormat ? (
                    <FlexBoxColumn sx={{ width: '100%', justifyContent: 'flex-start', gap: 2.5, pb: isMobile ? 2 : 0 }}>
                      <Button
                        size={'large'}
                        data-testid="buttonContinue"
                        aria-label={t('form.label', { context: 'buttonContinue' })}
                        variant="contained"
                        color="secondary"
                        onClick={searchKeywords}
                        disableRipple
                        id="refine-continue"
                        sx={{ alignSelf: 'flex-start', pointerEvents: methods.formState.isValid ? 'initial' : 'none' }}
                      >
                        {t('form.label', { context: 'buttonContinue' })}
                      </Button>
                      <ReCaptcha insideModal={modalFormat} t={t} />
                    </FlexBoxColumn>
                  ) : (
                    <Button
                      size={'medium'}
                      data-testid="buttonSearch"
                      aria-label={t('form.label', { context: 'buttonSearch' })}
                      variant="contained"
                      color="secondary"
                      onClick={searchKeywords}
                      disableRipple
                      id="header-search"
                      sx={{ pointerEvents: methods.formState.isValid ? 'initial' : 'none' }}
                    >
                      {t('form.label', { context: 'buttonSearch' })}
                    </Button>
                  )}
                </FlexBoxColumn>
              </ResponsiveRow>
              {modalFormat ? (
                <></>
              ) : (
                <FlexBoxRow sx={{ alignSelf: 'stretch', justifyContent: 'flex-start' }}>
                  <ReCaptcha insideModal={modalFormat} t={t} />
                </FlexBoxRow>
              )}
            </FlexBoxColumn>
          </Box>
        </>
        <LoadingModal />
      </form>
    </FormProvider>
  );
};
