import {DownloadPage} from 'src/components/keywords/downloadPage/DownloadPage';
import {Helmet} from 'react-helmet-async';
import useGATracking from 'src/hooks/google/useGATracking';
import {PageTitle} from 'src/enums/PageTitle';

export const Download = (): JSX.Element => {
  useGATracking({pageViewDefaults: {title: 'WordStream Free Keyword Tool'}, triggerOnMount: true});

  return <>
    <Helmet><title>{PageTitle.KEYWORDS}</title></Helmet>
    <DownloadPage />
  </>;
};
