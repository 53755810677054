import { useTranslation } from 'react-i18next';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { styled, Typography, useMediaQuery } from '@mui/material';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { LineHeight } from 'src/components/theme/orion/overrides/LineHeight';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { useContext } from 'react';
import { GoogleAdTypeContext } from '../../../providers/adType';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { GoogleShoppingScoreSection, ScoreSectionType } from 'src/enums/google/ScoreSection';
import { GradeUtils } from 'src/utils/GradeUtils';

const Label = styled(FlexBoxRow)`
  &:before {
    content: '';
    display: inline-block;
    min-width: ${({ theme }) => theme.spacing(2)};
    min-height: ${({ theme }) => theme.spacing(2)};
    border-radius: 50%;
    background: ${({ bcolor }: { bcolor: string }) => bcolor};
  }
`;

export const ChartLabel: React.FC<{ bcolor: string; children: JSX.Element }> = ({ bcolor, children }) => (
  <Label bcolor={bcolor}>{children}</Label>
);

type CommonChartLabelsProps = {
  responsive: boolean;
  section?: ScoreSectionType;
};

export const CommonChartLabels = ({ responsive, section }: CommonChartLabelsProps): JSX.Element => {
  const { isShopping } = useContext(GoogleAdTypeContext);
  const { t } = isShopping ? useTranslation([GoogleAdType.SHOPPING]) : useTranslation(['common']);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));
  const qualifier =
    section === GoogleShoppingScoreSection.CAMPAIGN_RANKINGS ? GradeUtils.getSectionKey(section) : 'common';

  return (
    <ResponsiveRow
      breakpoint={responsive ? 'sm' : undefined}
      gap={isMobile ? (responsive ? 0.5 : 3) : 3}
      sx={{ alignSelf: 'stretch', alignItems: isMobile ? 'flex-start' : 'center', mt: -1, pl: isMobile ? 9 : 0 }}
    >
      <ChartLabel bcolor={OrionColors.chart.orange}>
        <Typography sx={{ pl: 1 }} fontSize={isMobile ? '0.75rem' : '1rem'} lineHeight={LineHeight.LOOSE}>
          {t(`chartLabels.you_${qualifier}`)}
        </Typography>
      </ChartLabel>
      <ChartLabel bcolor={OrionColors.chart.blue}>
        <Typography sx={{ pl: 1 }} fontSize={isMobile ? '0.75rem' : '1rem'} lineHeight={LineHeight.LOOSE}>
          {t(`chartLabels.category_${qualifier}`)}
        </Typography>
      </ChartLabel>
    </ResponsiveRow>
  );
};
