import {IGradeProcess, IGoogleGradeProcessPayload} from 'src/types/google/GoogleProcess';
import {IFacebookGradeProcessPayload} from 'src/types/facebook/FacebookProcess';
import {ServiceType} from 'src/enums/ServiceType';
import {FontSpec} from 'chart.js';
import {NavigateFunction} from 'react-router-dom';

type ValueType = {
  $isintersecting: boolean;
  intersectionratio: number;
}

export type TimeIDRef = {
  timeID: NodeJS.Timeout | undefined;
}

const threshold = Array.from({length: 100}, (v, i) => i / 100);
const FIVE_MINS_IN_MILLI_SECS = 5 * 60 * 1000;

export const observer = (setValue: (value: ValueType) => void): IntersectionObserver => {
  return new window.IntersectionObserver(([{isIntersecting, intersectionRatio}]) => {
    setValue({$isintersecting: isIntersecting, intersectionratio: intersectionRatio});
  }, {
    root: null,
    threshold
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const getResponsiveChartFont = (context: any): Partial<FontSpec> => {
  const width = context.chart.width;
  const size = width > 600 ? 16 : 12;
  const lineHeight = width > 600 ? '24px' : '16px';
  const weight = width > 600 ? 'normal' : 'bold';

  return {
    family: 'Unify Sans',
    size,
    weight,
    lineHeight
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const getResponsiveMetricChartFont = (context: any): Partial<FontSpec> => {
  const width = context.chart.width;
  const size = width > 600 ? 16 : 12;
  const lineHeight = width > 600 ? '24px' : '16px';

  return {
    family: 'Unify Sans',
    weight: 'normal',
    size,
    lineHeight
  };
};

export interface SubscriptionInfo {
  id: number;
  userId: number;
  companyId: number;
  googleCustomerId: number;
  subscribed: boolean;
  emailSentDate: string;
}

export const postSubscribers = async (subscribed: boolean, service: ServiceType, UUID: string): Promise<SubscriptionInfo> => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_URL}/${service}/${UUID}/subscribers`,
    {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({subscribed})
    }
  )
    .then((response) => response.json());
};

export const postGoogleProcess = async (payload: IGoogleGradeProcessPayload): Promise<IGradeProcess> => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/google/process`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(payload)
  })
    .then((response) => response.json());
};

export const postFacebookProcess = async (payload: IFacebookGradeProcessPayload): Promise<IGradeProcess> => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/facebook/process`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(payload)
  })
    .then((response) => response.json());
};

export const capitalizeFirstLetter = (word: string | undefined): string => {
  if (!word) return '';

  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const DelayNavigationToTimedOutError = (navigate: NavigateFunction, serviceType: ServiceType): NodeJS.Timeout =>
  setTimeout(() => navigate(`/${serviceType}-grader/error?event=timedOut`), FIVE_MINS_IN_MILLI_SECS);

export const ConditionalClearTimeout = (timeIDRef: TimeIDRef): void => {
  if (timeIDRef.timeID) clearTimeout(timeIDRef.timeID);
};

// ex date received from backend => '2023-11-07'
export const ISODateParser = (date: string): Date => {
  const [year, month, day] = date.split('-').map((val) => parseInt(val, 10));
  return new Date(year, month - 1, day);
};

export const FormatCanonicalName = (name: string): string => {
  // The negative lookahead assertion (?!\s) checks if the comma is not already followed by a whitespace
  return name.replace(/,(?!\s)/g, ', ');
};
