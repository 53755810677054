import {ScoreName} from 'src/enums/ScoreName';

const SCORE_VALUE_UPPER_PERCENT_LIMIT = 0;
const SCORE_VALUE_MEDIUM_PERCENT_LIMIT = -20;

const CPC_VALUE_LOWER_PERCENT_LIMIT = 0;
const CPC_VALUE_MEDIUM_PERCENT_LIMIT = 20;

interface Props {
  you: number | undefined;
  benchmark: number | undefined;
  difference: number | undefined;
  youZeroNotUsed?: boolean;
}

// CTR Or Any Account Activity metric -> Higher your value, better the score
export const getScoreName = ({you, benchmark, difference, youZeroNotUsed = false}: Props): ScoreName => {
  if (you === 0 && youZeroNotUsed) return ScoreName.INTERMEDIATE; //warning color

  if (you !== undefined && you > 0 && !benchmark) return ScoreName.ADVANCED;

  if (difference !== undefined && difference >= SCORE_VALUE_UPPER_PERCENT_LIMIT) {
    return ScoreName.ADVANCED;
  } else if (difference !== undefined && difference < 0 && difference > SCORE_VALUE_MEDIUM_PERCENT_LIMIT) {
    return ScoreName.INTERMEDIATE;
  }

  return ScoreName.FOUNDATIONAL;
};

// CPC -> Lower your value, better the score
export const getScoreNameForCPC = ({you, benchmark, difference, youZeroNotUsed = false}: Props): ScoreName => {
  if (you === 0 && youZeroNotUsed) return ScoreName.INTERMEDIATE; //warning color

  if (you !== undefined && you <= 0 && !benchmark) return ScoreName.ADVANCED;

  if (difference !== undefined && difference > CPC_VALUE_LOWER_PERCENT_LIMIT && difference <= CPC_VALUE_MEDIUM_PERCENT_LIMIT) {
    return ScoreName.INTERMEDIATE;
  } else if (difference !== undefined && difference > CPC_VALUE_MEDIUM_PERCENT_LIMIT) {
    return ScoreName.FOUNDATIONAL;
  }

  return ScoreName.ADVANCED;
};
