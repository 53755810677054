import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ScoreName} from 'src/enums/ScoreName';
import {GradeUtils} from 'src/utils/GradeUtils';
import {useFacebookGrade} from './useFacebookGrade';
import {FacebookAdsScoreSection} from 'src/enums/facebook/ScoreSection';
import {IFacebookGrade} from 'src/types/facebook/FacebookGrade';
import {ServiceType} from 'src/enums/ServiceType';

const EMPTY_CHAR = '\u00a0';

type Props = {
  sectionName?: FacebookAdsScoreSection;
}

export type FacebookGradeScoreType = {
  grade: IFacebookGrade | undefined;
  scoreName: ScoreName;
  scoreValue: number | string;
  date: string;
  currencySymbol: string;
}

export const useFAGradeScore = ({sectionName}: Props): FacebookGradeScoreType => {
  const {UUID} = useParams();
  const {grade} = useFacebookGrade(UUID!);
  const [scoreName, setScoreName] = useState<ScoreName>(ScoreName.FOUNDATIONAL);
  const [scoreValue, setScoreValue] = useState<number | string>(EMPTY_CHAR);
  const [date, setDate] = useState<string>(new Date().toLocaleDateString(process.env.REACT_APP_LOCALE));
  const [currencySymbol, setCurrencySymbol] = useState<string>('$');

  useEffect(() => {
    if (!grade) return;

    setScoreName(GradeUtils.getScoreName(grade, ServiceType.FACEBOOK, sectionName));
    if (sectionName) {
      setScoreValue(GradeUtils.getScoreValue(sectionName, grade, ServiceType.FACEBOOK,));
    } else {
      setScoreValue(GradeUtils.roundoff(grade.overallScore.overallScore, 0));
    }

    if (grade.overallScore.reportDate) {
      setDate(new Date(grade.overallScore.reportDate.replace(/-/g, '/')).toLocaleDateString(process.env.REACT_APP_LOCALE));
    }
    if (grade.accountSummary.currencySymbol) {
      setCurrencySymbol(grade.accountSummary.currencySymbol);
    }
  }, [grade]);

  return {grade, scoreName, scoreValue, date, currencySymbol};
};
