import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { useShoppingGradeScore } from 'src/hooks/shopping/useShoppingGradeScrore';
import { GoogleShoppingScoreSection } from 'src/enums/google/ScoreSection';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { GradeUtils } from 'src/utils/GradeUtils';
import { CampaignRankTable } from './table/CampaignRankTable';
import { ICampaignRank } from 'src/types/shopping/ShoppingGrade';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { MobileCampaignRankTable } from './table/MobileCampaignRankTable';
import { CampaignType } from 'src/enums/shopping/CampaignRank';

export const CampaignResults = (): JSX.Element => {
  const { shoppingGrade, currencySymbol } = useShoppingGradeScore({
    sectionName: GoogleShoppingScoreSection.CAMPAIGN_RANKINGS,
  });
  const { t } = useTranslation([GoogleAdType.SHOPPING]);
  const [worstCampaigns, setWorstCampaigns] = useState<ICampaignRank[]>();
  const [bestCampaignArray, setBestCampaignArray] = useState<ICampaignRank[]>();
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));

  useEffect(() => {
    if (shoppingGrade?.campaignRankings) {
      setWorstCampaigns(GradeUtils.getWorstShoppingCampaigns(shoppingGrade.campaignRankings.campaigns));
      setBestCampaignArray(GradeUtils.getBestShoppingCampaign(shoppingGrade.campaignRankings.campaigns));
    }
  }, [shoppingGrade?.campaignRankings]);

  return (
    <>
      {isMobile ? (
        <FlexBoxColumn gap={3} sx={{ alignItems: 'flex-start', width: '100%' }}>
          {worstCampaigns && worstCampaigns.length > 0 && (
            <MobileCampaignRankTable
              type={CampaignType.RANKING_WORST}
              campaigns={worstCampaigns}
              currencySymbol={currencySymbol}
            />
          )}
          {bestCampaignArray && bestCampaignArray.length > 0 && (
            <MobileCampaignRankTable
              type={CampaignType.RANKING_WORST}
              campaigns={bestCampaignArray}
              currencySymbol={currencySymbol}
            />
          )}
        </FlexBoxColumn>
      ) : (
        <FlexBoxColumn gap={5} sx={{ alignItems: 'flex-start', width: '100%' }}>
          {worstCampaigns && worstCampaigns.length > 0 && (
            <FlexBoxColumn sx={{ alignSelf: 'stretch', alignItems: 'flex-start' }}>
              <BoldTypography variant="body2">{t('grade.campaignRankings.table.titles.optimizations')}</BoldTypography>
              <CampaignRankTable campaigns={worstCampaigns} currencySymbol={currencySymbol} />
            </FlexBoxColumn>
          )}
          {bestCampaignArray && bestCampaignArray.length > 0 && (
            <FlexBoxColumn sx={{ alignSelf: 'stretch', alignItems: 'flex-start' }}>
              <BoldTypography variant="body2">{t('grade.campaignRankings.table.titles.topPerformer')}</BoldTypography>
              <CampaignRankTable campaigns={bestCampaignArray} currencySymbol={currencySymbol} applyBgColor={false} />
            </FlexBoxColumn>
          )}
        </FlexBoxColumn>
      )}
    </>
  );
};
