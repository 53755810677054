import {Grid} from '@mui/material';
import {GridType} from '../../enums/GridType';

const getGridItems = (children: JSX.Element | JSX.Element[], type?: GridType): JSX.Element => {
  switch (type) {
    case GridType.TAB:
      return (
        <>
          <Grid item lg={12} md={10} sm={8} xs={4}>
            {children}
          </Grid>
        </>
      );
    case GridType.TAB_PANEL_CONTENT:
    case GridType.MESSAGE_CONTENT:
      return (
        <>
          <Grid item lg={12} md={10} sm={8} xs={4}>
            {children}
          </Grid>
        </>
      );
    default:
      return (
        <>
          <Grid item lg={2} md={1} sm={1} xs={0} />
          <Grid item lg={8} md={8} sm={6} xs={4}>
            {children}
          </Grid>
          <Grid item lg={2} md={1} sm={1} xs={0} />
        </>
      );
  }
};

export const TypedGrid: React.FC<{type?: GridType; children: JSX.Element | JSX.Element[]}> = ({type, children}): JSX.Element => {
  return (
    <Grid
      container
      direction='row'
      columns={{lg: 12, md: 10, sm: 8, xs: 4}}
      spacing={0}
    >
      {getGridItems(children, type)}
    </Grid>
  );
};
