export enum DisplayAdType {
  RESPONSIVE = 'RESPONSIVE',
  ANIMATED = 'ANIMATED',
  OTHER = 'OTHER'
}

export enum DisplayAdPerformanceType {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export enum DisplayAdSection {
  RESPONSIVE = 'responsiveAdsMetrics',
  ANIMATED = 'animatedAdsMetrics',
  OTHER = 'otherAdsMetrics'
}

export enum DisplayAdSectionTitle {
  RESPONSIVE = 'Responsive Display Ads',
  ANIMATED = 'HTML5 Ads',
  OTHER = 'Text, Image, Other'
}

export enum AdditionalMetric {
  AD_COUNT = 'adsCount',
  AD_GROUPS = 'adGroupsCount',
  IMPRESSIONS = 'impressions',
}

export type AdditionalMetricKey = keyof typeof AdditionalMetric;
