import {useCallback, useState} from 'react';
import {IErrorResponse} from 'src/types/common/Error';
import {
  IEmailReportPayload,
  IEmailReportResponse,
  IEmailReportFormData,
  IEmailReportProps,
} from '../../types/common/IEmailReport';

type ReturnType = {
  sendEmail: (props: IEmailReportProps) => void;
  response: IEmailReportResponse | undefined;
  loading: boolean;
  error: IErrorResponse | undefined;
};

function formatPayload (formData: IEmailReportFormData): IEmailReportPayload {
  return {
    emailRecipient: formData.shareEmail,
    senderEmail: formData.fromEmail,
    message: formData.messageText
  };
}

type Props = {
  reportTo: string;
  reportFrom: string;
  message?: string;
}

export const useEmailReport = (emailProps: Props): ReturnType => {
  const [response, setResponse] = useState<IEmailReportResponse>();
  const [error, setError] = useState<IErrorResponse>();

  const sendEmail = useCallback((props: IEmailReportProps) => {
    const URL = `${process.env.REACT_APP_BACKEND_URL}/${emailProps.reportTo}/grade/${props.uuid}/email`;

    fetch(URL, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(formatPayload(props)),
    })
      .then((res) => res.json())
      .then((res) => setResponse(res))
      .catch((e) => setError(e));
  }, []);

  return {
    response,
    loading: !response && !error,
    error,
    sendEmail,
  };
};
