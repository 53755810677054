import {LineController} from 'chart.js';

export class ShadowLineController extends LineController {
  draw (): void {
    const ctx = this.chart.ctx;
    ctx.shadowColor = 'white';
    ctx.shadowBlur = 4;
    ctx.shadowOffsetX = 1;
    ctx.shadowOffsetY = 1;
    this.getMeta().data.forEach(() => super.draw());
  }
}
  
ShadowLineController.id = 'shadowLine';
