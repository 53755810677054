import useSWR from 'swr';
import {IErrorResponse} from 'src/types/common/Error';
import {GradeProcessorStatus, useProcessGrade} from './useProcessGrade';
import {IOverallGrade} from 'src/types/overall/OverallGrades';

export interface IOverallGradeResponse {
  overallGrade: IOverallGrade | undefined;
  overallError: IErrorResponse | undefined;
  b4Shopping: boolean;
}

const SHOPPING_GO_LIVE_DATE = '2024-03-28';

export const useOverallGrade = (uuid: string | undefined): IOverallGradeResponse => {
  const URL = `${process.env.REACT_APP_BACKEND_URL}/google/grade`;

  /*
   Removed conditional calling of useProcessGrade to let SWR logic manage state
    - whether a new report or not we make the first api call and we don't have to manually handle state
    regarding a new report, a completed/previous report, or a reload condition for a new report mid process
   */
  const {processorData, processorError} = useProcessGrade(uuid);

  const {data, error} = useSWR<IOverallGrade>(
    uuid && processorData && processorData.status30Days === GradeProcessorStatus.COMPLETE
      && processorData.status90Days === GradeProcessorStatus.COMPLETE
      ? `${URL}/${uuid}/scores`
      : null
  );

  const reportDateStr = data?.reportDate;
  const reportDate = reportDateStr ? new Date(reportDateStr) : new Date('1900-01-01');
  const cutoffDate = new Date(SHOPPING_GO_LIVE_DATE);
  const b4Shopping = reportDate < cutoffDate;

  return {
    overallGrade: data,
    overallError: processorError ?? error,
    b4Shopping
  };
};
