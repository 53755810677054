import {useTranslation} from 'react-i18next';
import {StyledCardWithPadding} from '../../common/styled/StyledCard';
import {FormInputText} from '../../common/forms/inputs/FormInputText';
import {FormProvider, useForm} from 'react-hook-form';
import FormInputSelect from '../../common/forms/inputs/FormInputSelect';
import {FormHeader} from '../../common/forms/FormHeader';
import {FlexBoxColumn} from '../../common/FlexBoxColumn';
import {StyledFormLabel} from '../../common/forms/FormLabel';
import {yupResolver} from '@hookform/resolvers/yup';
import {useManageCategories} from 'src/components/googleAds/formStepTwo/useManageCategories';
import {useSubmitForm} from './useSubmitForm';
import {memo} from 'react';
import {StyledRowStack} from 'src/components/common/styled/StyledRowStack';
import {FormStepsNextButton} from 'src/components/common/styled/FormStepsNextButton';
import {useFAContext} from 'src/components/providers/facebookAds';
import {ITranslation} from 'src/types/common/Translation';
import * as Yup from 'yup';

const urlRegex = new RegExp(/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ig);

const getValidationSchema = ({t}: ITranslation) => Yup.object().shape({
  businessName: Yup.string().required(t('errors.required')),
  website: Yup.string().required(t('errors.required')).matches(urlRegex, t('errors.websiteNotValid')),
  categoryId: Yup.string().optional(),
  subcategoryId: Yup.string().optional()
});

export const FormStepTwo = memo(function FormStepTwo (): JSX.Element {
  const {t} = useTranslation(['common']);
  const context = useFAContext();

  const methods = useForm({
    defaultValues: {
      businessName: context.businessDetails.businessName ?? '',
      website: context.businessDetails.website ?? '',
      categoryId: context.businessDetails.categoryId?.toString() ?? '',
      subcategoryId: context.businessDetails.subcategoryId?.toString() ?? '',
    },
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema({t}))
  });

  const {categories, subCategories, onCategoryChange} = useManageCategories(context);
  const {disabled, onSubmit} = useSubmitForm(methods.getValues);

  return (
    <FormProvider {...methods}>
      <form style={{width: '100%'}}>
        <FlexBoxColumn>
          <StyledCardWithPadding sx={{gap: 3}}>
            <FormHeader step={context.currentStep} />
            <FlexBoxColumn sx={{width: '100%', gap: 2.5}}>
              <FlexBoxColumn sx={{width: '100%', gap: 1}}>
                <StyledFormLabel error={!!methods.formState.errors.businessName}>
                  {t('formSteps.step2.businessName')}
                </StyledFormLabel>
                <FormInputText data-testid="businessName" name={'businessName'} />
              </FlexBoxColumn>
              <FlexBoxColumn sx={{width: '100%', gap: 1}}>
                <StyledFormLabel error={!!methods.formState.errors.website}>
                  {t('formSteps.step2.website')}
                </StyledFormLabel>
                <FormInputText data-testid="website" name={'website'} />
              </FlexBoxColumn>
              <FlexBoxColumn sx={{width: '100%', gap: 1}}>
                <StyledFormLabel>{t('formSteps.step2.category')}</StyledFormLabel>
                <FormInputSelect testid='categorySelect' name='categoryId' options={categories} changeHandler={onCategoryChange} />
              </FlexBoxColumn>
              <FlexBoxColumn sx={{width: '100%', gap: 1}}>
                <StyledFormLabel>{t('formSteps.step2.subCategory')}</StyledFormLabel>
                <FormInputSelect testid='subcategorySelect' name='subcategoryId' options={subCategories} />
              </FlexBoxColumn>
            </FlexBoxColumn>
            <StyledRowStack sx={{justifyContent: 'center'}}>
              <FormStepsNextButton onSubmit={onSubmit} disabled={disabled} id="fb-business-info-next" />
            </StyledRowStack>
          </StyledCardWithPadding>
        </FlexBoxColumn>
      </form>
    </FormProvider>
  );
});
