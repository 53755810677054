import {Typography} from '@mui/material';
import React, {SyntheticEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from '../../common/FlexBoxColumn';
import {useParams} from 'react-router-dom';
import {MetricsTab} from 'src/components/common/metrics/MetricsTab';
import {PerformanceTracker} from './tracker/PerformanceTracker';
import {AccountSummary} from './accountSummary/AccountSummary';
import {PlatformOverview} from 'src/components/facebook/metrics/platformOverview/PlatformOverview';
import {AdPerformance} from 'src/components/facebook/metrics/adPerformance/AdPerformance';
import {ServiceType} from 'src/enums/ServiceType';
import {useFacebookGrade} from 'src/hooks/facebook/useFacebookGrade';
import {FacebookAdsMetricsTabs} from 'src/enums/MetricTabType';
import {TabsWrapper} from 'src/components/common/styled/TabsWrapper';
import {Tabs} from 'src/components/common/styled/Tabs';
import {GradeAccordion} from 'src/components/common/grade/Accordion';
import {useFetchHistory} from 'src/hooks/common/useFetchHistory';

interface IFacebookAdsMetricsTab {
  tabName: FacebookAdsMetricsTabs;
  tabKey: keyof typeof FacebookAdsMetricsTabs;
  visible: boolean;
}

const TABS = [
  {
    tabName: FacebookAdsMetricsTabs.ACCOUNT_SUMMARY,
    tabKey: 'ACCOUNT_SUMMARY',
    visible: true
  },
  {
    tabName: FacebookAdsMetricsTabs.PLATFORM_OVERVIEW,
    tabKey: 'PLATFORM_OVERVIEW',
    visible: false
  },
  {
    tabName: FacebookAdsMetricsTabs.AD_PERFORMANCE,
    tabKey: 'AD_PERFORMANCE',
    visible: false
  },
  {
    tabName: FacebookAdsMetricsTabs.PERFORMANCE_TRACKER,
    tabKey: 'PERFORMANCE_TRACKER',
    visible: true
  }
] as IFacebookAdsMetricsTab[];

export const MetricsTabs = (): JSX.Element => {
  const {UUID} = useParams();
  const [selected, setSelected] = useState<FacebookAdsMetricsTabs>(FacebookAdsMetricsTabs.ACCOUNT_SUMMARY);
  const handleChange = (event: SyntheticEvent<Element, Event>, value: FacebookAdsMetricsTabs) => setSelected(value);
  const {grade} = useFacebookGrade(UUID);
  const [tabs, setTabs] = useState<IFacebookAdsMetricsTab[]>(TABS);
  useFetchHistory({UUID}); // Prefetch PerformanceTracker data for improved User Experience

  useEffect(() => {
    if (grade) {
      setTabs(tabs.map((visibleTab: IFacebookAdsMetricsTab) => ({
        ...visibleTab,
        visible: visibleTab.tabName === FacebookAdsMetricsTabs.PLATFORM_OVERVIEW
          ? grade.platformOverview && Array.isArray(grade.platformOverview.metricsByPlatform) && grade.platformOverview.metricsByPlatform.length > 0
          : visibleTab.tabName === FacebookAdsMetricsTabs.AD_PERFORMANCE
            ? grade.adsPerformance && Array.isArray(grade.adsPerformance.adMetrics) && grade.adsPerformance.adMetrics.length > 0
            : visibleTab.visible
      })) as IFacebookAdsMetricsTab[]);
    }
  }, [grade]);

  return <TabsWrapper>
    <Tabs value={selected} onChange={handleChange}>
      {
        Object.values(tabs).map((tab: IFacebookAdsMetricsTab) =>
          <MetricsTab name={tab.tabName} value={tab.tabName} onChange={() => handleChange} key={tab.tabKey} splitLabelText={true} />
        )
      }
    </Tabs>
    <div role='tabpanel'>
      {
        tabs.find((tab: IFacebookAdsMetricsTab) => tab.tabName === FacebookAdsMetricsTabs.PERFORMANCE_TRACKER)?.visible
        && selected === FacebookAdsMetricsTabs.PERFORMANCE_TRACKER
          && <PerformanceTracker key={FacebookAdsMetricsTabs.PERFORMANCE_TRACKER} />
      }
      {
        tabs.find((tab: IFacebookAdsMetricsTab) => tab.tabName === FacebookAdsMetricsTabs.ACCOUNT_SUMMARY)?.visible
        && selected === FacebookAdsMetricsTabs.ACCOUNT_SUMMARY
          && <AccountSummary key={FacebookAdsMetricsTabs.ACCOUNT_SUMMARY} />
      }
      {
        tabs.find((tab: IFacebookAdsMetricsTab) => tab.tabName === FacebookAdsMetricsTabs.PLATFORM_OVERVIEW)?.visible
        && selected === FacebookAdsMetricsTabs.PLATFORM_OVERVIEW
          && <PlatformOverview key={FacebookAdsMetricsTabs.PLATFORM_OVERVIEW} />
      }
      {
        tabs.find((tab: IFacebookAdsMetricsTab) => tab.tabName === FacebookAdsMetricsTabs.AD_PERFORMANCE)?.visible
        && selected === FacebookAdsMetricsTabs.AD_PERFORMANCE
          && <AdPerformance key={FacebookAdsMetricsTabs.AD_PERFORMANCE} />
      }
    </div>
  </TabsWrapper>;
};

export const MetricsAccordions = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);
  const {UUID} = useParams();
  const {grade} = useFacebookGrade(UUID);
  const [expanded, setExpanded] = useState<string | false>(t('metrics.accountSummary.tab'));
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);

    setTimeout(() => {
      if (!isExpanded) return;
      document.getElementById(panel)?.
        scrollIntoView({behavior: 'smooth', block: 'start'});
    }, 100);
  };

  return <FlexBoxColumn sx={{gap: 2, mb: 2, width: '100%'}}>
    <GradeAccordion
      id={t('metrics.accountSummary.tab')}
      header={<Typography variant="h3">{t('metrics.accountSummary.tab')}</Typography>}
      expanded={expanded === t('metrics.accountSummary.tab')}
      handleChange={handleChange(t('metrics.accountSummary.tab'))}>
      <AccountSummary />
    </GradeAccordion>
    {grade && grade.platformOverview?.metricsByPlatform.length ?
      <GradeAccordion
        id={t('metrics.platformOverview.tab')}
        header={<Typography variant="h3">{t('metrics.platformOverview.tab')}</Typography>}
        expanded={expanded === t('metrics.platformOverview.tab')}
        handleChange={handleChange(t('metrics.platformOverview.tab'))}>
        <PlatformOverview />
      </GradeAccordion>
      :
      <></>}
    {grade && grade.adsPerformance?.adMetrics?.length ?
      <GradeAccordion
        id={t('metrics.adPerformance.tab')}
        header={<Typography variant="h3">{t('metrics.adPerformance.tab')}</Typography>}
        expanded={expanded === t('metrics.adPerformance.tab')}
        handleChange={handleChange(t('metrics.adPerformance.tab'))}>
        <AdPerformance />
      </GradeAccordion>
      :
      <></>}
    <GradeAccordion
      id={t('metrics.performanceTracker.tab')}
      header={<Typography variant="h3">{t('metrics.performanceTracker.tab')}</Typography>}
      expanded={expanded === t('metrics.performanceTracker.tab')}
      handleChange={handleChange(t('metrics.performanceTracker.tab'))}>
      <PerformanceTracker />
    </GradeAccordion>
  </FlexBoxColumn>;
};
