import {
  Box,
  Divider,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { Gender } from 'src/enums/facebook/Gender';
import { FacebookAdsScoreSection } from 'src/enums/facebook/ScoreSection';
import { useFAGradeScore } from 'src/hooks/facebook/useFAGradeScore';
import { IAPDemographics } from 'src/types/facebook/FacebookGrade';
import { GradeUtils } from 'src/utils/GradeUtils';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { OrionTheme } from 'src/components/theme/orion/Theme';

type GendersChartProps = {
  genders: IAPDemographics[];
};

type InnerBarProps = {
  color: string;
  width: string;
  side: 'left' | 'right';
};

const InnerBar = styled('div')<InnerBarProps>(({ theme, color, width, side }) => ({
  height: '100%',
  width: '100%',
  maxWidth: width,
  background: color,
  borderTopLeftRadius: side === 'left' && width === '100%' ? theme.spacing(3) : 0,
  borderBottomLeftRadius: side === 'left' && width === '100%' ? theme.spacing(3) : 0,
  borderTopRightRadius: side === 'right' && width === '100%' ? theme.spacing(3) : 0,
  borderBottomRightRadius: side === 'right' && width === '100%' ? theme.spacing(3) : 0,
}));

const GendersChart = ({ genders }: GendersChartProps) => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const male = genders.find((item: IAPDemographics) => item.name.toLowerCase() === Gender.MALE)!;
  const female = genders.find((item: IAPDemographics) => item.name.toLowerCase() === Gender.FEMALE)!;
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));

  return (
    <FlexBoxColumn
      gap={2}
      sx={{
        alignSelf: 'stretch',
        alignItems: 'flex-start',
        justifyContent: 'center',
        pt: isMobile ? 0 : 7,
        pb: isMobile ? 0 : 5.5,
      }}
    >
      <FlexBoxRow sx={{ width: '100%', height: '40px' }} gap={1}>
        <FlexBoxRow
          sx={{
            justifyContent: 'flex-end',
            width: '50%',
            height: '100%',
            backgroundColor: OrionColors.chart.orangeLight,
            borderTopLeftRadius: '24px',
            borderBottomLeftRadius: '24px',
          }}
        >
          <InnerBar width={`${female.percentClicks}%`} color={OrionColors.chart.orange} side={'left'} />
        </FlexBoxRow>
        <Box
          sx={{
            width: '50%',
            height: '100%',
            backgroundColor: OrionColors.chart.blueLight,
            borderTopRightRadius: '24px',
            borderBottomRightRadius: '24px',
          }}
        >
          <InnerBar width={`${male.percentClicks}%`} color={OrionColors.chart.blue} side={'right'} />
        </Box>
      </FlexBoxRow>
      <FlexBoxRow sx={{ width: '100%', justifyContent: 'space-around' }}>
        <Typography variant="h6" fontWeight={female.clicks > male.clicks ? 600 : 400}>
          {t('grade.audiencePerformance.table.female')} {female.percentClicks}%
        </Typography>
        <Typography variant="h6" fontWeight={male.clicks > female.clicks ? 600 : 400}>
          {t('grade.audiencePerformance.table.male')} {male.percentClicks}%
        </Typography>
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};

type FlexCellProps = {
  value: string;
  pl?: number;
  pr?: number;
  withoutDivider?: boolean;
  isBold?: boolean;
};

const FlexCell = ({ value, pl, pr, withoutDivider, isBold }: FlexCellProps) => (
  <>
    <FlexBoxColumn pl={pl} pr={pr} sx={{ width: '100%', height: !withoutDivider ? '55px' : '' }}>
      <Typography fontWeight={isBold ? 600 : 400} sx={{ width: '100%' }} pl={pl} variant="h6">
        {value}
      </Typography>
    </FlexBoxColumn>
    {withoutDivider ? (
      <></>
    ) : (
      <Divider sx={{ width: '96%', borderColor: OrionColors.gray.neutral, alignSelf: 'center' }} />
    )}
  </>
);

export const TopDemographics = (): JSX.Element => {
  const { grade } = useFAGradeScore({});
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));

  return (
    <ResponsiveRow sx={{ gap: 6 }} breakpoint={'sm'}>
      <Table sx={{ tableLayout: 'fixed', width: isMobile ? '100%' : '60%' }}>
        <TableHead>
          <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
            <TableCell>
              <BoldTypography variant="body2">{t('grade.audiencePerformance.table.gender')}</BoldTypography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
            <TableCell>
              {grade?.audiencePerformance.bestPerformingGender && (
                <GendersChart genders={grade.audiencePerformance.bestPerformingGender} />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <ResponsiveRow breakpoint={'sm'} sx={{ width: isMobile ? '100%' : '40%' }}>
        <Table sx={{ tableLayout: 'fixed', width: isMobile ? '100%' : '50%', height: '265px' }}>
          <TableHead>
            <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
              <TableCell>
                <BoldTypography variant="body2">{t('grade.audiencePerformance.table.age')}</BoldTypography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {grade?.audiencePerformance.bestPerformingAgeGroup.map((ageGroup) => (
              <TableRow key={ageGroup.name} sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
                <TableCell>
                  {GradeUtils.clearAgeGroup(ageGroup.name)} ({ageGroup.percentClicks}%)
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Table sx={{ tableLayout: 'fixed', width: isMobile ? '100%' : '50%', height: '265px' }}>
          <TableHead>
            <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
              <TableCell sx={{ verticalAlign: 'top' }}>
                <BoldTypography variant="body2">{t('grade.audiencePerformance.table.location')}</BoldTypography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {grade && grade.audiencePerformance.bestPerformingLocation.length > 0 ? (
              grade.audiencePerformance.bestPerformingLocation.map((location) => (
                <TableRow key={location.name} sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
                  <TableCell sx={{ verticalAlign: 'top' }}>
                    {location.name} ({location.percentClicks}%)
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <Typography sx={{ pt: 4 }}>{t('grade.audiencePerformance.table.noLocation')}</Typography>
            )}
          </TableBody>
        </Table>
      </ResponsiveRow>
    </ResponsiveRow>
  );
};

export const TopDemographicsMobile = (): JSX.Element => {
  const { grade } = useFAGradeScore({ sectionName: FacebookAdsScoreSection.ACCOUNT_ACTIVITY });
  const { t } = useTranslation([ServiceType.FACEBOOK]);

  return (
    <FlexBoxColumn sx={{ width: '100%', alignItems: 'flex-start' }} gap={2}>
      <BoldTypography variant="body2">{t('grade.audiencePerformance.table.gender')}</BoldTypography>
      {grade?.audiencePerformance.bestPerformingGender && (
        <GendersChart genders={grade.audiencePerformance.bestPerformingGender} />
      )}
      <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
      <BoldTypography pl={1} variant="body2">
        {t('grade.audiencePerformance.table.age')}
      </BoldTypography>
      {grade?.audiencePerformance.bestPerformingAgeGroup ? (
        grade.audiencePerformance.bestPerformingAgeGroup.map((ageGroup, i) => {
          return (
            <FlexCell
              isBold={i === 0}
              withoutDivider
              key={ageGroup.name}
              pl={1}
              value={`${GradeUtils.clearAgeGroup(ageGroup.name)} (${ageGroup.percentClicks}%)`}
            />
          );
        })
      ) : (
        <> -- </>
      )}
      <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
      <BoldTypography pl={1} variant="body2" textAlign="left">
        {t('grade.audiencePerformance.table.location')}
      </BoldTypography>
      {grade?.audiencePerformance.bestPerformingLocation.length ? (
        grade.audiencePerformance.bestPerformingLocation.map((location, i) => {
          return (
            <FlexCell
              isBold={i === 0}
              withoutDivider={true}
              key={location.name}
              pl={1}
              value={`${location.name} (${location.percentClicks}%)`}
            />
          );
        })
      ) : (
        <Typography variant="h6" sx={{ pl: 1 }}>
          {t('grade.audiencePerformance.table.noLocation')}
        </Typography>
      )}
    </FlexBoxColumn>
  );
};
