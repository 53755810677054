import React, {useMemo} from 'react';
import {SizeOfAccount} from 'src/components/common/metrics/SizeOfAccount';
import {IShoppingGrade} from 'src/types/shopping/ShoppingGrade';
import {ShoppingMetrics} from './ShoppingMetrics';
import {PerformanceTracker} from './tracker/PerformanceTracker';
import {ShoppingMetricsTabName} from 'src/enums/shopping/MetricsTabName';

type Props = {
  selected: ShoppingMetricsTabName;
  shoppingGrade: IShoppingGrade | undefined;
}

type ReturnType = {
  TabPanels: JSX.Element[];
}

type ContentProps = {
  tabKey: ShoppingMetricsTabName;
  shoppingGrade: IShoppingGrade | undefined;
}

const renderPanelContent = ({tabKey, shoppingGrade}: ContentProps) => {
  let value: JSX.Element;
  switch (tabKey) {
    case ShoppingMetricsTabName.PerformanceMetrics:
      value = <ShoppingMetrics />;
      break;
    case ShoppingMetricsTabName.PerformanceTracker:
      value = <PerformanceTracker />;
      break;
    case ShoppingMetricsTabName.SizeOfAccount:
      value = shoppingGrade
        ? <SizeOfAccount
          activeCampaigns={shoppingGrade.accountStructureMetrics.activeCampaigns}
          activeAdGroups={shoppingGrade.accountStructureMetrics.activeAdGroups}
          averageAdGroupsPerCampaign={shoppingGrade.accountStructureMetrics.averageAdGroupsPerCampaign}
          activeProductGroups={shoppingGrade.accountStructureMetrics.activeProductGroups}
          averageProductGroupsPerAdGroup={shoppingGrade.accountStructureMetrics.averageProductGroupsPerAdGroup}
          activeProducts={shoppingGrade.accountStructureMetrics.activeProducts}
        />
        : <></>;
      break;
  }
  return value!;
};

export const useTabPanels = ({shoppingGrade, selected}: Props): ReturnType => {
  const TabPanels = useMemo(() => {
    return Object.values(ShoppingMetricsTabName).map((tabKey) => {
      return <React.Fragment key={tabKey}>
        {selected === tabKey && renderPanelContent({tabKey, shoppingGrade})}
      </React.Fragment>;
    }) as JSX.Element[];

  }, [selected]);

  return {TabPanels};
};
