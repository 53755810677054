import { forwardRef } from 'react';
import clsx from 'clsx';

// Reference -> https://github.com/mui/material-ui/pull/31923
const BackdropUnstyled = forwardRef<HTMLDivElement, { open?: boolean; className?: string }>((props, ref) => {
  const { open, className, ...other } = props;
  return <div className={clsx({ 'MuiBackdrop-open': open }, className)} ref={ref} {...other} />;
});

BackdropUnstyled.displayName = 'BackdropUnstyled';

export default BackdropUnstyled;
