import {Typography} from '@mui/material';
import {useContext} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ResponsiveRow} from 'src/components/common/ResponsiveRow';
import {GoogleAdTypeContext} from 'src/components/providers/adType';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {ServiceType} from 'src/enums/ServiceType';
import {GradeSize, ScoreBox} from 'src/components/common/ScoreBox';
import styled from 'styled-components';
import {useGradeSectionScore} from 'src/hooks/common/useGradeSectionScore';
import {ServiceContext} from 'src/components/providers/service';

const ScoreContentContainer = styled(ResponsiveRow).attrs((props) => ({...props, breakpoint: 600}))`
  position: relative;
  background: ${({theme}) => theme.palette.common.white};
  padding: ${({theme}) => theme.spacing(3)};
  border-radius: ${({theme}) => theme.spacing(1)};
  gap: ${({theme}) => theme.spacing(3)};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14);
`;

export const ScoreContent = (): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const {isDisplay, isShopping} = useContext(GoogleAdTypeContext);
  const nameSpace = isDisplay ? GoogleAdType.DISPLAY : isShopping ? GoogleAdType.SHOPPING : ServiceType.GOOGLE;
  const {t} = useTranslation([nameSpace, 'common']);
  const sectionName = '';
  const {scoreName, scoreValue} = useGradeSectionScore({service, sectionName});

  return (
    <ResponsiveRow breakpoint={600} gap={3} sx={{alignItems: 'stretch'}}>
      {
        scoreName &&
        <ScoreContentContainer>
          <ScoreBox scoreName={scoreName} scoreValue={scoreValue} size={GradeSize.Large} inverse={false} expandBorder={true} />
          <Typography
            variant='body1'
            color='common.black'>
            <Trans
              t={t}
              i18nKey={t('googleHeader.text', {context: scoreName.toLowerCase(), ns: 'common'})}
            />
          </Typography>
        </ScoreContentContainer>
      }
    </ResponsiveRow>
  );
};

