import {Theme} from '@mui/material';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import styled from 'styled-components';

export const StyledPageContent = styled(FlexBoxColumn)`
  ${props => props.theme.breakpoints.up('xl')} {
    width: 1200px;
  }
  ${props => (props.theme as Theme).breakpoints.between('md', 'xl')} {
    width: calc(100vw - ${({theme}) => theme.spacing(10)});
  }
  ${props => props.theme.breakpoints.down('md')} {
    width: calc(100vw - ${({theme}) => theme.spacing(4)});
  }
`;

export const AdditionalFooterContainer = styled(FlexBoxColumn)`
  width: 100vw;
  height: 100%;
`;
