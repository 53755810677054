import {Tooltip} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ServiceType} from 'src/enums/ServiceType';

type SimpleTooltipProps = {
  children: JSX.Element;
}

export const SimpleTooltip = ({children}: SimpleTooltipProps): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);
  return <Tooltip
    enterTouchDelay={0}
    color={'common.black'}
    title={t('grade.keyScore.scoreDescriptionTooltip')}
    arrow
  >{children}</Tooltip>;
};
