import { TableRow, TableCell } from '@mui/material';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { IAPStats } from 'src/types/facebook/FacebookGrade';
import { GradeUtils } from 'src/utils/GradeUtils';
import { capitalizeFirstLetter } from 'src/utils/helpers';
import { withSymbol } from '../utils';

interface Props extends IAPStats {
  color: string;
  currencySymbol: string;
}

export const APTableRow = ({
  gender,
  ageGroup,
  ctr,
  impressions,
  clicks,
  spend,
  results,
  color,
  currencySymbol,
}: Props): JSX.Element => {
  return (
    <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
      <TableCell>
        {capitalizeFirstLetter(gender)} {GradeUtils.clearAgeGroup(ageGroup)}
      </TableCell>
      <TableCell sx={{ backgroundColor: color }} align="right">
        {ctr}%
      </TableCell>
      <TableCell align="right">{impressions?.toLocaleString()}</TableCell>
      <TableCell align="right">{clicks?.toLocaleString()}</TableCell>
      <TableCell align="right">{withSymbol(spend, currencySymbol)}</TableCell>
      <TableCell align="right">{results?.toLocaleString()}</TableCell>
    </TableRow>
  );
};
