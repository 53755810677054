import { Button } from '@mui/material';
import { useSignIn } from 'src/hooks/common/useSignIn';
import { useTranslation } from 'react-i18next';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionTheme } from '../theme/orion/Theme';
import GoogleGIcon from 'src/assets/platformIcons/GoogleGIcon.svg';

export const GoogleIconSignIn = (): JSX.Element => {
  const { signIn } = useSignIn({ signTo: 'google' });
  const { t } = useTranslation([ServiceType.GOOGLE]);

  return (
    <Button
      variant="outlined"
      color={'primary'}
      size={'medium'}
      data-testid="googleButton"
      aria-label="Sign in with Google"
      onClick={signIn}
      startIcon={<img src={GoogleGIcon} />}
      sx={{
        borderRadius: OrionTheme.spacing(5),
        padding: `${OrionTheme.spacing(1.375)} ${OrionTheme.spacing(3)}`,
        width: OrionTheme.spacing(26.5),
      }}
    >
      {t('start.signin')}
    </Button>
  );
};
