import {TableCell, TableRow, Typography} from '@mui/material';
import {ScoreName} from 'src/enums/ScoreName';
import {GradeUtils} from 'src/utils/GradeUtils';
import {useTranslation} from 'react-i18next';
import {GoogleAdType} from 'src/enums/GoogleAdType';

type Props = {
  text: string;
  you: number | string | undefined;
  competitors: number | string | undefined;
  clicks: number | string | undefined;
  scoreName: ScoreName;
  withIcon?: boolean;
}

export const MobileTableSection = ({text, you, competitors, clicks, scoreName}: Props): JSX.Element => {
  const {t} = useTranslation([GoogleAdType.DISPLAY]);
  const bgColor = GradeUtils.getScoreColorLight(scoreName as ScoreName);

  return (
    <>
      <TableRow>
        <TableCell colSpan={2} padding='none' sx={{p: 1}}>{text}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell size='small' sx={{fontWeight: 600}}>
          {t('grade.you')}
        </TableCell>
        <TableCell size='small' sx={{backgroundColor: bgColor}} align='right'>
          <Typography variant='subtitle1'>{you ?? '-'}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell size='small' sx={{fontWeight: 600}}>
          {t('grade.competitorAvg')}
        </TableCell>
        <TableCell padding='none' sx={{p: 1}} align='right'>
          {competitors ?? '-'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell size='small' sx={{fontWeight: 600}}>
          {t('grade.clicks')}
        </TableCell>
        <TableCell padding='none' sx={{p: 1}} align='right'>
          {clicks ?? '-'}
        </TableCell>
      </TableRow>
    </>
  );
};
