import {Button} from '@mui/material';
import {FieldValues, SubmitHandler, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import ForwardArrow from 'src/assets/ForwardArrow.svg';

export const FormStepsNextButton: React.FC<{disabled?: boolean; onSubmit: SubmitHandler<FieldValues>; id: string}> = ({disabled = false, onSubmit, id}) => {
  const {t} = useTranslation(['common']);
  const {handleSubmit} = useFormContext();

  return (
    <Button
      data-testid="nextButton"
      variant="contained"
      color="secondary"
      size='medium'
      onClick={handleSubmit(onSubmit)}
      endIcon={<img src={ForwardArrow} />}
      disabled={disabled}
      id={id}
      aria-label={t('next')}
    >
      {t('next')}
    </Button>
  );
};
