import { Typography } from '@mui/material';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { GradeUtils } from 'src/utils/GradeUtils';
import { ScoreSectionType } from 'src/enums/google/ScoreSection';
import { useContext } from 'react';
import { ServiceContext } from 'src/components/providers/service';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { FlexBoxRow } from '../../FlexBoxRow';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { ScoreName } from 'src/enums/ScoreName';
import { useGradeSectionScore } from 'src/hooks/common/useGradeSectionScore';
import { styled } from '@mui/system';

type Props = {
  sectionName: ScoreSectionType;
};

const Container = styled(FlexBoxColumn)(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(7)}`,
  border: `${theme.spacing(0.125)} solid ${OrionColors.gray.dark}`,
  borderRadius: theme.spacing(1),
  gap: theme.spacing(2),
}));

const ScoreNameContainer = styled(FlexBoxRow)<{ scoreName: ScoreName }>(({ theme, scoreName }) => ({
  width: theme.spacing(15),
  border: `${theme.spacing(0.25)} solid ${GradeUtils.getScoreColor(scoreName, OrionTheme)}`,
  borderRadius: theme.spacing(0.5),
  background: 'white',
  boxSizing: 'border-box',
}));

export const Score = ({ sectionName }: Props): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const { scoreName, scoreValue } = useGradeSectionScore({ service, sectionName });

  return (
    <Container>
      {scoreName ? (
        <>
          <FlexBoxColumn
            sx={{
              width: OrionTheme.spacing(12),
              height: OrionTheme.spacing(12),
              borderRadius: '50%',
              backgroundColor: GradeUtils.getScoreColor(scoreName, OrionTheme),
            }}
          >
            <FlexBoxColumn>
              <img
                src={GradeUtils.getIcon(scoreName)}
                alt="Score Icon"
                width={OrionTheme.spacing(5.652)}
                height={OrionTheme.spacing(5.652)}
              />
              <ScoreNameContainer scoreName={scoreName}>
                <Typography variant="overline">{scoreName}</Typography>
              </ScoreNameContainer>
            </FlexBoxColumn>
          </FlexBoxColumn>
          <Typography variant="h3" className="display">
            {scoreValue}
          </Typography>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};
