import useSWR from 'swr';
import {ICategory} from '../../types/common/Category';
import {IErrorResponse} from 'src/types/common/Error';
import {Logger} from '../../utils/Logger';
import {useEffect} from 'react';

const URL = `${process.env.REACT_APP_BACKEND_URL}/categories`;

export const useCategories = ():{categories?: ICategory[]; categoriesError: IErrorResponse} => {
  const {data, error} = useSWR<{categories: ICategory[]}>(URL);

  useEffect(() => {
    if (data || error) {
      Logger.trace('categories:useSWR', {data, error}, useCategories.name);
    }
  }, [data, error]);
 
  return {
    categories: data && data.categories,
    categoriesError: error
  };
};
