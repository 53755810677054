import {SelectChangeEvent} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {ICategory} from 'src/types/common/Category';
import {ISubCategory} from 'src/types/common/SubCategory';
import {Logger} from 'src/utils/Logger';
import {useSubCategories} from 'src/hooks/common/useSubCategories';
import {useCategories} from 'src/hooks/common/useCategories';
import {IGGContext} from 'src/types/google/GGContext';
import {IFacebookContext} from 'src/types/facebook/FacebookContext';

type ReturnType = {
  onCategoryChange: (event: SelectChangeEvent<string>) => void;
  categories: ICategory[];
  subCategories: ISubCategory[];
}

export const useManageCategories = (context: IGGContext | IFacebookContext): ReturnType => {
  const [categories, setCategories] = useState([] as ICategory[]);
  const [subCategories, setSubCategories] = useState([] as ISubCategory[]);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const categoriesResponse = useCategories();
  const subCategoriesResponse = useSubCategories(selectedCategory);

  const onCategoryChange = useCallback((event: SelectChangeEvent<string>) => setSelectedCategory(+event.target.value), []);

  useEffect(() => {
    if (context.businessDetails.categoryId) {
      setSelectedCategory(context.businessDetails.categoryId);
    }
  }, []);

  useEffect(() => {
    Logger.trace('useEffect:categories', {categoriesResponse, subCategoriesResponse}, 'FormStepTwo');

    if (categoriesResponse.categories) {
      setCategories(categoriesResponse.categories);
    }

    if (subCategoriesResponse.data?.subcategories) {
      setSubCategories(subCategoriesResponse.data.subcategories);
    }
  }, [categoriesResponse, subCategoriesResponse]);

  return {onCategoryChange, categories, subCategories};
};
