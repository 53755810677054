import {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {GoogleAdTypeContext} from 'src/components/providers/adType';
import {useGGContext} from 'src/components/providers/googleAds';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {IErrorResponse} from 'src/types/common/Error';

interface ResponseType extends IErrorResponse {
  googleGradeUuid: string;
}

export const EXPIRED_TOKEN_ERROR = 'ExpiredTokenException';

export const useGGradeRefresh = (): void => {
  const {setIsNewReport} = useGGContext();
  const {UUID} = useParams();
  const [searchParams] = useSearchParams();
  const salesOnly = searchParams.get('salesOnly');
  const navigate = useNavigate();
  const [response, setResponse] = useState<ResponseType>();
  const {setGoogleAdType} = useContext(GoogleAdTypeContext);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/google/refresh`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        googleGradeUuid: UUID,
        salesOnly: salesOnly !== null ? salesOnly : true
      })
    })
      .then(async (response) => {
        setResponse(await response.json());
      });
  }, []);

  useEffect(() => {
    if (response && response.googleGradeUuid) {
      setGoogleAdType(GoogleAdType.OVERALL);
      setIsNewReport(true);
      navigate(`/google-grader/report/${response.googleGradeUuid}`);
    }

    if (response && response.error) {
      const queryString = response.error === EXPIRED_TOKEN_ERROR ? '?event=expiredToken' : '';
      navigate(`/google-grader/error${queryString}`);
    }
  }, [response]);
};
