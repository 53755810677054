import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { useTranslation } from 'react-i18next';
import { ServiceType } from 'src/enums/ServiceType';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { Typography, useMediaQuery } from '@mui/material';
import { AutomaticPlacement } from './AutomaticPlacement';
import { DataType, PlatformOverviewTable } from './Table';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { PlatformOverviewTableMobile } from './TableMobile';
import { DoughnutChart } from 'src/components/common/grade/charts/DoughnutChart';
import {
  DoughnutLabelOptions,
  DoughnutStyling,
  getLegendIcon,
  getPlacementsByDevice,
  getPlacementsByPlatform,
  LegendColors,
} from './utils';
import { commonChartOptions } from 'src/components/common/grade/charts/commonOptions';
import { getPlatformPlacementData } from 'src/utils/datasetHelper';
import { PlacementByDeviceLegends } from './Legends';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { useFAGradeScore } from 'src/hooks/facebook/useFAGradeScore';

export const PlatformOverview = (): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const { grade, currencySymbol } = useFAGradeScore({ sectionName: undefined });
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));

  const legends = ['phone', 'desktop', 'tablet', 'other'].map((name) => ({
    name,
    icon: getLegendIcon(name),
  }));

  return (
    <FlexBoxColumn gap={3} sx={{ paddingTop: 3, width: '100%' }}>
      <ResponsiveRow breakpoint={600} sx={{ width: '100%', justifyContent: 'space-between' }}>
        <BoldTypography variant="h3">{t('metrics.platformOverview.platformPlacement.metrics')}</BoldTypography>
        <Typography variant="h6" color="text.disabled" fontStyle="italic">
          {t('last30', { ns: 'common' })}
        </Typography>
      </ResponsiveRow>
      <ResponsiveRow sx={{ justifyContent: 'left' }} width={'100%'} breakpoint={600}>
        <AutomaticPlacement />
      </ResponsiveRow>
      <FlexBoxColumn sx={{ width: '100%' }}>
        <FlexBoxRow sx={{ width: '100%', justifyContent: isMobile ? 'center' : 'flex-start', pt: 2 }}>
          <BoldTypography variant="h5">{t('metrics.platformOverview.platformPlacement.heading')}</BoldTypography>
        </FlexBoxRow>
        <ResponsiveRow sx={{ width: '100%' }} breakpoint={600}>
          <FlexBoxRow>
            {grade && (
              <DoughnutChart
                dataset={getPlatformPlacementData(grade).dataset}
                doughnutStyling={DoughnutStyling}
                labels={DoughnutLabelOptions}
                options={commonChartOptions}
              />
            )}
          </FlexBoxRow>
          {grade && (
            <PlacementByDeviceLegends
              data={legends.map((item, index) => ({
                ...item,
                percent: getPlatformPlacementData(grade).dataset[index],
                color: LegendColors[index],
              }))}
            />
          )}
        </ResponsiveRow>
      </FlexBoxColumn>
      {grade && isMobile ? (
        <>
          <PlatformOverviewTableMobile
            type={DataType.BY_DEVICE}
            data={getPlacementsByDevice(grade)}
            currencySymbol={currencySymbol}
          />
          <PlatformOverviewTableMobile
            type={DataType.BY_PLATFORM}
            data={getPlacementsByPlatform(grade)}
            currencySymbol={currencySymbol}
          />
        </>
      ) : (
        grade && (
          <>
            <PlatformOverviewTable
              type={DataType.BY_DEVICE}
              data={getPlacementsByDevice(grade)}
              currencySymbol={currencySymbol}
            />
            <PlatformOverviewTable
              type={DataType.BY_PLATFORM}
              data={getPlacementsByPlatform(grade)}
              currencySymbol={currencySymbol}
            />
          </>
        )
      )}
    </FlexBoxColumn>
  );
};
