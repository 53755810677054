import {useCallback, useContext, useEffect, useState} from 'react';
import {KeywordsContext} from 'src/components/providers/keywords';

type ReturnType = {
  open: boolean;
  onClose: () => void;
}

const useLoadingModal = (): ReturnType => {
  const [open, setOpen] = useState(false);
  const {status: {isLoading, isDone, error}} = useContext(KeywordsContext);

  const onClose = useCallback(() => setOpen(false), []);

  useEffect(() => {
    if (isLoading) {
      setOpen(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isDone || error) {
      setOpen(false);
    }
  }, [isDone, error]);

  return {open, onClose};

};

export default useLoadingModal;
