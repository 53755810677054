import { useSignIn } from 'src/hooks/common/useSignIn';
import { Button, buttonClasses } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import FacebookIconTransparent from 'src/assets/platformIcons/FacebookIconTransparent.svg';

export const FacebookSignIn = (): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const { signIn } = useSignIn({ signTo: 'facebook' });

  return (
    <Button
      variant="contained"
      size="medium"
      data-testid="facebookButton"
      aria-label="Login with Facebook"
      onClick={signIn}
      sx={{
        background: OrionColors.common.facebookBlue,
        [`.${buttonClasses.startIcon}`]: {
          width: 28,
          height: 28,
          lineHeight: '28px',
        },
      }}
      startIcon={<img src={FacebookIconTransparent} />}
    >
      {t('start.signin')}
    </Button>
  );
};
