import {useContext, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {ServiceContext} from 'src/components/providers/service';

type ReturnType = {
  errorTitle: string;
  errorBody: string;
  email: string | null;
  event: ErrorEvent | null;
}

export enum ErrorEvent {
  CANCELLED = 'cancelled',
  NO_ROUTE = 'noRoute',
  TIMED_OUT = 'timedOut',
  PERMISSION_DENIED = 'permissions-denied',
  EXPIRED_TOKEN = 'expiredToken',
  PROCESS = 'process'
}

export const useErrorContent = (): ReturnType => {
  const [service] = useContext(ServiceContext);
  const {t} = useTranslation([service]);
  const [searchParams] = useSearchParams();
  const event = useMemo(() => searchParams.get('event') as ErrorEvent, [searchParams]);
  const email = useMemo(() => searchParams.get('email'), [searchParams]);

  const errorTitle = useMemo(() => {
    switch (event) {
      case ErrorEvent.CANCELLED:
        return t('error.title_cancelled');
      case ErrorEvent.NO_ROUTE:
        return t('error.title_noroute');
      case ErrorEvent.TIMED_OUT:
        return '';
      case ErrorEvent.PROCESS:
        return t('error.title_process');
      case ErrorEvent.EXPIRED_TOKEN:
        return t('error.title_expiredToken');
      case ErrorEvent.PERMISSION_DENIED:
      default:
        return t('error.title_noaccount');
    }
  }, [event]);

  const errorBody = useMemo(() => {
    switch (event) {
      case ErrorEvent.CANCELLED:
        return t('error.body_cancelled');
      case ErrorEvent.NO_ROUTE:
        return t('error.body_noroute');
      case ErrorEvent.TIMED_OUT:
        return t('error.body_timedout');
      case ErrorEvent.PROCESS:
        return t('error.body_process');
      case ErrorEvent.EXPIRED_TOKEN:
        return t('error.body_expiredToken');
      case ErrorEvent.PERMISSION_DENIED:
        return t('error.body_nopermissions');
      default:
        return t('error.body_noaccount');
    }
  }, [event]);

  return {
    event,
    errorTitle,
    errorBody,
    email
  };
};
