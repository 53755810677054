import React, {useContext, useEffect, useState} from 'react';
import {Transition} from 'react-transition-group';
import {FlexBoxColumn} from './FlexBoxColumn';
import {GoogleAdTypeContext} from 'src/components/providers/adType';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {ServiceContext} from 'src/components/providers/service';
import {ServiceType} from 'src/enums/ServiceType';

const DURATION = 500; // Animation Duration

const transitionStyles: any = { // eslint-disable-line
  entering: {opacity: 1},
  entered: {opacity: 1}
};

export const FadeInTransition: React.FC<{triggerAfter?: number; children: React.ReactNode}> = ({triggerAfter = 0, children}) => {
  const [value, setValue] = useState(false);
  const [service] = useContext(ServiceContext);
  const {googleAdType} = useContext(GoogleAdTypeContext);
  const defaultStyle = {
    transition: `opacity ${DURATION}ms ease-in-out`,
    opacity: 0
  };
  const ref = React.useRef(null);
  const alignSelf = service !== ServiceType.GOOGLE || googleAdType === GoogleAdType.OVERALL ? 'stretch' : 'center';

  useEffect(() => {
    const timer = setTimeout(() => setValue(!value), triggerAfter);
    return () => clearInterval(timer);
  }, []);

  return <Transition {...{in: value}} timeout={DURATION} nodeRef={ref}>
    {
      state => <FlexBoxColumn ref={ref} sx={{alignSelf: alignSelf, alignItems: 'stretch'}}
        style={{...defaultStyle, ...transitionStyles[state]}}>{children}</FlexBoxColumn>
    }
  </Transition>;
};
