import useSWR from 'swr';
import {ILocation} from 'src/types/common/Location';
import {IErrorResponse} from 'src/types/common/Error';
import {Logger} from '../../utils/Logger';
import {useEffect, useMemo} from 'react';

export const useGeoLocations = (target: string): {locations?: ILocation[]; locationsError: IErrorResponse} => {
  const URL = useMemo(() => `${process.env.REACT_APP_BACKEND_URL}/google/geotarget/${encodeURIComponent(target)}`, [target]);
  const {data, error} = useSWR<{geotargets: ILocation[]}>(URL);

  useEffect(() => {
    if (data || error) {
      Logger.trace('locations:swr', {data, error}, useGeoLocations.name);
    }
  }, [data, error]);

  return {
    locations: data && data.geotargets,
    locationsError: error
  };
};
