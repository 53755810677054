import {MenuItem} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useMetricSelect} from 'src/components/common/metrics/useMetricSelect';
import StyledSelect from 'src/components/common/styled/StyledSelect';
import {ServiceType} from 'src/enums/ServiceType';

export const MetricSelect = (): JSX.Element => {
  const {value, onSelect} = useMetricSelect();
  const {t} = useTranslation([ServiceType.GOOGLE]);

  return (
    <StyledSelect data-testid="metricSelect" value={value} onChange={onSelect} sx={{width: '296px'}}>
      <MenuItem value="overallScore">{t('tracker.overall_score')}</MenuItem>
      <MenuItem value="spend">{t('tracker.spend')}</MenuItem>
    </StyledSelect>
  );
};
