import {GradeUtils} from 'src/utils/GradeUtils';
import {FacebookAdsMetricsTabs, GoogleAdsMetricsTabs} from 'src/enums/MetricTabType';
import {Tab} from '../styled/Tab';
import {MetricsTabKey} from 'src/enums/google/MetricsTabKey';
import {Typography} from '@mui/material';
import {FontWeight} from 'src/components/theme/orion/overrides/FontWeight';
import {DisplayMetricsTabName, OverallMetricsTabName} from 'src/enums/display/MetricsTabName';
import {EventHandler, SyntheticEvent} from 'react';
import {ShoppingMetricsTabName} from '../../../enums/shopping/MetricsTabName';

interface Props {
  name: GoogleAdsMetricsTabs | FacebookAdsMetricsTabs;
  value: MetricsTabKey | DisplayMetricsTabName | FacebookAdsMetricsTabs | OverallMetricsTabName | ShoppingMetricsTabName;
  disabled?: boolean;
  splitLabelText?: boolean;
  onChange?: EventHandler<SyntheticEvent>;
}

const Label = (name: GoogleAdsMetricsTabs | FacebookAdsMetricsTabs, splitText?: boolean) => {
  return <Typography variant={'h4'}
    sx={{
      fontWeight: FontWeight.SemiBold,
      textTransform: name === GoogleAdsMetricsTabs.SIZE_OF_ACCOUNT ? 'initial' : 'capitalize',
      color: 'text.primary', whiteSpace: splitText ? 'pre' : 'initial',
      textAlign: 'center'
    }}
  >
    {GradeUtils.formatSectionName(name)}
  </Typography>;
};

export const MetricsTab = ({name, value, disabled, splitLabelText, onChange}: Props): JSX.Element =>
  <Tab label={Label(name, splitLabelText)} value={value} key={value} disabled={disabled} onChange={onChange} sx={{flexBasis: 0, flexGrow: 1}} />;
