import useSWR, { SWRConfiguration } from 'swr';
import { IErrorResponse } from 'src/types/common/Error';
import { IFacebookGrade } from 'src/types/facebook/FacebookGrade';
import { IFacebookGradeProcessWithStatus } from 'src/types/facebook/FacebookProcess';
import { GradeProcessorStatus } from '../overall/useProcessGrade';
import { useNavigate } from 'react-router-dom';
import { clearTimeout, setTimeout } from 'timers';

type ReturnType = {
  grade: IFacebookGrade | undefined;
  error: IErrorResponse | undefined;
  loading: boolean;
};

const DELAY = 1000;
let timeoutInterval: NodeJS.Timeout | undefined;

export const useFacebookGrade = (uuid: string | undefined): ReturnType => {
  const STATUS_URL = `${process.env.REACT_APP_BACKEND_URL}/facebook/process`;
  const URL = `${process.env.REACT_APP_BACKEND_URL}/facebook/grade`;
  const navigate = useNavigate();

  const {
    data: processorData,
    error: processorError,
    mutate,
  } = useSWR<IFacebookGradeProcessWithStatus>(uuid ? `${STATUS_URL}/${uuid}` : null, {
    refreshInterval: 0,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    onSuccess: (data: IFacebookGradeProcessWithStatus) => {
      if (data.status === GradeProcessorStatus.ERROR) {
        if (timeoutInterval) clearTimeout(timeoutInterval as NodeJS.Timeout);
        navigate('/facebook-grader/error?event=process');
        return;
      }
      if (data.status === GradeProcessorStatus.PENDING) {
        timeoutInterval = setTimeout(mutate, DELAY);
      }

      if (data.status === GradeProcessorStatus.COMPLETE) {
        if (timeoutInterval) clearTimeout(timeoutInterval as NodeJS.Timeout);
      }
    },
  } as SWRConfiguration);

  const { data: gradeData, error: gradeError } = useSWR<IFacebookGrade>(() =>
    processorData?.status === GradeProcessorStatus.COMPLETE ? `${URL}/${processorData.gradeUuid}` : null,
  );

  return {
    loading: !gradeData && !gradeError,
    grade: gradeData,
    error: processorError || gradeError,
  };
};
