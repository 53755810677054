import { useContext } from 'react';
import { KeywordsContext } from 'src/components/providers/keywords';
import { Accordion, AccordionDetails, AccordionSummary, Divider, TableCell } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { Trans, useTranslation } from 'react-i18next';
import { GradeUtils } from 'src/utils/GradeUtils';
import { capitalizeFirstLetter } from 'src/utils/helpers';
import { ExpandedRow } from './ExpandedRow';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import styled from 'styled-components';

const StyledDivider = styled(Divider)(() => ({
  width: '100%',
  borderBottom: `${OrionTheme.spacing(0.125)} solid ${OrionColors.gray.neutral}`,
  margin: `${OrionTheme.spacing(1)} 0`,
}));

export const KeywordAccordion = (): JSX.Element => {
  const { t } = useTranslation(['keywords']);
  const { keywords } = useContext(KeywordsContext);

  return (
    <FlexBoxColumn width={'100%'} gap={1} sx={{ alignItems: 'flex-start' }}>
      {keywords?.keywords.map((keyword, index) => {
        return (
          <Accordion key={index} sx={{ width: '100%' }} disableGutters={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`accordion-${index}`}>
              <table>
                <tbody>
                  <tr>
                    <TableCell sx={{ p: 0 }}>{keyword.keywordText}</TableCell>
                  </tr>
                </tbody>
              </table>
            </AccordionSummary>
            <AccordionDetails id={`accordion-details-${index}`} sx={{ pl: 2 }}>
              <FlexBoxColumn sx={{ width: '100%', alignItems: 'stretch' }}>
                <ExpandedRow label={t('accordion.header-keyword')} value={keyword.keywordText} />
                <StyledDivider />
                <ExpandedRow
                  label={t('table.header.volume')}
                  value={keyword.searchVolume.toLocaleString(process.env.REACT_APP_LOCALE)}
                />
                <StyledDivider />
                <ExpandedRow
                  label={
                    <>
                      <Trans t={t} i18nKey={'table.header.topPageBid'} />
                      <br />
                      <Trans t={t} i18nKey={'table.header.bidLow'} />
                    </>
                  }
                  labelVariant="body"
                  value={
                    keyword.lowTopPageBid > 0
                      ? `$${GradeUtils.roundoff(keyword.lowTopPageBid, 2).toLocaleString(process.env.REACT_APP_LOCALE, { minimumFractionDigits: 2 })}`
                      : '-'
                  }
                />
                <StyledDivider />
                <ExpandedRow
                  label={
                    <>
                      <Trans t={t} i18nKey={'table.header.topPageBid'} />
                      <br />
                      <Trans t={t} i18nKey={'table.header.bidHigh'} />
                    </>
                  }
                  labelVariant="body"
                  value={
                    keyword.highTopPageBid > 0
                      ? `$${GradeUtils.roundoff(keyword.highTopPageBid, 2).toLocaleString(process.env.REACT_APP_LOCALE, { minimumFractionDigits: 2 })}`
                      : '-'
                  }
                />
                <StyledDivider />
                <ExpandedRow label={t('table.header.competition')} value={capitalizeFirstLetter(keyword.competition)} />
              </FlexBoxColumn>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </FlexBoxColumn>
  );
};
