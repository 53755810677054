import {LogLevel} from 'src/enums/LogLevel';

type Data = {[key: string]: any}; // eslint-disable-line

export class Logger {
  static level = +(process.env.REACT_APP_LOGGER_LEVEL ?? LogLevel.OFF);

  public static info (message: string, data: Data, source: string): void {
    if (this.level >= LogLevel.INFO) {
      console.info(`Info: ${message}`, JSON.parse(JSON.stringify(data)), `[${source}]`);
    }
  }

  public static success (message: string, data: Data, source: string): void {
    if (this.level >= LogLevel.SUCCESS) {
      console.log(`Success: ${message}`, JSON.parse(JSON.stringify(data)), `[${source}]`);
    }
  }

  public static error (message: string, data: Data, source: string): void {
    if (this.level >= LogLevel.ERROR) {
      console.error(`Error: ${message}`, JSON.parse(JSON.stringify(data)), `[${source}]`);
    }
  }

  public static debug (message: string, data: Data, source: string): void {
    if (this.level >= LogLevel.DEBUG) {
      console.debug(`Debug: ${message}`, JSON.parse(JSON.stringify(data)), `[${source}]`);
    }
  }

  public static trace (message: string, data: Data, source: string): void {
    if (this.level >= LogLevel.TRACE) {
      console.info(`Trace: ${message}`, JSON.parse(JSON.stringify(data)), `[${source}]`);
    }
  }
}
