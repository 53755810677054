import useSWR from 'swr';
import {IGoogleGrade} from 'src/types/google/GoogleGrade';
import {IErrorResponse} from 'src/types/common/Error';

type ReturnType = {
  grade: IGoogleGrade | undefined;
  error: IErrorResponse | undefined;
}

export const useGoogleGrade = (uuid: string | undefined): ReturnType => {
  const URL = `${process.env.REACT_APP_BACKEND_URL}/google/grade`;

  const {data: gradeData, error: gradeError} = useSWR<IGoogleGrade>(
    uuid ? `${URL}/${uuid}` : null
  );

  return {
    grade: gradeData,
    error: gradeError
  };
};
