import {Typography, Box} from '@mui/material';
import {FlexBoxColumn} from '../FlexBoxColumn';
import {useTranslation} from 'react-i18next';
import {Step as GoogleStep} from 'src/enums/Step';
import {Step as FacebookStep} from 'src/enums/facebook/Step';

import stepperOne from 'src/assets/stepper-1.svg';
import stepperTwo from 'src/assets/stepper-2.svg';

const getStepperIcon = (step: GoogleStep | FacebookStep) => {
  switch (step) {
    case GoogleStep.FILL_YOUR_INFO:
      return stepperOne;
    case GoogleStep.FILL_BUSINESS_INFO:
      return stepperTwo;
  }
};

export const FormHeader: React.FC<{step: GoogleStep | FacebookStep}> = ({step}) => {
  const {t} = useTranslation(['common']);

  return (
    <FlexBoxColumn sx={{maxWidth: 332, gap: 5}}>
      <FlexBoxColumn sx={{gap: 2}}>
        <Typography variant='h3'>{t('formSteps.title')}</Typography>
        <Typography variant='body1' sx={{textAlign: 'center'}}>{t('formSteps.body')}</Typography>
      </FlexBoxColumn>
      <Box><img src={getStepperIcon(step)} /></Box>
    </FlexBoxColumn>
  );
};
