import {createContext, Dispatch, SetStateAction, useState} from 'react';
import {IMetric} from './metricsData';

export const SelectedMetricContext = createContext<[keyof IMetric, Dispatch<SetStateAction<keyof IMetric>>]>(['overallScore', () => null]);

type Props = {
  children: JSX.Element | JSX.Element[];
}

export const SelectedMetricProvider = ({children}: Props): JSX.Element => {
  const state = useState<keyof IMetric>('overallScore');
  
  return <SelectedMetricContext.Provider value={state} >{children}</SelectedMetricContext.Provider>;
};
