import {useState} from 'react';
import {GoogleDisplayScoreSection} from 'src/enums/google/ScoreSection';
import {FadeInTransition} from '../common/FadeInTransition';
import {FlexBoxColumn} from '../common/FlexBoxColumn';
import {GradeHeader} from '../common/grade/GradeHeader';
import {KeyScore} from 'src/components/common/grade/keyScore/KeyScore';
import {ScoreSummary} from '../common/grade/summaries/ScoreSummary';
import {KeyMetrics} from '../common/grade/keyMetrics/KeyMetrics';
import {HowToImprove} from 'src/components/common/grade/howTo/HowToImprove';
import {AdFormats} from './grade/adFormats/AdFormats';
import {AdPerformance} from './grade/adPerformance/AdPerformance';
import {
  AudiencePerformance, AudiencePerformanceMobile
} from './grade/audiencePerformance/AudiencePerformance';
import {TabsWrapper} from '../common/styled/TabsWrapper';
import {Tabs} from '../common/styled/Tabs';
import {Tab} from '@mui/material';

interface Props {
  sectionName: GoogleDisplayScoreSection;
  isMobile: boolean;
}

const VariableSectionContent = ({sectionName, isMobile}: Props): JSX.Element => {
  switch (sectionName) {
    case GoogleDisplayScoreSection.STATUS:
      return <AdFormats
        key={`${sectionName}-ad-formats`}
      />;
    case GoogleDisplayScoreSection.PLACEMENT:
      return <AdPerformance
        key={`${sectionName}-ad-placement`}
        sectionName={sectionName}
      />;
    case GoogleDisplayScoreSection.AUDIENCE_PERFORMANCE:
      switch (isMobile) {
        case true:
          return <AudiencePerformanceMobile
            key={`${sectionName}-ad-performance-mobile`}
            sectionName={sectionName}
          />;
        case false:
          return <AudiencePerformance
            key={`${sectionName}-ad-performance`}
            sectionName={sectionName}
          />;
      }
  }
};

export const SectionContent = ({sectionName, isMobile}: Props): JSX.Element =>
  <FlexBoxColumn width={'100%'} gap={isMobile ? 3 : 5} key={`${sectionName}-section-content`}>
    <KeyScore key={`${sectionName}-about-score`} sectionName={sectionName} />
    <KeyMetrics key={`${sectionName}-key-metric`} sectionName={sectionName} />
    <VariableSectionContent sectionName={sectionName} isMobile={isMobile} key={`${sectionName}-variable-section`} />
    <ScoreSummary key={`${sectionName}-score-summary`} sectionName={sectionName} />
    <HowToImprove key={`${sectionName}-howToImprove`} sectionName={sectionName} />
  </FlexBoxColumn>;

export const GoogleDisplayTabs = (): JSX.Element => {
  const [selected, setSelected] = useState<GoogleDisplayScoreSection>(GoogleDisplayScoreSection.STATUS);
  return (
    <TabsWrapper>
      <Tabs
        value={selected}
        onChange={(event, newValue) => setSelected(newValue)}
      >
        {
          Object.values(GoogleDisplayScoreSection)
            .map((sectionName) =>
              <Tab icon={<GradeHeader sectionName={sectionName} />} value={sectionName} key={`${sectionName}-tab`} />
            )
        }
      </Tabs>
      <div role='tab-panel'>
        {
          Object.values(GoogleDisplayScoreSection)
            .map((sectionName) =>
              sectionName === selected && <FadeInTransition key={`${sectionName}-panel-transition`}>
                <SectionContent sectionName={sectionName} isMobile={false} key={`${sectionName}-panel`} />
              </FadeInTransition>
            )
        }
      </div>
    </TabsWrapper>
  );
};
