import { useParams } from 'react-router-dom';
import { useShoppingGrade } from 'src/hooks/shopping/useShoppingGrade';
import { useTranslation } from 'react-i18next';
import { ServiceType } from 'src/enums/ServiceType';
import { Typography, useMediaQuery } from '@mui/material';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { PerformanceMetrics } from 'src/components/common/metrics/PerformanceMetrics';
import { GoogleAdType } from 'src/enums/GoogleAdType';

export const ShoppingMetrics = (): JSX.Element => {
  const { UUID } = useParams();
  const { shoppingGrade } = useShoppingGrade(UUID as string);
  const { t } = useTranslation([ServiceType.GOOGLE]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));

  return (
    <FlexBoxColumn gap={isMobile ? 3 : 4.5} sx={{ width: '100%' }}>
      {shoppingGrade && (
        <PerformanceMetrics
          metrics={{
            ...shoppingGrade.shoppingPerformanceMetrics,
            currencySymbol: shoppingGrade.overallScore.currencySymbol ?? '$',
          }}
          adType={GoogleAdType.SHOPPING}
        />
      )}
      <Typography variant="caption" sx={{ color: 'text.disabled' }} textAlign={isMobile ? 'center' : 'left'}>
        {t('metrics.performanceFooter', { context: 'shopping' })}
      </Typography>
    </FlexBoxColumn>
  );
};
