import {useEffect, useMemo} from 'react';
import {BusinessType} from 'src/enums/BusinessType';

type Props = {
  email: string | undefined;
  businessType: string | undefined;
  cid: string | undefined;
  uuid: string | undefined;
}

export const useFreemiumAuth = ({email, businessType, cid, uuid}: Props): void => {
  const isClient = businessType === BusinessType.CLIENT;
  const reformedEmail = useMemo(() => email ? email.replaceAll(' ', '%2B') : email, [email]);
  const URL = `${process.env.REACT_APP_BACKEND_URL}/freemium/auth?email=${reformedEmail}&keywordUUID=${uuid}&sfdcCampaignID=${cid}&isClient=${isClient}`;

  useEffect(() => {
    if (reformedEmail) {
      window.location.href = URL;
    }
  }, [reformedEmail, URL]);
};
