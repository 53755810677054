import {Typography, useMediaQuery, useTheme} from '@mui/material';
import {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {KeywordsContext} from 'src/components/providers/keywords';
import {EmailKeywordsModal} from 'src/components/keywords/emailModal/EmailKeywordsModal';
import {AlertSnackbar} from 'src/components/common/AlertSnackbar';
import {IEmailKeywordResponse} from 'src/types/keywords/KeywordResponse';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import DownloadButton from './Download';
import {useNavigate} from 'react-router-dom';

const Header = (): JSX.Element => {
  const {t} = useTranslation(['keywords']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const {keywords, keywordFormState, geoFlow} = useContext(KeywordsContext);
  const [openKwdModal, setOpenKwdModal] = useState(false);
  const [modalResponse, setModalResponse] = useState<IEmailKeywordResponse | null>(null);
  const navigate = useNavigate();
  const handleOpen = () => setOpenKwdModal(true);
  const handleClose = () => setOpenKwdModal(false);

  const handleModalResponse = (emailResponse: IEmailKeywordResponse) => {
    setModalResponse(emailResponse);
  };

  const handleDownloadClick = () => {
    if (geoFlow === '0') {
      // Non-European traffic - open modal - go to freemium
      handleOpen();
    } else {
      // European traffic - go to download page to retrieve keywords directly
      navigate(`/fkt/Download/${keywords?.uuid}`);
    }
  };

  return (
    <>
      {
        keywords?.keywordCount
          ? <FlexBoxColumn sx={{alignItems: 'flex-start', gap: isMobile ? 2 : 5, mb: isMobile ? 3: 0}}>
            <Typography textAlign={'left'} fontWeight='medium' variant='h4' color='common.black'>
              {t('result.header', {searchTerm: keywordFormState.websiteURLOrKeyword})}
            </Typography>
            <DownloadButton onClick={handleDownloadClick} hasUuid={!!keywords.uuid} />
            <Typography variant="body2">
              {t('result.overline', {
                showed: keywords.keywords.length,
                total: keywords.keywordCount,
              })}
            </Typography>
          </FlexBoxColumn>
          : <></>
      }
      <EmailKeywordsModal open={openKwdModal} onClose={handleClose} onResponse={handleModalResponse} />
      {modalResponse && <AlertSnackbar message={modalResponse.emailResponse} onClose={() => setModalResponse(null)} />}
    </>
  );
};

export default Header;
