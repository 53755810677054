import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { useFacebookGrade } from 'src/hooks/facebook/useFacebookGrade';

export const AccountStructure = (): JSX.Element => {
  const { t } = useTranslation(['facebook']);
  const { UUID } = useParams();
  const { grade } = useFacebookGrade(UUID);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));

  return (
    <FlexBoxColumn sx={{ alignItems: isMobile ? 'center' : 'flex-start', gap: 4 }}>
      <Typography variant="body1" sx={{ width: '100%' }} textAlign={isMobile ? 'center' : 'left'}>
        {t('metrics.accountSummary.accountStructureText')}
      </Typography>
      <Grid container rowSpacing={1} columns={12} columnSpacing={1} alignItems={'stretch'}>
        <Grid item xs={6} sm={4}>
          <FlexBoxColumn
            sx={{ border: '1px solid #AEAEAD', height: '100%', justifyContent: 'space-evenly', alignItems: 'stretch' }}
          >
            <FlexBoxColumn sx={{ p: 2, gap: 1, height: '100%', justifyContent: 'space-between' }}>
              <Typography variant="overline" textAlign="center">
                {t('metrics.accountSummary.activeCampaigns')}
              </Typography>
              <BoldTypography variant="h3">{grade?.accountSummary.activeCampaigns.toLocaleString()}</BoldTypography>
            </FlexBoxColumn>
          </FlexBoxColumn>
        </Grid>
        <Grid item xs={6} sm={4}>
          <FlexBoxColumn
            sx={{ border: '1px solid #AEAEAD', height: '100%', justifyContent: 'space-evenly', alignItems: 'stretch' }}
          >
            <FlexBoxColumn sx={{ p: 2, gap: 1, height: '100%', justifyContent: 'space-between' }}>
              <Typography variant="overline" textAlign="center">
                {t('metrics.accountSummary.avgAdSetPerCampaign')}
              </Typography>
              <BoldTypography variant="h3">
                {grade?.accountSummary.averageAdSetsPerCampaign.toLocaleString()}
              </BoldTypography>
            </FlexBoxColumn>
          </FlexBoxColumn>
        </Grid>
        <Grid item xs={6} sm={4}>
          <FlexBoxColumn
            sx={{ border: '1px solid #AEAEAD', height: '100%', justifyContent: 'space-evenly', alignItems: 'stretch' }}
          >
            <FlexBoxColumn sx={{ p: 2, gap: 1, height: '100%', justifyContent: 'space-between' }}>
              <Typography variant="overline" textAlign="center">
                {t('metrics.accountSummary.activeAdSets')}
              </Typography>
              <BoldTypography variant="h3">{grade?.accountSummary.activeAdSets.toLocaleString()}</BoldTypography>
            </FlexBoxColumn>
          </FlexBoxColumn>
        </Grid>
        <Grid item xs={6} sm={4}>
          <FlexBoxColumn
            sx={{ border: '1px solid #AEAEAD', height: '100%', justifyContent: 'space-evenly', alignItems: 'stretch' }}
          >
            <FlexBoxColumn sx={{ p: 2, gap: 1, height: '100%', justifyContent: 'space-between' }}>
              <Typography variant="overline" textAlign="center">
                {t('metrics.accountSummary.avgAdsPerSet')}
              </Typography>
              <BoldTypography variant="h3">{grade?.accountSummary.averageAdsPerAdSet.toLocaleString()}</BoldTypography>
            </FlexBoxColumn>
          </FlexBoxColumn>
        </Grid>
        <Grid item xs={6} sm={4}>
          <FlexBoxColumn
            sx={{ border: '1px solid #AEAEAD', height: '100%', justifyContent: 'space-evenly', alignItems: 'stretch' }}
          >
            <FlexBoxColumn sx={{ p: 2, gap: 1, height: '100%', justifyContent: 'space-between' }}>
              <Typography variant="overline" textAlign="center">
                {t('metrics.accountSummary.activeAds')}
              </Typography>
              <BoldTypography variant="h3">{grade?.accountSummary.activeAds.toLocaleString()}</BoldTypography>
            </FlexBoxColumn>
          </FlexBoxColumn>
        </Grid>
        <Grid item xs={6} sm={4}>
          <FlexBoxColumn
            sx={{ border: '1px solid #AEAEAD', height: '100%', justifyContent: 'space-evenly', alignItems: 'stretch' }}
          >
            <FlexBoxColumn sx={{ p: 2, gap: 1, height: '100%', justifyContent: 'space-between' }}>
              <Typography variant="overline" textAlign="center">
                {t('metrics.accountSummary.avgAdFrequency')}
              </Typography>
              <BoldTypography variant="h3">{grade?.accountSummary.averageAdFrequency.toLocaleString()}</BoldTypography>
            </FlexBoxColumn>
          </FlexBoxColumn>
        </Grid>
      </Grid>
    </FlexBoxColumn>
  );
};
