import { styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { useTips } from './useTips';
import { ServiceType } from 'src/enums/ServiceType';

const StyledBox = styled(FlexBoxColumn)`
  height: 100%;
  padding: ${({ theme }) => theme.spacing(5)} ${({ theme }) => theme.spacing(12)} ${({ theme }) => theme.spacing(7.5)};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(3.5)};
  }
`;

const StyledTip = styled(Typography)({
  textAlign: 'center',
});

const StyledOverline = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(0.5),
}));

const TipsCarousel = (): JSX.Element => {
  const { t } = useTranslation([ServiceType.GOOGLE]);
  const { tip } = useTips();

  return (
    <StyledBox sx={{ background: tip.background }}>
      <StyledOverline color="common.white" fontWeight={600} sx={{ pb: 2 }} variant="overline">
        {t('tips.header')}
      </StyledOverline>
      <StyledTip color="common.white" variant="h3">
        {t(`tips.messages.${tip.message}`)}
      </StyledTip>
    </StyledBox>
  );
};

export default TipsCarousel;
