import { Typography } from '@mui/material';
import { FlexBoxColumn } from '../../common/FlexBoxColumn';
import { useTranslation } from 'react-i18next';
import { StyledLinearProgress } from 'src/components/common/progress/LinearProgress';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { OrionTheme } from 'src/components/theme/orion/Theme';

type Props = {
  ismobile: boolean;
  onComplete?: () => void;
};

const LoadingHeader = ({ onComplete, ismobile }: Props): JSX.Element => {
  const { t } = useTranslation(['keywords']);

  return (
    <FlexBoxColumn
      sx={{ background: OrionTheme.palette.common.white, pt: 2, pb: ismobile ? 2 : 6, gap: ismobile ? 0 : 3 }}
    >
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        {t('loading.keywords')}
      </Typography>
      <FlexBoxColumn sx={{ alignSelf: 'stretch', alignItems: 'stretch' }}>
        <FlexBoxRow sx={{ py: 3 }}>
          <StyledLinearProgress callback={onComplete} />
        </FlexBoxRow>
        <Typography variant="body1" sx={{ alignSelf: 'center' }}>
          {t('loading.footer')}
        </Typography>
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export default LoadingHeader;
