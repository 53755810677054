import { ResponsiveRow } from '../../common/ResponsiveRow';
import { BoldTypography } from '../../common/styled/BoldTypography';
import { Table, TableBody, TableCell, TableRow, Typography, useMediaQuery } from '@mui/material';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { FlexBoxColumn } from '../../common/FlexBoxColumn';
import { useTranslation } from 'react-i18next';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { ReportDateRange } from '../../common/metrics/useReportDateRange';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { useOverallSpend } from 'src/hooks/overall/useOverallSpend';
import { useParams } from 'react-router-dom';
import { SpendRow } from '../../common/metrics/PerformanceMetrics';
import { SpendDataKey } from 'src/enums/overall/SpendData';
import { useOverallGrade } from 'src/hooks/overall/useOverallGrade';
import { OtherSpendRow } from './OtherSpendRow';

type Props = {
  adType?: GoogleAdType;
};

export interface ISpendDataKey {
  key: SpendDataKey;
}

export interface ISpendDataGroup {
  data: ISpendDataKey[];
}

const spendDataGroups: ISpendDataGroup[] = [
  {
    data: [{ key: SpendDataKey.Discovery }, { key: SpendDataKey.Local }, { key: SpendDataKey.Hotel }],
  },
  {
    data: [
      { key: SpendDataKey.LocalServices },
      { key: SpendDataKey.MultiChannel },
      { key: SpendDataKey.PerformanceMax },
    ],
  },
  {
    data: [{ key: SpendDataKey.Smart }, { key: SpendDataKey.Unknown }, { key: SpendDataKey.Video }],
  },
  {
    data: [{ key: SpendDataKey.Unspecified }],
  },
];

export const SpendBreakdown = ({ adType = GoogleAdType.OVERALL }: Props): JSX.Element => {
  const { UUID } = useParams();
  const { t } = useTranslation([ServiceType.GOOGLE]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));
  const { dateRange } = ReportDateRange({ adType });
  const { overallGrade } = useOverallGrade(UUID);
  const { spendData } = useOverallSpend(UUID);

  return (
    <>
      {overallGrade && (
        <FlexBoxColumn gap={isMobile ? 3 : 5} alignSelf={'stretch'}>
          <FlexBoxColumn alignSelf={'stretch'}>
            {/* Header Row Starts */}
            <ResponsiveRow breakpoint={600} sx={{ alignSelf: 'stretch', justifyContent: 'space-between', mb: 8 }}>
              <BoldTypography variant="h4" color={'common.black'}>
                {t('metrics.overallSpend.title', { context: adType })}
              </BoldTypography>
              <Typography variant="body2" color={OrionColors.gray.mid} sx={{ fontStyle: 'italic' }}>
                {dateRange}
              </Typography>
            </ResponsiveRow>
            {/* End Header Row */}

            {/* Graded Spend Row */}
            {spendData && (
              <FlexBoxColumn alignSelf={'stretch'} sx={{ mb: 8 }}>
                <SpendRow
                  spendText={t('metrics.overallSpend.gradedSpend')}
                  spend={
                    spendData.gradedSpend?.toLocaleString(process.env.LOCALE, { minimumFractionDigits: 2 }) ?? '0.00'
                  }
                  currencySymbol={overallGrade.currencySymbol}
                  gap={0}
                >
                  <></>
                </SpendRow>
                <ResponsiveRow sx={{ alignSelf: 'stretch', alignItems: 'stretch', p: 2 }} gap={1.25} breakpoint={600}>
                  {['search', 'display', 'shopping'].map((section) => (
                    <Table key={section}>
                      <TableBody>
                        <TableRow>
                          <TableCell size={'small'}>
                            <FlexBoxColumn gap={0.5}>
                              <Typography variant="h3">
                                {overallGrade.currencySymbol}
                                {(spendData[section as 'search' | 'display' | 'shopping'] as number).toLocaleString(
                                  process.env.LOCALE,
                                  { minimumFractionDigits: 2 },
                                )}
                              </Typography>
                              <Typography variant="overline" textAlign="center">
                                {t(`metrics.overallSpend.${section}`)}
                              </Typography>
                            </FlexBoxColumn>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ))}
                </ResponsiveRow>
              </FlexBoxColumn>
            )}
            {/* End Graded Spend Row */}

            {/* Other Spend Rows */}
            {spendData && (
              <SpendRow
                spendText={t('metrics.overallSpend.otherSpend')}
                spend={spendData.otherSpend?.toLocaleString(process.env.LOCALE, { minimumFractionDigits: 2 }) ?? '0.00'}
                currencySymbol={overallGrade.currencySymbol}
              >
                <></>
              </SpendRow>
            )}
            {spendData && (
              <FlexBoxColumn sx={{ p: 3, alignSelf: 'stretch', gap: 1 }}>
                {spendDataGroups.map((group: ISpendDataGroup, index: number) => (
                  <OtherSpendRow
                    key={`other-spend-row-${index}`}
                    keys={group.data}
                    spendData={spendData}
                    currencySymbol={overallGrade.currencySymbol}
                  />
                ))}
              </FlexBoxColumn>
            )}
            {/* End Other Spend Rows */}
          </FlexBoxColumn>
        </FlexBoxColumn>
      )}
    </>
  );
};
