import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useGGContext} from 'src/components/providers/googleAds';
import {useGoogleCustomers} from 'src/hooks/google/useGoogleCustomers';
import {FieldValues, SubmitHandler} from 'react-hook-form';
import {Step} from 'src/enums/Step';
import {Logger} from 'src/utils/Logger';
import {IGoogleCustomer} from 'src/types/google/GoogleCustomer';
import {ISelectOption} from 'src/types/common/SelectOption';

type ReturnType = {
  accounts: ISelectOption[];
  backHandler: () => void;
  onSubmit: SubmitHandler<FieldValues>;
}

export const useResponseHandler = (): ReturnType => {
  const context = useGGContext();
  const [searchParams] = useSearchParams();
  const [accounts, setAccounts] = useState<ISelectOption[]>([]);
  const googleAccountsResponse = useGoogleCustomers(searchParams.get('auth'));
  const navigate = useNavigate();

  const backHandler = useCallback(() => navigate('/'), []);

  const onSubmit: SubmitHandler<FieldValues> = useCallback((value) => {
    Logger.trace('SubmitHandler', {value}, useResponseHandler.name);
    const selectedAccount = accounts.find((customerAccount) => customerAccount.id === value.customerAccount)!;
    context.setGoogleAdsAccount({accountId: selectedAccount.id, accountName: selectedAccount.name});
    context.setCurrentStep(Step.FILL_YOUR_INFO);
  }, [accounts]);

  const handleResponse = useCallback((customers: IGoogleCustomer[]): void => {
    const formattedAccounts: ISelectOption[] = customers.map(({customerId, name}) => ({
      id: customerId,
      name: name ? `${name} (${customerId})` : `${customerId}`
    }));

    setAccounts(formattedAccounts);
  }, []);

  useEffect(() => {
    Logger.trace('useEffect:GoogleAccounts', googleAccountsResponse, 'ChooseAccount');
    if (googleAccountsResponse.data?.customers) {
      handleResponse(googleAccountsResponse.data.customers);
    }
  }, [googleAccountsResponse.data?.customers]);

  return {accounts, backHandler, onSubmit};
};
