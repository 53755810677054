import useSWR from 'swr';
import {IFormData} from 'src/types/common/IFormData';
import {IErrorResponse} from 'src/types/common/Error';
import {Logger} from 'src/utils/Logger';
import {useEffect} from 'react';
import {IGGContext} from 'src/types/google/GGContext';
import {IFacebookContext} from 'src/types/facebook/FacebookContext';

const URL = `${process.env.REACT_APP_BACKEND_URL}/business-details?auth=`;

type ReturnType = {
  prefillData?: IFormData;
  formPrefillError: IErrorResponse;
}

export const useFormPrefill = (context: IGGContext | IFacebookContext): ReturnType => {
  const {data, error} = useSWR<IFormData>(context.auth ? URL + context.auth : null);

  useEffect(() => {
    if (data || error) {
      Logger.trace('formPrefill:useSWR', {data, error}, useFormPrefill.name);
    }
  }, [data, error]);

  return {
    prefillData: data,
    formPrefillError: error
  };
};
