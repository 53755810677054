import {Box, Theme, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FlexBoxRow} from 'src/components/common/FlexBoxRow';
import {TypedGrid} from 'src/components/common/Grid';
import {StyledPageContent} from 'src/components/common/styled/StyledPageElements';
import {GridType} from 'src/enums/GridType';
import {useLocation, useParams} from 'react-router-dom';
import {useGoogleGrade} from 'src/hooks/google/useGoogleGrade';
import {Header} from '../grade/header/Header';
import {useDisplayGrade} from 'src/hooks/display/useDisplayGrade';
import {useOverallGrade} from 'src/hooks/overall/useOverallGrade';
import {OverallHeader} from 'src/components/overall/header/Header';
import {useContext, useEffect} from 'react';
import {GoogleAdTypeContext} from 'src/components/providers/adType';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import styled from 'styled-components';
import {HeaderActions} from 'src/components/common/grade/header/headerActions';
import {ResponsiveRow} from '../../common/ResponsiveRow';
import {ServiceType} from 'src/enums/ServiceType';
import {Progress} from 'src/components/common/progress/Progress';
import {LoadingContext} from 'src/components/providers/loading';
import {FadeInTransition} from 'src/components/common/FadeInTransition';
import {useShoppingGrade} from 'src/hooks/shopping/useShoppingGrade';

export const NavBarHeight = '64px';
export const FooterDesktopHeight = '125px';
const FooterMobileHeight = '277px';
const PaddingDesktopHeight = '80px';
const PaddingMobileHeight = '128px';
const ErrorBannerHeightDesktop = '221px';
const ErrorBannerHeightMobile = '472px';

type IProps = {
  gridType: GridType;
  errord: boolean;
  isMobile: boolean;
  theme: Theme;
  isLoading: boolean;
}

export const FlexBoxRowWithBackground = styled(FlexBoxRow)(({gridType, errord, isMobile, theme, isLoading}: IProps) => ({
  width: '100vw',
  paddingTop: gridType !== GridType.TAB
    ? isMobile ? theme.spacing(8) : theme.spacing(5)
    : 0,
  paddingBottom: gridType !== GridType.TAB
    ? isMobile ? theme.spacing(8) : theme.spacing(5)
    : 0,
  minHeight: gridType !== GridType.TAB
    ? errord
      ? isMobile
        ? `calc(100vh - ${NavBarHeight} - ${FooterMobileHeight} - ${ErrorBannerHeightMobile} - ${PaddingMobileHeight})`
        : `calc(100vh - ${NavBarHeight} - ${FooterDesktopHeight} - ${ErrorBannerHeightDesktop} - ${PaddingDesktopHeight})`
      : isLoading
        ? `calc(100vh - ${NavBarHeight} - ${FooterDesktopHeight})`
        : isMobile
          ? `calc(100vh - ${NavBarHeight} - ${FooterMobileHeight} - ${PaddingMobileHeight})`
          : `calc(100vh - ${NavBarHeight} - ${FooterDesktopHeight} - ${PaddingDesktopHeight})`
    : isLoading
      ? isMobile
        ? '100%'
        : `calc(100vh - ${NavBarHeight} - ${FooterDesktopHeight})`
      : '100%',
  background: gridType === GridType.TAB
    ? `linear-gradient(
    294deg,
    #04009D 14.59%,
    ${theme.palette.primary.main} 77.31%,
    ${theme.palette.info.dark} 100%
  )` : `
  linear-gradient(
    142deg,
    ${theme.palette.info.dark} 0%,
    ${theme.palette.primary.main} 29.25%,
    #04009D 82.27%
  )`
}));

type PageContentProps = {
  gridType: GridType;
  children: JSX.Element | JSX.Element[];
};

export const PageContent = ({
  gridType,
  children,
}: PageContentProps): JSX.Element => {
  const {t} = useTranslation(['google', 'common']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {UUID} = useParams();
  const {googleAdType, isSearch, isDisplay, isShopping, isOverall} = useContext(GoogleAdTypeContext);
  const {isProgressBarComplete, isProgressBarRunning, setApiComplete, setProgressBarComplete} = useContext(LoadingContext);
  const {grade, error} = isSearch ? useGoogleGrade(UUID) : {grade: undefined, error: undefined};
  const {displayGrade, displayError} = isDisplay ? useDisplayGrade(UUID) : {displayGrade: undefined, displayError: undefined};
  const {shoppingGrade, shoppingError} = isShopping ? useShoppingGrade(UUID) : {shoppingGrade: undefined, shoppingError: undefined};
  const {overallGrade, overallError} = isOverall ? useOverallGrade(UUID) : {overallGrade: undefined, overallError: undefined};
  const location = useLocation();
  const errord = !!error || !!displayError || !!overallError || location.pathname.includes('/error');
  const isLoading =
    error === undefined &&
    displayError === undefined &&
    shoppingError === undefined &&
    overallError === undefined &&
    grade === undefined &&
    displayGrade === undefined &&
    shoppingGrade === undefined &&
    overallGrade === undefined;

  // Reset loading context states
  useEffect(() => {
    setProgressBarComplete(false);
    setApiComplete(false);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const timeout = setTimeout(() => setApiComplete(true), 500);
      return () => clearTimeout(timeout);
    }
  }, [isLoading]);

  return (
    <>
      <FlexBoxColumn gap={gridType === GridType.TAB ? isMobile ? 2 : 7 : 0}>
        <FlexBoxRowWithBackground gridType={gridType} errord={errord} isMobile={isMobile} isLoading={isProgressBarRunning}>
          <StyledPageContent
            sx={{
              // 64px - 64px -> paddingTop - paddingBottom
              height: !isProgressBarComplete && gridType === GridType.TAB
                ? isMobile
                  ? '100%'
                  : `calc(100vh - ${NavBarHeight} - 64px - 64px - ${FooterDesktopHeight})`
                : '100%',
              alignItems: gridType === GridType.TAB ? 'center' : 'stretch',
              alignSelf: gridType === GridType.TAB ? 'flex-start' : 'stretch',
              paddingTop: gridType === GridType.TAB ? isMobile ? 2 : !isProgressBarComplete ? 8 : 5 : 0,
              paddingBottom: gridType === GridType.TAB ? isMobile ? 5 : 8 : 0,
              gap: !isProgressBarComplete && gridType === GridType.TAB ? isMobile ? 3 : 0 : isMobile ? 5 : 6
            }}>
            {
              gridType === GridType.TAB
                ? <>
                  <ResponsiveRow
                    breakpoint={'sm'}
                    sx={{width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      py: isMobile ? 0 : 3,
                      gap: 3
                    }}
                  >
                    <FlexBoxColumn sx={{alignItems: 'flex-start', flex: '1 1 0'}} gap={1}>
                      <Typography variant="h3" color="common.white">
                        {t('title1')}
                      </Typography>
                      <Typography variant="h4" color="common.white" fontWeight={'600'}>
                        {t('title2')}
                      </Typography>
                    </FlexBoxColumn>
                    {
                      isProgressBarComplete && <Box sx={{alignSelf: isMobile ? 'stretch' : 'center'}}>
                        <HeaderActions googleAdType={googleAdType} />
                      </Box>
                    }
                  </ResponsiveRow>
                  <FlexBoxColumn sx={{alignSelf: 'stretch', flex: '1 0 0', pb: !isProgressBarComplete ? 5 : 0}}>
                    {
                      !isProgressBarComplete
                        ? <FadeInTransition><Progress serviceType={ServiceType.GOOGLE} /></FadeInTransition>
                        : isSearch || isDisplay || isShopping
                          ? <FadeInTransition><Header /></FadeInTransition>
                          : <FadeInTransition><OverallHeader /></FadeInTransition>
                    }
                  </FlexBoxColumn>
                </>
                : <TypedGrid type={gridType}>
                  <FlexBoxColumn gap={7}>
                    <Typography variant='h1' color="common.white" >{t('title1')}&nbsp;Grader</Typography>
                    {children}
                  </FlexBoxColumn>
                </TypedGrid>
            }
          </StyledPageContent>
        </FlexBoxRowWithBackground>
        {
          !isProgressBarComplete
            ? <></>
            : <StyledPageContent data-testid="pageContent" sx={{justifyContent: 'flex-start'}}>
              {gridType === GridType.TAB && <TypedGrid type={gridType}>{children}</TypedGrid>}
            </StyledPageContent>
        }
      </FlexBoxColumn>
    </>
  );
};
