import {GradeUtils} from 'src/utils/GradeUtils';

export const withSymbol = (
  prop: number | undefined,
  type?: string,
  displayZeroValueOfPropAs: '0' | '-' | 'Not Used' = '0'
): string => {
  if (prop === 0) return displayZeroValueOfPropAs;

  if (type === '%' && prop) return `${GradeUtils.roundoff(prop, 2).toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 2})}${type}`;

  if (prop) return `${type}${GradeUtils.roundoff(prop, 2).toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 2})}`;

  return toLocale(prop);
};

export const toLocale = (value?: number): string => value?.toLocaleString(process.env.REACT_APP_LOCALE, {maximumFractionDigits: 2}) ?? '-';

export enum AudienceTypeStat {
  CTR = 'CTR',
  CPC = 'CPC',
}
