import { Theme, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Legend,
  BarController,
  ArcElement,
  DoughnutController,
} from 'chart.js';
import { ShadowLineController } from '../../../googleAds/grade/charts/ShadowlineController';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import LeftArrowSvg from 'src/assets/chartIcons/LeftArrow.svg';
import RightArrowSvg from 'src/assets/chartIcons/RightArrow.svg';
import UpArrowSvg from 'src/assets/chartIcons/UpArrow.svg';
import DownArrowSvg from 'src/assets/chartIcons/DownArrow.svg';

// Arrow images for ScatterChart
const arrowImages = {
  left: new Image(),
  right: new Image(),
  up: new Image(),
  down: new Image(),
};
arrowImages.left.src = LeftArrowSvg;
arrowImages.right.src = RightArrowSvg;
arrowImages.up.src = UpArrowSvg;
arrowImages.down.src = DownArrowSvg;

// Plugin for ScatterGraph for Shopping Campaign Rankings
const CustomArrowsAndTextPlugin = {
  id: 'CustomArrowsAndText',
  beforeDraw: (chart: Chart) => {
    if (chart.options.plugins?.CustomArrowsAndText?.drawCustomArrowsAndText) {
      const ctx = chart.ctx;
      const xAxis = chart.scales.x;
      const yAxis = chart.scales.y;
      const chartArea = chart.chartArea;

      // Dynamically determine middle points
      const midX = xAxis.getPixelForValue(xAxis.ticks[Math.floor(xAxis.ticks.length / 2)].value);
      const midY = yAxis.getPixelForValue(yAxis.ticks[Math.floor(yAxis.ticks.length / 2)].value);

      // Shade lower-left quadrant to RedTint color
      ctx.fillStyle = '#F2D2D8';
      ctx.fillRect(chartArea.left, midY, midX - chartArea.left, chartArea.bottom - midY);

      // Shade upper-right quadrant to GreenTint color
      ctx.fillStyle = '#E5F2DC';
      ctx.fillRect(midX, chartArea.top, chartArea.right - midX, midY - chartArea.top);
    }
  },
  afterDraw: (chart: Chart) => {
    if (chart.options.plugins?.CustomArrowsAndText?.drawCustomArrowsAndText) {
      const ctx = chart.ctx;
      const xAxis = chart.scales.x;
      const yAxis = chart.scales.y;
      const chartArea = chart.chartArea;
      const isMobile = chart.width < 600;

      // Function to draw an arrow image from svg sources
      const drawArrow = (image: HTMLImageElement, x: number, y: number, width: number, height: number) => {
        if (image.complete && image.naturalHeight !== 0) {
          ctx.drawImage(image, x - width / 2, y - height / 2, width, height);
        }
      };

      // Set styles for the arrows and text
      ctx.fillStyle = 'black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = isMobile ? '12px Unify Sans' : '14px Unify Sans';

      const midX = xAxis.getPixelForValue(xAxis.ticks[Math.floor(xAxis.ticks.length / 2)].value);
      const midY = yAxis.getPixelForValue(yAxis.ticks[Math.floor(yAxis.ticks.length / 2)].value);

      ctx.beginPath();

      // Set line width for central lines
      ctx.lineWidth = 1;

      // Central X-axis line
      ctx.moveTo(midX, yAxis.top);
      ctx.lineTo(midX, yAxis.bottom);

      // Central Y-axis line
      ctx.moveTo(xAxis.left, midY);
      ctx.lineTo(xAxis.right, midY);

      // Set central lines to black
      ctx.strokeStyle = 'black';
      ctx.stroke();

      // Bottom Performers text in the lower left quadrant
      const bottomPerformersX = (chartArea.left + midX) / 2;
      const bottomPerformersY = midY + 20;
      ctx.fillText('Bottom Performers', bottomPerformersX, bottomPerformersY);

      // Top Performers text in the upper right quadrant
      const topPerformersX = (midX + chartArea.right) / 2;
      const topPerformersY = midY - 20;
      ctx.fillText('Top Performers', topPerformersX, topPerformersY);

      // X-axis arrows & text
      const leftArrowX = xAxis.left;
      const rightArrowX = xAxis.right;
      const arrowY = isMobile ? yAxis.bottom + 12 : yAxis.bottom + 24;

      ctx.fillText('Low', leftArrowX + 40, arrowY);
      ctx.fillText('High', rightArrowX - 40, arrowY);
      drawArrow(arrowImages.left, xAxis.left + 8, arrowY, 24, 24);
      drawArrow(arrowImages.right, xAxis.right - 8, arrowY, 24, 24);

      // Y-axis arrows & text
      const topArrowY = yAxis.top;
      const bottomArrowY = yAxis.bottom;
      const arrowX = isMobile ? yAxis.left + (16 + 4 + 12 * 2) : yAxis.left + (24 + 16 + 14 * 2);

      ctx.fillText('High', arrowX, topArrowY + 30);
      ctx.fillText('Low', arrowX, bottomArrowY - 30);
      drawArrow(arrowImages.up, arrowX, yAxis.top + 8, 24, 24);
      drawArrow(arrowImages.down, arrowX, yAxis.bottom - 8, 24, 24);
    }
  },
};

// Settings applied to all Charts
const setChartDefaults = (theme: Theme, isMobile: boolean) => {
  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
    LineElement,
    BarController,
    BarElement,
    DoughnutController,
    ShadowLineController,
    ArcElement,
    CustomArrowsAndTextPlugin,
  );

  if (isMobile) {
    Chart.defaults.font.size = 8;
    Chart.defaults.font.lineHeight = theme.spacing(1.25);
  } else {
    Chart.defaults.font.size = theme.typography.fontSize;
    Chart.defaults.font.lineHeight = theme.spacing(2);
  }

  Chart.defaults.font.family = theme.typography.fontFamily;
  Chart.defaults.layout = { padding: Number(theme.spacing(3).slice(0, -2)) };
  Chart.defaults.borderColor = '#737373'; // Not in color pallette
};

export const useChartDefaults = (): void => {
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));

  useEffect(() => {
    setChartDefaults(OrionTheme, isMobile);
  }, []);
};
