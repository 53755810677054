import {useCallback, useEffect} from 'react';
import {useFAContext} from 'src/components/providers/facebookAds';
import {Step} from 'src/enums/facebook/Step';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {ChooseAccount} from 'src/components/facebook/chooseAccount/ChooseAccount';
import {FormStepOne} from 'src/components/facebook/formStepOne/FormStepOne';
import {FormStepTwo} from 'src/components/facebook/formStepTwo/FormStepTwo';
import {GridType} from 'src/enums/GridType';
import {Helmet} from 'react-helmet-async';
import {PageTitle} from 'src/enums/PageTitle';
import {IFacebookContext} from 'src/types/facebook/FacebookContext';
import {FacebookAdsPage} from 'src/components/facebook/page/Page';
import {useFormPrefill} from 'src/hooks/google/useFormPrefill';
import {ServiceType} from 'src/enums/ServiceType';
import BadgeFooter from 'src/components/common/BadgeFooter';
import MetaPartnerSVG from 'src/assets/MetaPartner.svg';

const WrapTitle = (FormComponent: React.FC, context: IFacebookContext): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>{`${PageTitle.FACEBOOK} | ${context.currentStep}`}</title>
      </Helmet>
      <FormComponent />
    </>
  );
};

export const FacebookAdsAccountHome = (): JSX.Element => {
  const context = useFAContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {t} = useTranslation(['common']);
  const prefillData = useFormPrefill(context);

  useEffect(() => {
    if (!context.facebookAdsAccount.accountId) {
      if (searchParams.get('auth')) {
        const authKey = searchParams.get('auth');
        context.setAuth(authKey);
        context.setCurrentStep(Step.CHOOSE_FACEBOOK_ADS_ACCOUNT);
      } else {
        navigate('/');
      }
    }
  });

  useEffect(() => {
    if (context.currentStep === Step.FILL_BUSINESS_INFO) {
      context.setIsNewReport(true);
    }
  }, [context.currentStep]);

  useEffect(() => {
    if (prefillData.prefillData?.companies && prefillData.prefillData.companies[0]) {

      context.setUserDetails({
        firstName: prefillData.prefillData.first_name!,
        lastName: prefillData.prefillData.last_name!,
        phoneNumber: prefillData.prefillData.companies[0].phone_number!
      });

      context.setBusinessDetails({
        businessName: prefillData.prefillData.companies[0].name!,
        categoryId: prefillData.prefillData.companies[0].business_category_id!,
        subcategoryId: prefillData.prefillData.companies[0].business_sub_category_id!,
        website: prefillData.prefillData.companies[0].url!
      });
    }
  }, [prefillData.prefillData]);

  const renderSwitch = useCallback(() => {
    switch (context.currentStep) {
      case Step.CHOOSE_FACEBOOK_ADS_ACCOUNT:
        return WrapTitle(ChooseAccount, context);
      case Step.FILL_YOUR_INFO:
        return WrapTitle(FormStepOne, context);
      case Step.FILL_BUSINESS_INFO:
        return WrapTitle(FormStepTwo, context);
      default:
        return <>{t('errors.500.title')}</>;
    }
  }, [context]);

  return (
    <FacebookAdsPage
      gridType={GridType.FORM}
      additionalFooter={
        <BadgeFooter
          iconHref={t('facebookBadgeLink')}
          i18nNameSpace={ServiceType.FACEBOOK}
          badgeSVG={MetaPartnerSVG} />
      }
    >
      {renderSwitch()}
    </FacebookAdsPage>
  );
};
