import React, { Component } from 'react';
import { Chart, ChartOptions, ScatterDataPoint, GridLineOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { OrionColors } from 'src/components/theme/orion/common/colors';

interface TooltipDataPoint {
  campaignName: string;
  spend: number;
  impressionShare: number;
  ctr: number;
  x: number;
  y: number;
}

const gridOptions: Partial<GridLineOptions> = {
  display: true,
  drawTicks: false,
  drawOnChartArea: true,
  drawBorder: true,
  borderColor: '#D7D7D7',
  color: '#D7D7D7',
};

const GetScatterChartOptions = (isMobile?: boolean, currencyCode?: string) => {
  return {
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        title: {
          display: true,
          text: 'Visibility (Impression Share)',
          color: '#000',
          font: {
            size: isMobile ? 12 : 14,
            lineHeight: '16px',
            family: 'Unify Sans',
            weight: 'normal',
          },
        },
        ticks: {
          callback: (value, index, ticks) => {
            return index === Math.floor(ticks.length / 2)
              ? (+value / 100).toLocaleString(process.env.REACT_APP_LOCALE, {
                  minimumFractionDigits: 0,
                  style: 'percent',
                })
              : '';
          },
          color: '#000',
          padding: isMobile ? 4 : 16,
          font: {
            size: isMobile ? 12 : 14,
            lineHeight: '16px',
            family: 'Unify Sans',
            weight: 'normal',
          },
        },
        grid: { ...gridOptions },
      },
      y: {
        title: {
          display: true,
          text: 'Traffic (CTR)',
          color: '#000',
          font: {
            size: isMobile ? 12 : 16,
            lineHeight: isMobile ? '16px' : '24px',
            family: 'Unify Sans',
            weight: 'normal',
          },
        },
        ticks: {
          display: true,
          callback: (value, index, ticks) => {
            return index === Math.floor(ticks.length / 2)
              ? (+value / 100).toLocaleString(process.env.REACT_APP_LOCALE, {
                  minimumFractionDigits: 2,
                  style: 'percent',
                })
              : '';
          },
          color: '#000',
          padding: isMobile ? 4 : 16,
          font: {
            size: isMobile ? 12 : 14,
            lineHeight: '16px',
            family: 'Unify Sans',
            weight: 'normal',
          },
        },
        grid: { ...gridOptions },
      },
    },
    plugins: {
      CustomArrowsAndText: {
        drawCustomArrowsAndText: true,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            const dataPoint = context[0].raw as TooltipDataPoint;
            return dataPoint.campaignName || 'No Campaign Name';
          },
          beforeBody: (context) => {
            const dataPoint = context[0].raw as TooltipDataPoint;
            return [
              `Spend: ${dataPoint.spend.toLocaleString(process.env.REACT_APP_LOCALE, { minimumFractionDigits: 0, style: 'currency', currency: currencyCode })}`,
              `Impressions Share: ${(dataPoint.x / 100).toLocaleString(process.env.REACT_APP_LOCALE, { minimumFractionDigits: 2, style: 'percent' })}`,
              `CTR: ${(dataPoint.y / 100).toLocaleString(process.env.REACT_APP_LOCALE, { minimumFractionDigits: 2, style: 'percent' })}`,
            ];
          },
          label: () => {
            return '';
          },
        },
        displayColors: false,
      },
    },
  } as ChartOptions<'scatter'>;
};

export interface IScatterChartProps {
  dataset: {
    campaigns: {
      campaignName: string;
      impressionShare: number;
      ctr: number;
      spend: number;
    }[];
    benchmarks: {
      campaignName: string;
      impressionShare: number;
      ctr: number;
      spend: number;
    }[];
  };
  labels: string[];
  xAxisText?: string;
  options?: ChartOptions;
  isMobile?: boolean;
  currencyCode?: string;
}

export class ScatterChart extends Component<IScatterChartProps> {
  canvasRef: React.RefObject<HTMLCanvasElement> = React.createRef();

  chart: Chart<'scatter', ScatterDataPoint[], string> | undefined;

  drawChart(): void {
    const canvasContext = this.canvasRef.current?.getContext('2d');
    if (!canvasContext) return;

    const chartOptions: ChartOptions<'scatter'> = {
      ...this.props.options,
      scales: {
        ...{
          ...GetScatterChartOptions(this.props.isMobile, this.props.currencyCode).scales,
        },
      },
      plugins: {
        ...this.props.options?.plugins,
        ...GetScatterChartOptions(this.props.isMobile, this.props.currencyCode).plugins,
        CustomArrowsAndText: {
          drawCustomArrowsAndText: true,
        },
      },
    };

    this.chart = new Chart(canvasContext, {
      type: 'scatter',
      data: {
        datasets: [
          {
            label: this.props.labels[0],
            data: this.props.dataset.campaigns.map((campaign) => ({
              ...campaign,
              x: campaign.impressionShare,
              y: campaign.ctr,
            })),
            backgroundColor: OrionColors.chart.orange,
            pointRadius: 7,
            pointBorderColor: '#fff',
            pointBorderWidth: 1,
            pointStyle: 'circle',
          },
          {
            label: this.props.labels[1],
            data: this.props.dataset.benchmarks.map((benchmark) => ({
              ...benchmark,
              x: benchmark.impressionShare,
              y: benchmark.ctr,
            })),
            backgroundColor: OrionColors.chart.blue,
            pointRadius: 7,
            pointBorderColor: '#fff',
            pointBorderWidth: 1,
            pointStyle: 'circle',
          },
        ],
      },
      options: { ...chartOptions },
      plugins: [ChartDataLabels],
    });
  }

  componentDidMount(): void {
    this.chart?.destroy();
    this.drawChart();
  }

  componentDidUpdate(): void {
    this.chart?.destroy();
    this.drawChart();
  }

  render(): JSX.Element {
    return (
      <div style={{ height: this.props.isMobile ? '280px' : '340px', width: '100%' }}>
        <canvas ref={this.canvasRef} />
      </div>
    );
  }
}
