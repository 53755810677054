import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useFAContext} from 'src/components/providers/facebookAds';
import {useFacebookAccounts} from '../../../hooks/facebook/useFacebookAccounts';
import {FieldValues, SubmitHandler} from 'react-hook-form';
import {Logger} from 'src/utils/Logger';
import {Step} from 'src/enums/facebook/Step';
import {ISelectOption} from 'src/types/common/SelectOption';
import {IFacebookAccount} from 'src/types/facebook/FacebookAccount';

type ReturnType = {
  accounts: ISelectOption[];
  backHandler: () => void;
  onSubmit: SubmitHandler<FieldValues>;
}

export const useResponseHandler = (): ReturnType => {
  const context = useFAContext();
  const [searchParams] = useSearchParams();
  const [accounts, setAccounts] = useState<ISelectOption[]>([]);
  const {data, isValidating} = useFacebookAccounts(searchParams.get('auth'));
  const navigate = useNavigate();

  const backHandler = useCallback(() => navigate('/'), []);

  const onSubmit: SubmitHandler<FieldValues> = useCallback((value) => {
    Logger.trace('SubmitHandler', {value}, useResponseHandler.name);
    const selectedAccount = accounts.find((customerAccount) => customerAccount.id === value.customerAccount)!;
    context.setFacebookAdsAccount({accountId: selectedAccount.id, accountName: selectedAccount.name});
    context.setCurrentStep(Step.FILL_YOUR_INFO);
  }, [accounts]);

  const handleResponse = useCallback((accounts: IFacebookAccount[]): void => {
    const formattedAccounts: ISelectOption[] = accounts.map(({id, name}) => ({
      id: id,
      name: name ? `${name} (${id})` : `${id}`
    }));

    setAccounts(formattedAccounts);
  }, []);

  useEffect(() => {
    Logger.trace('useEffect:FacebookAccounts', {data, isValidating}, 'ChooseAccount');
    if (!data) return;

    if (!isValidating && data.facebookAdsAccounts.length < 1) {
      navigate(`/facebook-grader/error?email=${data.email}`);
    }
  
    handleResponse(data.facebookAdsAccounts);
  }, [data, isValidating]);

  return {accounts, backHandler, onSubmit};
};
