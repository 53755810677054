import {Table, TableBody} from '@mui/material';
import {ICampaignRank} from 'src/types/shopping/ShoppingGrade';
import {CampaignRankHead} from './CampaignRankHead';
import {CampaignRankRow} from './CampaignRankRow';

type Props = {
  campaigns: ICampaignRank[];
  currencySymbol: string;
  enumerate?: boolean;
  applyBgColor?: boolean;
}

export const CampaignRankTable = ({campaigns, currencySymbol, enumerate = false, applyBgColor = true}: Props): JSX.Element => {
  return (
    <Table sx={{tableLayout: 'fixed'}}>
      <CampaignRankHead />
      <TableBody>
        {campaigns.map((campaign, index) =>
          <CampaignRankRow
            key={index}
            name={campaign.campaignName}
            spend={campaign.spend}
            impressionShare={campaign.impressionShare}
            ctr={campaign.ctr}
            optimizationLevel={campaign.optimizationLevel}
            currencySymbol={currencySymbol}
            enumerate={enumerate}
            index={index + 1}
            applyBgColor={applyBgColor}
          />)}
      </TableBody>
    </Table>
  );
};
