export enum AdPerformanceType {
  BEST = 'BEST',
  WORST = 'WORST',
  TOP = 'TOP'
}

export const AdPerformanceTypeOrder = {
  [AdPerformanceType.BEST]: 2,
  [AdPerformanceType.WORST]: 3,
  [AdPerformanceType.TOP]: 1
};

export enum AdPerformanceName {
  ADS = 'metrics.adPerformance.benchmark.ads',
  AD_SETS = 'metrics.adPerformance.benchmark.adSets'
}

export enum AdditionalMetric {
  IMPRESSIONS = 'impressions',
  FREQUENCY = 'frequency',
  CLICKS = 'clicks',
  CPR = 'cpr'
}

export enum OverviewList {
  BULLET_0 = 'bullet_0',
  BULLET_1 = 'bullet_1',
  BULLET_2 = 'bullet_2'
}

export type AdditionalMetricKey = keyof typeof AdditionalMetric;
