import { TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { ServiceType } from 'src/enums/ServiceType';
import styled from 'styled-components';

const CaptionWithDottedBorderBottom = styled(Typography).attrs((props) => ({ ...props, variant: 'caption' }))`
  cursor: default;
  font-weight: 600;
  border-bottom: ${({ theme }) => theme.spacing(0.25)} dotted ${({ theme }) => theme.palette.text.primary};
`;

export const AudienceTypesTableHead = (): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);

  return (
    <TableHead>
      <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
        <TableCell colSpan={2} size="small" align="right">
          {t('grade.you')}
        </TableCell>
        <TableCell size="small" align="right">
          <Tooltip enterTouchDelay={0} title={t('grade.keyScore.scoreDescriptionTooltip')} placement="bottom" arrow>
            <CaptionWithDottedBorderBottom>{t('grade.competitorAvg')}</CaptionWithDottedBorderBottom>
          </Tooltip>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
