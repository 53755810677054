import { Box, Divider, styled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { ICampaignObjective } from 'src/types/facebook/FacebookGrade';
import { ObjectiveRow, ObjectiveRowMobile } from './ObjectiveRow';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import ArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ArrowUp from '@mui/icons-material/KeyboardArrowUp';

const StyledArrowUp = styled(ArrowUp)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1rem;
  cursor: pointer;
`;

const StyledArrowDown = styled(ArrowDown)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1rem;
  cursor: pointer;
`;

const StyledExpand = styled(BoldTypography)`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.875rem;
`;

export const Objectives = ({
  objectives,
  currencySymbol,
}: {
  objectives?: ICampaignObjective[];
  currencySymbol: string;
}): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const [expanded, setExpanded] = useState(false);
  const sortedBySpend = useMemo(() => {
    if (!objectives) return;

    return objectives.slice().sort((a, b) => b.spend - a.spend);
  }, [objectives]);

  return (
    <FlexBoxColumn sx={{ alignItems: 'flex-start', width: '100%' }}>
      <BoldTypography fontSize={'1rem'} lineHeight={'1.5em'} letterSpacing={'0.15px'}>
        {t('grade.campaignGoals.table.objectives.title')}
      </BoldTypography>
      <Table>
        <TableHead>
          <TableRow sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
            <TableCell colSpan={2} align="right">
              {t('grade.campaignGoals.table.objectives.spend')}
            </TableCell>
            <TableCell align="right">{t('grade.campaignGoals.table.objectives.results')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!expanded && sortedBySpend && <ObjectiveRow {...sortedBySpend[0]} currencySymbol={currencySymbol} />}
          {expanded &&
            sortedBySpend &&
            sortedBySpend.map((objective) => {
              return <ObjectiveRow key={objective.objective} {...objective} currencySymbol={currencySymbol} />;
            })}
          <TableRow>
            <TableCell width={'60%'}>
              <FlexBoxRow gap={1} sx={{ justifyContent: 'flex-start' }}>
                <StyledExpand onClick={() => setExpanded(!expanded)}>
                  {t(`grade.campaignGoals.table.objectives.${expanded ? 'collapse' : 'expand'}`)}
                </StyledExpand>
                {expanded ? (
                  <StyledArrowUp onClick={() => setExpanded(!expanded)} />
                ) : (
                  <StyledArrowDown onClick={() => setExpanded(!expanded)} />
                )}
              </FlexBoxRow>
            </TableCell>
            <TableCell sx={{ width: '20%' }} />
            <TableCell sx={{ width: '20%' }} />
          </TableRow>
        </TableBody>
      </Table>
    </FlexBoxColumn>
  );
};

export const ObjectivesMobile = ({
  objectives,
  currencySymbol,
}: {
  objectives?: ICampaignObjective[];
  currencySymbol: string;
}): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const [expanded, setExpanded] = useState(false);
  const sortedBySpend = useMemo(() => {
    if (!objectives) return;

    return objectives.slice().sort((a, b) => b.spend - a.spend);
  }, [objectives]);

  return (
    <FlexBoxColumn sx={{ alignItems: 'flex-start', width: '100%', gap: 2 }}>
      <BoldTypography variant="body2" sx={{ alignSelf: 'center' }}>
        {t('grade.campaignGoals.table.objectives.title')}
      </BoldTypography>
      <Box sx={{ width: '100%' }}>
        <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
        <FlexBoxColumn sx={{ width: '100%' }} pb={2}>
          {!expanded && sortedBySpend && <ObjectiveRowMobile {...sortedBySpend[0]} currencySymbol={currencySymbol} />}
          {expanded &&
            sortedBySpend &&
            sortedBySpend.map((objective) => {
              return (
                <>
                  <ObjectiveRowMobile key={objective.objective} {...objective} currencySymbol={currencySymbol} />
                </>
              );
            })}
          <FlexBoxRow gap={1} sx={{ width: '100%', justifyContent: 'flex-start' }} mt={2}>
            <StyledExpand onClick={() => setExpanded(!expanded)}>
              {t(`grade.campaignGoals.table.objectives.${expanded ? 'collapse' : 'expand'}`)}
            </StyledExpand>
            {expanded ? (
              <StyledArrowUp onClick={() => setExpanded(!expanded)} />
            ) : (
              <StyledArrowDown onClick={() => setExpanded(!expanded)} />
            )}
          </FlexBoxRow>
        </FlexBoxColumn>
      </Box>
    </FlexBoxColumn>
  );
};
