import {useGGContext} from 'src/components/providers/googleAds';
import {useFAContext} from 'src/components/providers/facebookAds';
import {NavBar} from './NavBar';
import {useContext} from 'react';
import {ServiceContext} from '../providers/service';
import {ServiceType} from 'src/enums/ServiceType';

export const NavBarContainer = ({children}: {children: JSX.Element | JSX.Element[]}): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const {googleAdsAccount} = service === ServiceType.GOOGLE
    ? useGGContext()
    : {googleAdsAccount: undefined};
  const {facebookAdsAccount} = service === ServiceType.FACEBOOK
    ? useFAContext()
    : {facebookAdsAccount: undefined};

  return <>
    <NavBar accountName={googleAdsAccount?.accountName ?? facebookAdsAccount?.accountName} />
    {children}
  </>;
};
