import { Box, SxProps, Theme, Typography } from '@mui/material';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { GoogleDisplayScoreSection } from 'src/enums/google/ScoreSection';
import { GradeUtils } from 'src/utils/GradeUtils';
import { useDisplayGradeScore } from 'src/hooks/display/useDisplayGradeScore';
import { IDisplayGrade } from 'src/types/display/DisplayGrade';
import { ReactComponent as AlertIcon } from 'src/assets/AlertIconCurrent.svg';
import { ScoreName } from 'src/enums/ScoreName';
import { TFunction, useTranslation } from 'react-i18next';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { HelperTooltip } from 'src/components/common/grade/Tooltip';
import styled from 'styled-components';
import { OrionTheme } from '../../../theme/orion/Theme';

interface Props {
  sectionName: GoogleDisplayScoreSection;
}

const Metric = ({ sectionName, grade }: Props & { grade: IDisplayGrade }): string => {
  switch (sectionName) {
    case GoogleDisplayScoreSection.STATUS:
      return (grade.adTypesStats.overallCtr / 100).toLocaleString(process.env.REACT_APP_LOCALE, {
        minimumFractionDigits: 2,
        style: 'percent',
      });
    case GoogleDisplayScoreSection.PLACEMENT:
      return grade.adsPlacementStats.viewableImpressions.toLocaleString(process.env.REACT_APP_LOCALE);
    case GoogleDisplayScoreSection.AUDIENCE_PERFORMANCE:
      return grade.displayAudienceMetrics.cpm.toLocaleString(process.env.REACT_APP_LOCALE, {
        minimumFractionDigits: 2,
        style: 'currency',
        currency: grade.overallScore.currencyCode,
      });
  }
};

interface IconProps {
  scorename: ScoreName | undefined;
  theme: Theme;
}

const StyledAlertIcon = styled(AlertIcon)`
  fill: ${({ scorename, theme }: IconProps) => (scorename ? GradeUtils.getScoreColor(scorename, theme) : 'none')};
`;

const sectionsWithTooltips = ['displayAdPlacements'];

interface ContentProps {
  scoreName: ScoreName | undefined;
  sectionKey: string;
  metric: string;
  t: TFunction;
}

const Content = ({ scoreName, sectionKey, metric, t }: ContentProps): JSX.Element => {
  const sx: SxProps = {
    whiteSpace: 'pre',
    width: OrionTheme.spacing(36),
  };

  return (
    <FlexBoxColumn gap={2} sx={{ alignSelf: 'stretch' }}>
      {sectionKey && sectionsWithTooltips.includes(sectionKey) ? (
        <Box sx={{ ...sx, textAlign: 'center' }}>
          <HelperTooltip
            key={sectionKey}
            color={'common.black'}
            title={t('grade.keyMetricToolTip', { context: sectionKey })}
          >
            <Typography component="span" fontWeight={'fontWeightMedium'} variant="h4">
              {t('grade.keyMetricScoreTitle', { context: sectionKey })}
            </Typography>
          </HelperTooltip>
        </Box>
      ) : (
        <FlexBoxColumn gap={0.5} sx={{ ...sx }}>
          <BoldTypography textAlign={'center'} variant="h4">
            {t('grade.keyMetricScoreTitle', { context: sectionKey })}
          </BoldTypography>
        </FlexBoxColumn>
      )}
      <FlexBoxRow gap={1}>
        {scoreName !== ScoreName.ADVANCED && <StyledAlertIcon scorename={scoreName} />}
        <Typography variant="h3" whiteSpace={'pre'}>
          {metric}
        </Typography>
      </FlexBoxRow>
    </FlexBoxColumn>
  );
};

export const DisplayKeyMetric = ({ sectionName, sectionKey }: Props & { sectionKey: string }): JSX.Element => {
  const { displayGrade, scoreName } = useDisplayGradeScore({ sectionName });
  const metric = displayGrade && Metric({ sectionName, grade: displayGrade });
  const { t } = useTranslation([GoogleAdType.DISPLAY]);

  return metric ? (
    Content({
      scoreName,
      sectionKey,
      metric,
      t,
    })
  ) : (
    <></>
  );
};
