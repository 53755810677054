import {Tab as MuiTab, TabProps} from '@mui/material';
import styled from 'styled-components';

const Props: TabProps = {
  disableRipple: true
};

const Tab= styled(MuiTab)`
  max-width: 100%;
`;

Tab.defaultProps = Props;

export {Tab};
