import {TableCell, TableRow} from '@mui/material';
import {GradeUtils} from 'src/utils/GradeUtils';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {useTranslation} from 'react-i18next';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {GoogleDisplayScoreSection} from 'src/enums/google/ScoreSection';
import {useDisplayGradeScore} from 'src/hooks/display/useDisplayGradeScore';

type Props = {
  impressions: number;
  cpc: number;
  ctr: number;
  spend: number;
  clicks: number;
  sectionName: GoogleDisplayScoreSection;
}

export const MobileTableSection = ({impressions, cpc, ctr, spend, clicks, sectionName}: Props): JSX.Element => {
  const {t} = useTranslation([GoogleAdType.DISPLAY]);
  const {scoreName, currencyCode} = useDisplayGradeScore({sectionName});

  return (
    <>
      <TableRow>
        <TableCell size='small' sx={{fontWeight: 600}}>
          {t('grade.displayAdPlacements.impressions')}
        </TableCell>
        <TableCell sx={{backgroundColor: GradeUtils.getScoreColorLight(scoreName!)}} size='small' align='right'>
          <BoldTypography variant='subtitle1'>
            {impressions.toLocaleString(process.env.REACT_APP_LOCALE)}
          </BoldTypography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell size='small' sx={{fontWeight: 600}}>
          {t('grade.displayAdPlacements.cpc')}
        </TableCell>
        <TableCell align='right' padding='none' sx={{p: 1}}>
          {cpc.toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 2, style: 'currency', currency: currencyCode})}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell size='small' sx={{fontWeight: 600}}>
          {t('grade.displayAdPlacements.ctr')}
        </TableCell>
        <TableCell align='right' padding='none' sx={{p: 1}}>
          {(ctr / 100).toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 2, style: 'percent'})}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell size='small' sx={{fontWeight: 600}}>
          {t('grade.displayAdPlacements.spend')}
        </TableCell>
        <TableCell align='right' padding='none' sx={{p: 1}}>
          {spend.toLocaleString(process.env.REACT_APP_LOCALE, {minimumFractionDigits: 2, style: 'currency', currency: currencyCode})}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell size='small' sx={{fontWeight: 600}}>
          {t('grade.displayAdPlacements.clicks')}
        </TableCell>
        <TableCell align='right' padding='none' sx={{p: 1}}>
          {clicks.toLocaleString(process.env.REACT_APP_LOCALE)}
        </TableCell>
      </TableRow>
    </>
  );
};
