import { Divider, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GradeUtils } from 'src/utils/GradeUtils';
import { TableRowMobile } from '../TableRowMobile';
import { toLocale, withSymbol } from '../utils';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { OrionTheme } from 'src/components/theme/orion/Theme';

type Props = {
  objective: string;
  spend?: number;
  results?: number;
  currencySymbol: string;
};

export const ObjectiveRow = ({ objective, spend, results, currencySymbol }: Props): JSX.Element => {
  return (
    <TableRow key={objective} sx={{ borderBottom: `1px solid ${OrionColors.gray.neutral}` }}>
      <TableCell>
        <Typography sx={{ textAlign: 'left' }} variant="body1">
          {GradeUtils.transformToPascal(objective)}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body1">{withSymbol(spend, currencySymbol)}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body1">{toLocale(results)}</Typography>
      </TableCell>
    </TableRow>
  );
};

export const ObjectiveRowMobile = ({ objective, spend, results, currencySymbol }: Props): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);

  return (
    <Table sx={{ tableLayout: 'fixed', borderSpacing: `0 ${OrionTheme.spacing(1)}`, borderCollapse: 'separate' }}>
      <TableBody>
        <TableRow>
          <TableCell colSpan={2} size="small">
            <Typography variant="h6" sx={{ alignSelf: 'flex-start' }}>
              {GradeUtils.transformToPascal(objective)}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRowMobile
          text={t('grade.campaignGoals.table.objectives.spend')}
          value={withSymbol(spend, currencySymbol)}
          isBold={false}
        />
        <TableRowMobile
          text={t('grade.campaignGoals.table.objectives.results')}
          value={toLocale(results)}
          isBold={false}
        />
        <TableRow>
          <TableCell colSpan={2} padding="none">
            <Divider sx={{ width: '100%', borderColor: OrionColors.gray.neutral }} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
