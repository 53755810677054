import { Grid, styled, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { withSymbol, toLocale } from '../utils';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { ServiceType } from 'src/enums/ServiceType';
import { OrionColors } from 'src/components/theme/orion/common/colors';

const Box = styled(FlexBoxColumn)(({ theme }) => ({
  padding: useMediaQuery(theme.breakpoints.down('sm')) ? theme.spacing(2) : theme.spacing(1),
  border: `1px solid ${OrionColors.gray.neutral}`,
  gap: theme.spacing(1),
}));

type Props = {
  campaigns?: number;
  totalSpend?: number;
  totalResults?: number;
  ctr?: number;
  costPerResult?: number;
  clicks?: number;
  currencySymbol: string;
};

export const Summary = ({
  campaigns,
  totalSpend,
  totalResults,
  ctr,
  costPerResult,
  clicks,
  currencySymbol,
}: Props): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));

  return (
    <FlexBoxColumn sx={{ alignItems: 'flex-start', width: '100%', gap: 2 }}>
      <BoldTypography
        fontSize={'1rem'}
        lineHeight={'1.5em'}
        letterSpacing={'0.15px'}
        sx={{ alignSelf: isMobile ? 'center' : 'flex-start' }}
      >
        {t('grade.campaignGoals.table.summary.title')}
      </BoldTypography>
      <Grid container rowSpacing={1} columns={12} columnSpacing={1}>
        <Grid item xs={6} sm={4}>
          <Box>
            <BoldTypography variant="caption" textAlign="center">
              {t('grade.campaignGoals.table.summary.campaigns')}
            </BoldTypography>
            <Typography fontSize={'1rem'} lineHeight={'1.5em'} letterSpacing={'0.15px'}>
              {toLocale(campaigns)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box>
            <BoldTypography variant="caption" textAlign="center">
              {t('grade.campaignGoals.table.summary.totalSpend')}
            </BoldTypography>
            <Typography fontSize={'1rem'} lineHeight={'1.5em'} letterSpacing={'0.15px'}>
              {withSymbol(totalSpend, currencySymbol)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box>
            <BoldTypography variant="caption" textAlign="center">
              {t('grade.campaignGoals.table.summary.totalResults')}
            </BoldTypography>
            <Typography fontSize={'1rem'} lineHeight={'1.5em'} letterSpacing={'0.15px'}>
              {toLocale(totalResults)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box>
            <BoldTypography variant="caption" textAlign="center">
              {t('grade.campaignGoals.table.summary.ctr')}
            </BoldTypography>
            <Typography fontSize={'1rem'} lineHeight={'1.5em'} letterSpacing={'0.15px'}>
              {withSymbol(ctr, '%')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box>
            <BoldTypography variant="caption" textAlign="center">
              {t('grade.campaignGoals.table.summary.costPerResult')}
            </BoldTypography>
            <Typography fontSize={'1rem'} lineHeight={'1.5em'} letterSpacing={'0.15px'}>
              {withSymbol(costPerResult, currencySymbol)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Box>
            <BoldTypography variant="caption" textAlign="center">
              {t('grade.campaignGoals.table.summary.clicks')}
            </BoldTypography>
            <Typography fontSize={'1rem'} lineHeight={'1.5em'} letterSpacing={'0.15px'}>
              {toLocale(clicks)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </FlexBoxColumn>
  );
};
