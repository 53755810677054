import {useCallback, useContext, useEffect, useState} from 'react';
import {useForm, UseFormReturn} from 'react-hook-form';
import {ICategory} from 'src/types/common/Category';
import {KeywordResponse} from 'src/types/keywords/KeywordResponse';
import {ILocation} from 'src/types/common/Location';
import {useCategories} from 'src/hooks/common/useCategories';
import {useGeoLocations} from 'src/hooks/google/useGeoLocations';
import {useLazyRequest} from 'src/hooks/keywords/useLazyQuery';
import {KeywordsContext, ALL_INDUSTRIES_CATEGORY} from '../../providers/keywords';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslation} from 'react-i18next';
import {ITranslation} from 'src/types/common/Translation';

const urlRegex = new RegExp(/[(http(s)?)://(www.)?a-zA-Z0-9@:%._\-+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ig);
const captchSrc = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;

export interface IKeywordForm {
  websiteURLOrKeyword: string;
  industry: string;
  location: ILocation;
}

type ReturnType = {
  methods: UseFormReturn<IKeywordForm>;
  searchKeywords: () => void;
  categories: ICategory[];
}

type PostBody = {
  locationIds: number[];
  categoryId?: number;
}

interface KeywordPostBody extends PostBody {
  keyword: string;
}

interface WebsiteUrlPostBody extends PostBody {
  websiteUrl: string;
}

const getValidationSchema = ({t}: ITranslation, urlRegex: RegExp) => Yup.object().shape({
  websiteURLOrKeyword: Yup.string()
    .required(t('error.required'))
    .max(256, t('error.keywordLength'))
    .test('isNotEmpty', t('error.emptySearch'), value => {
      return !!value && value.trim().length > 0;
    })
    .test('isEmoji', t('error.emoji'), value => {
      return !!value && !value.match(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g);
    })
    .test('IsUrlASubString', t('error.invalidUrl'), value => {
      if (!value) return true;

      const website = value.match(urlRegex);
      return website ? website[0] === value : true;
    })
});

export const useKeywordForm = (modalFormat: boolean): ReturnType => {
  const {t} = useTranslation(['keywords']);
  const [isReseted, setReset] = useState(false);
  const {executeQuery, response} = useLazyRequest<KeywordResponse>({url: `${process.env.REACT_APP_BACKEND_URL}/google/keywords`});
  const {setKeywords, setStatus, keywordFormState, setKeywordFormState} = useContext(KeywordsContext);
  const {categories} = useCategories();
  const {locations} = useGeoLocations(keywordFormState.location.name);

  const methods = useForm({
    defaultValues: {...keywordFormState},
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema({t}, urlRegex))
  });

  // Jumping through hoops to get validation to trigger upon page load but only for modal format
  const {reset, trigger} = methods;
  useEffect(() => {
    reset({...keywordFormState});
    setReset(true);
  }, [reset]);

  useEffect(() => {
    if (modalFormat && isReseted) trigger();
  }, [trigger, isReseted, modalFormat]);
  // End jumping through hoops

  useEffect(() => {
    if (response) {
      setStatus({isLoading: false, isDone: true, error: null});
      setKeywords(response);
    }
  }, [response]);

  useEffect(() => {
    if (locations) {
      methods.setValue('location', locations.find((location) => location.criteriaId === 2840) ?? locations[0]);
    }
  }, [locations]);

  // load recaptcha script
  useEffect(() => {
    const script = document.createElement('script');
    script.src = captchSrc;
    document.body.appendChild(script);
  }, []);

  const searchKeywords = useCallback(() => {
    setStatus({isLoading: true, isDone: false, error: null});
    setKeywordFormState({...methods.getValues()});

    const {websiteURLOrKeyword, industry, location} = methods.getValues();
    const websiteUrlMatch = websiteURLOrKeyword.match(urlRegex);

    const body = websiteUrlMatch
      ? {
        locationIds: [location.criteriaId],
        websiteUrl: websiteUrlMatch[0]
      } as WebsiteUrlPostBody
      : {
        locationIds: [location.criteriaId],
        keyword: websiteURLOrKeyword,
      } as KeywordPostBody;

    if (industry) {
      body.categoryId = parseInt(industry);
    }

    // send recaptcha to post using headers
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY!, {action: 'submit'})
        .then((recaptcha) => executeQuery({options: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            recaptcha
          },
          body: JSON.stringify(body),
        }}));
    });
  }, [methods]);

  return {methods, searchKeywords,
    categories: categories
      ? [ALL_INDUSTRIES_CATEGORY, ...categories]
      : [ALL_INDUSTRIES_CATEGORY]
  };
};
