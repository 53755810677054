import { Grid, styled, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ServiceType } from 'src/enums/ServiceType';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { BoldTypography } from 'src/components/common/styled/BoldTypography';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { AdditionalMetric, AdditionalMetricKey } from 'src/enums/facebook/AdPerformance';
import { toLocale, withSymbol } from '../../grade/table/utils';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { OrionColors } from 'src/components/theme/orion/common/colors';

const StyledColumn = styled(FlexBoxColumn)`
  flex: 1 0 0;
  padding: ${({ theme }) => theme.spacing(1)};
  border: ${({ theme }) => theme.spacing(0.125)} solid ${OrionColors.gray.neutral};
  gap: ${({ theme }) => theme.spacing(1)};
  ${({ theme }) => theme.breakpoints.down(600)} {
    align-self: stretch;
    padding: ${({ theme }) => theme.spacing(2)};
    justify-content: space-between;
  }
`;

type Props = {
  metrics: Record<AdditionalMetric, number>;
  currencySymbol: string;
};

export const AdditionalMetrics = ({ metrics, currencySymbol }: Props): JSX.Element => {
  const { t } = useTranslation([ServiceType.FACEBOOK]);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down(600));

  return (
    <ResponsiveRow breakpoint={600} sx={{ width: '100%' }}>
      <FlexBoxColumn sx={{ alignItems: isMobile ? 'center' : 'flex-start', width: '100%', gap: 2 }}>
        <BoldTypography variant="body2">{t('metrics.adPerformance.additionalMetrics.title')}</BoldTypography>
        <Grid container rowSpacing={1} columns={12} columnSpacing={1}>
          {Object.keys(AdditionalMetric).map((metricKey, index) => (
            <Grid item xs={6} key={index}>
              <StyledColumn>
                <BoldTypography variant="caption" textAlign="center">
                  {t(
                    `metrics.adPerformance.additionalMetrics.${AdditionalMetric[`${metricKey}` as AdditionalMetricKey]}`,
                  )}
                </BoldTypography>
                <Typography variant="h6">
                  {(metricKey as AdditionalMetricKey) === 'CPR'
                    ? withSymbol(metrics.cpr, currencySymbol)
                    : toLocale(metrics[AdditionalMetric[metricKey as AdditionalMetricKey]])}
                </Typography>
              </StyledColumn>
            </Grid>
          ))}
        </Grid>
      </FlexBoxColumn>
    </ResponsiveRow>
  );
};
