import {createContext, Dispatch, SetStateAction, useState} from 'react';
import {ServiceType} from 'src/enums/ServiceType';

export const ServiceContext = createContext<[ServiceType, Dispatch<SetStateAction<ServiceType>>]>([ServiceType.NONE, () => null]);

type Props = {
  children: JSX.Element | JSX.Element[];
  service: ServiceType;
}

export const DataServiceProvider = ({children, service}: Props): JSX.Element => {
  const state = useState<ServiceType>(service);
  
  return <ServiceContext.Provider value={state}>{children}</ServiceContext.Provider>;
};
