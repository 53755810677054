import useSWR from 'swr';
import {MetricsResponse} from 'src/components/providers/metricsData';
import {IErrorResponse} from 'src/types/common/Error';
import {useContext} from 'react';
import {ServiceContext} from 'src/components/providers/service';
import {GoogleAdType} from 'src/enums/GoogleAdType';
import {ServiceType} from 'src/enums/ServiceType';

type ReturnType = {
  history?: MetricsResponse;
  historyError?: IErrorResponse;
}

type Props = {
  UUID?: string;
  googleAdType?: GoogleAdType;
}

export const useFetchHistory = ({UUID, googleAdType}: Props): ReturnType => {
  const [service] = useContext(ServiceContext);
  const endpoint = service === ServiceType.GOOGLE && googleAdType !== GoogleAdType.OVERALL
    ? `${googleAdType}/history`
    : 'history';
  const {data, error} = useSWR(
    `${process.env.REACT_APP_BACKEND_URL}/${service}/grade/${UUID}/${endpoint}`,
    {onError: () => null}
  );

  return {
    history: data,
    historyError: error
  };
};
