import { Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ScoreContent } from './ScoreContent';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { ContactUsSection } from 'src/components/common/grade/header/ContactUsSection';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { GoogleAdTypeContext } from 'src/components/providers/adType';
import { useContext } from 'react';
import { ServiceType } from 'src/enums/ServiceType';
import { FontWeight } from 'src/components/theme/orion/overrides/FontWeight';

export const Header = (): JSX.Element => {
  const { isDisplay, isShopping } = useContext(GoogleAdTypeContext);
  const nameSpace = isDisplay ? GoogleAdType.DISPLAY : isShopping ? GoogleAdType.SHOPPING : ServiceType.GOOGLE;
  const { t } = useTranslation([nameSpace, 'common']);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));

  return (
    <FlexBoxColumn gap={isMobile ? 3 : 6} sx={{ maxWidth: 792 }}>
      <Typography variant="h1" color="common.white" fontWeight={FontWeight.SemiBold}>
        {t('header.title', { ns: nameSpace })}
      </Typography>
      <ScoreContent />
      <ContactUsSection stretched={true} />
    </FlexBoxColumn>
  );
};
