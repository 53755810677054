import useSWR from 'swr';
import {useEffect, useMemo} from 'react';
import {Logger} from '../../utils/Logger';
import {ISpend} from '../../types/overall/OverallGrades';
import {IErrorResponse} from 'src/types/common/Error';

export const useOverallSpend = (uuid: string | undefined): {spendData?: ISpend; spendError?: IErrorResponse} => {
  const URL = useMemo(() => `${process.env.REACT_APP_BACKEND_URL}/google/spend/${uuid}`, [uuid]);
  const {data, error} = useSWR<ISpend>(URL);

  const spendData = useMemo(() => {
    if (data) {
      const gradedSpend = data.search + data.display + data.shopping;
      const otherSpend = Number(
        (
          data.discovery + 
          data.local + 
          data.hotel +
          data.localServices + 
          data.multiChannel + 
          data.performanceMax + 
          data.smart + 
          data.video + 
          data.unknown + 
          data.unspecified
        ).toFixed(2)
      );

      return {
        ...data,
        gradedSpend,
        otherSpend
      };
    }
    return data;
  }, [data]);

  useEffect(() => {
    if (data || error) {
      Logger.trace('overallSpend:swr', {data, error}, useOverallSpend.name);
    }
  }, [data, error]);

  return {
    spendData,
    spendError: error
  };
};
