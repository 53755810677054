import styled from 'styled-components';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { OrionTheme } from 'src/components/theme/orion/Theme';

export const SpendFlexBoxColumn = styled(FlexBoxColumn)`
  background-color: ${({ theme }) => theme.palette.background.default};
  box-sizing: border-box;
  border: ${({ theme }) => theme.spacing(0.125)} solid ${({ theme }) => theme.palette.common.black};
  border-radius: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(4)}`};
`;

export const SpendWrapperFlexBoxColumn = styled(FlexBoxColumn)({
  borderLeft: `${OrionTheme.spacing(0.125)} solid ${OrionTheme.palette.common.black}`,
  borderTop: `${OrionTheme.spacing(0.125)} solid ${OrionTheme.palette.common.black}`,
  borderRight: `${OrionTheme.spacing(0.125)} solid ${OrionTheme.palette.common.black}`,
  borderTopRightRadius: OrionTheme.spacing(1),
  borderTopLeftRadius: OrionTheme.spacing(1),
});
