import { Typography, useMediaQuery } from '@mui/material';
import { LineHeight } from 'src/components/theme/orion/overrides/LineHeight';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import useDateRange from './useDateRange';

export const DateRange = (): JSX.Element => {
  const { dateRange } = useDateRange();
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));
  return (
    <Typography
      data-testid="dateRange"
      variant="body1"
      sx={{ fontSize: isMobile ? 14 : 16 }}
      lineHeight={LineHeight.LOOSE}
      fontStyle="normal"
      color="common.black"
    >
      {dateRange}
    </Typography>
  );
};
