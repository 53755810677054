import React, {useMemo} from 'react';
import {MetricsTabKey} from 'src/enums/google/MetricsTabKey';
import {SearchMetrics} from './SearchMetrics';
import {TextAds} from './TextAds/TextAds';
import {PerformanceTracker} from './tracker/PerformanceTracker';
import {IGoogleGrade} from 'src/types/google/GoogleGrade';
import {SizeOfAccount} from 'src/components/common/metrics/SizeOfAccount';

type Props = {
  gradeWithTextAds: boolean;
  grade: IGoogleGrade | undefined;
  selected?: MetricsTabKey;
}

type ContentProps = {
  tabKey: MetricsTabKey;
  grade: IGoogleGrade | undefined;
}

const renderPanelContent = ({tabKey, grade}: ContentProps) => {
  let value: JSX.Element;
  switch (tabKey) {
    case MetricsTabKey.PerformanceMetrics:
      value = <SearchMetrics key={tabKey} />;
      break;
    case MetricsTabKey.PerformanceTracker:
      value = <PerformanceTracker key={tabKey} />;
      break;
    case MetricsTabKey.SizeOfAccount:
      value = grade
        ? <SizeOfAccount
          activeAds={grade.accountSizeMetrics.activeAds}
          activeSearchCampaigns={grade.accountSizeMetrics.activeSearchCampaigns}
          activeAdGroups={grade.accountSizeMetrics.activeAdGroups}
          activeKeywords={grade.accountSizeMetrics.activeKeywords}
          averageAdGroupsPerCampaign={grade.accountSizeMetrics.averageAdGroups}
          averageActiveKeywordsPerAdGroup={grade.accountSizeMetrics.averageAdGroupKeywords}
          activeResponsiveSearchAds={grade.accountSizeMetrics.activeResponsiveSearchAds}
          responsiveSearchAdsPercentage={grade.accountSizeMetrics.responsiveSearchAdsPercentage}
          key={tabKey}
        />
        : <></>;
  }
  return value!;
};

type ReturnType = {
  TabsPanels: JSX.Element[];
}

export const useTabPanels = ({gradeWithTextAds, grade, selected}: Props): ReturnType => {
  const TabsPanels = useMemo(() => {
    return Object.values(MetricsTabKey).map((tabKey) => {
    
      if (tabKey === MetricsTabKey.TextAds) {
        return gradeWithTextAds
          ? selected === tabKey && <TextAds key={tabKey} />
          : null;
      }
    
      return <React.Fragment key={tabKey}>{selected === tabKey && renderPanelContent({tabKey, grade})}</React.Fragment>;
    }).filter((panel) => !!panel) as JSX.Element[];
    
  }, [gradeWithTextAds, selected]);

  return {TabsPanels};
};
