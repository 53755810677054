import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {ResponsiveRow} from 'src/components/common/ResponsiveRow';
import {MetricsDataProvider} from 'src/components/providers/metricsData';
import {SelectedMetricProvider} from 'src/components/providers/selectedMetrics';
import styled from 'styled-components';
import {DateRange} from '../../../common/metrics/DateRange';
import {DynamicChart} from '../../../common/metrics/DynamicChart';
import {Email} from '../../../common/metrics/Email';
import {MetricSelect} from './MetricSelect';
import {ServiceType} from 'src/enums/ServiceType';
import {useParams} from 'react-router-dom';
import {useOverallGrade} from 'src/hooks/overall/useOverallGrade';

const SelectContainer = styled(ResponsiveRow).attrs((props) => ({...props, breakpoint: 600}))`
    width: 100%;
    gap: ${({theme}) => theme.spacing(2)};
    align-items: center;
    justify-content: space-between;
`;

export const PerformanceTracker = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.GOOGLE]);
  const {UUID} = useParams();
  const {overallGrade} = useOverallGrade(UUID);

  return (
    <SelectedMetricProvider>
      <MetricsDataProvider>
        <FlexBoxColumn width={'100%'} gap={2} sx={{pt: 2}}>
          <Typography alignSelf={'flex-start'} variant='h6' fontWeight={'fontWeightMedium'}>
            {t('tracker.view')}
          </Typography>
          <SelectContainer>
            <MetricSelect />
            <DateRange />
          </SelectContainer>
          <DynamicChart currencySymbol={overallGrade?.currencySymbol ?? '$'} />
          <Email sx={{mt: 3}} />
        </FlexBoxColumn>
      </MetricsDataProvider>
    </SelectedMetricProvider>
  );
};
