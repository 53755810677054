import {useMemo} from 'react';

type Props = {
  you?: number;
  competitor?: number;
  isMobile: boolean;
}

type ReturnType = {
  youBoxFill: string;
  competitorBoxFill: string;
}

export const useBenchmarkFill = ({you, competitor, isMobile}: Props): ReturnType => {
  // Set 'Fill' pcts to 2 or 3 instead of 0 when they are 0, so we see some color representation on the bar graph instead of none
  // With rounded corners, need 2% & 3% for mobile for shape to look as expected
  const minPercent = isMobile ? '3%' : '2%';
  const youBoxFill = useMemo(() => {
    if (!you) return minPercent;
    if (!competitor) return '100%';
    //Calculating what percentage of 'you' is of 'competitor'
    const percentage = you * 100 / competitor;

    return percentage > 100 ? '100%' : percentage === 0 ? minPercent : `${percentage}%`;
  }, [you, competitor]);

  const competitorBoxFill = useMemo(() => {
    if (!competitor) return minPercent;
    if (!you || you < competitor || you === competitor) {
      return '100%';
    }
    //Calculating what percentage of 'competitor' is of 'you'
    const percentage = competitor * 100 / you;

    return percentage > 100 ? '100%' : percentage === 0 ? minPercent : `${percentage}%`;
  }, [you, competitor]);

  return {youBoxFill, competitorBoxFill};
};
