import {Alert, Snackbar, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import AlertIcon from 'src/assets/AlertIconCurrent.svg';

const ErrorSnackbar = (): JSX.Element => {
  const [open, setOpen] = useState(true);
  const {t} = useTranslation(['common']);

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => setOpen(false)}
      anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
      <Alert
        severity='error'
        variant='standard'
        icon={<img src={AlertIcon} />}
        action={
          <Typography
            variant='button'
            component="a"
            sx={{cursor: 'pointer', textDecoration: 'underline', textTransform: 'none'}}
            onClick={() => setOpen(false)}>
            Dismiss
          </Typography>
        }>
        {t('errors.general')}
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
