import {
  BubbleDataPoint,
  Chart,
  ChartDataset,
  ChartOptions,
  ChartTypeRegistry,
  registerables,
  ScatterDataPoint,
  Tooltip,
} from 'chart.js';
import {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { IMetric, MetricsDataContext, MetricsResponse } from 'src/components/providers/metricsData';
import { SelectedMetricContext } from 'src/components/providers/selectedMetrics';
import { GradeUtils } from 'src/utils/GradeUtils';
import { getResponsiveMetricChartFont } from 'src/utils/helpers';
import { externalTooltipHandler } from 'src/utils/externalTooltip';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { useFetchHistory } from 'src/hooks/common/useFetchHistory';
import { ServiceContext } from 'src/components/providers/service';
import { ServiceType } from 'src/enums/ServiceType';
import { GoogleAdTypeContext } from 'src/components/providers/adType';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { yAxisTitles, MetricType } from './PerfTrackerYAxisTitles';

Chart.register(...registerables, Tooltip);

type ReturnType = {
  canvasRef: RefObject<HTMLCanvasElement>;
  isLoading: boolean;
  hasHistoryData: boolean;
  // Note: Added those 2 extra props so I can more easily test the hook
  setContextData: Dispatch<SetStateAction<MetricsResponse | undefined>>;
  chartRef: MutableRefObject<
    Chart<keyof ChartTypeRegistry, (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown> | undefined
  >;
};

interface DynamicChartOptions extends ChartOptions {
  tickSuffix?: string;
  tickPrefix?: string;
}

const DateFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: 'numeric',
};

type Props = {
  currencySymbol: string;
};

export const useDynamicChart = ({ currencySymbol }: Props): ReturnType => {
  const chartRef = useRef<Chart>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [selectedMetric] = useContext(SelectedMetricContext);
  const [contextData, setContextData] = useContext(MetricsDataContext);
  const [service] = useContext(ServiceContext);
  const { googleAdType, isSearch, isShopping, isDisplay } = useContext(GoogleAdTypeContext);
  const [isLoading, setLoading] = useState(true);
  const { UUID } = useParams();
  const { history, historyError } = useFetchHistory({ UUID, googleAdType });
  const axisLabelFontSize = window.innerWidth > 600 ? 16 : 12;
  const axisLabelPadding = window.innerWidth > 600 ? 10 : 8;

  const adType = useMemo(() => {
    if (service === ServiceType.FACEBOOK) {
      return GoogleAdType.NONE;
    }
    if (isDisplay) return GoogleAdType.DISPLAY;
    if (isSearch) return GoogleAdType.SEARCH;
    if (isShopping) return GoogleAdType.SHOPPING;
    return GoogleAdType.OVERALL;
  }, [service, isDisplay, isSearch, isShopping]);
  const yAxisTitle = yAxisTitles[service][adType]?.[selectedMetric as MetricType] || 'Performance Tracker Results';

  const hasHistoryData = useMemo(() => {
    return !!(contextData?.grades && contextData.grades.length > 1);
  }, [contextData]);

  useEffect(() => {
    if (!canvasRef.current) return;

    const onMouseLeave = () => {
      const tooltip = document.getElementById('external_tooltip');
      if (tooltip && !tooltip.matches(':hover')) {
        setTimeout(() => {
          tooltip.remove();
        }, 500);
      }
    };

    canvasRef.current.addEventListener('mouseout', onMouseLeave);

    return () => chartRef.current?.canvas && chartRef.current.canvas.removeEventListener('mouseout', onMouseLeave);
  }, [canvasRef.current]);

  useEffect(() => {
    if (history || historyError) setLoading(false);
  }, [history, historyError]);

  useEffect(() => {
    if (history) {
      setContextData(history);
    }
  }, [history]);

  useEffect(() => {
    const externalTooltip = document.getElementById('external_tooltip');
    if (externalTooltip) {
      externalTooltip.style.opacity = '0';
    }
  }, [selectedMetric]);

  const getMetricData = useCallback(
    (prop: keyof IMetric, roundoff?: number) => {
      if (!contextData || contextData.grades.length < 2) return [];

      return contextData.grades
        .slice(-6)
        .map((grade) => {
          let value = +grade[prop];
          if (prop === 'ctr') {
            value *= 100;
          }
          if (roundoff !== undefined && value > 0) {
            return GradeUtils.roundoff(value, roundoff);
          }
          return value;
        })
        .reverse();
    },
    [contextData],
  );

  const getMetricLabels = useCallback(() => {
    if (!contextData) return [];

    return contextData.grades
      .slice(-6)
      .map((grade) =>
        new Date(grade.reportDate.replaceAll('-', '/')).toLocaleDateString(
          process.env.REACT_APP_LOCALE,
          DateFormatOptions,
        ),
      )
      .reverse();
  }, [contextData]);

  const getUUID = useCallback((index: number) => contextData?.grades.slice(-6).reverse()[index].uuid, [contextData]);

  const chartOptionsAndDataSet = useMemo(() => {
    let genOptions: DynamicChartOptions = {
      interaction: {
        mode: 'index',
        intersect: false,
      },
      maintainAspectRatio: false,
      font: {
        family: OrionTheme.typography.fontFamily,
        size: OrionTheme.typography.fontSize,
        lineHeight: OrionTheme.spacing(2),
      },
    };

    let roundoff;

    switch (selectedMetric) {
      case 'overallScore':
        genOptions = {
          ...genOptions,
          tickSuffix: '%',
        };
        roundoff = 0;
        break;
      case 'impressionShare':
      case 'ctr':
        genOptions = {
          ...genOptions,
          tickSuffix: '%',
        };
        roundoff = 2;
        break;
      case 'wastedSpend':
      case 'spend':
      case 'cpa':
      case 'cpm':
        genOptions = {
          ...genOptions,
          tickPrefix: currencySymbol,
        };
        break;
      case 'qualityScore':
        roundoff = 2;
    }

    const dataset: ChartDataset<'line'> = {
      data: getMetricData(selectedMetric, roundoff),
    };

    return { options: genOptions, dataset };
  }, [selectedMetric, getMetricData, getMetricLabels]);

  useEffect(() => {
    if (!canvasRef.current) return;
    chartRef.current?.destroy();

    chartRef.current = new Chart(canvasRef.current, {
      type: 'line',
      options: {
        ...chartOptionsAndDataSet.options,
        layout: {
          padding: 0,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            position: 'nearest',
            external: (props) => {
              const { tickPrefix, tickSuffix } = chartOptionsAndDataSet.options;

              return externalTooltipHandler({
                ...props,
                tickPrefix,
                tickSuffix,
                getUUID,
                service,
                googleAdType,
              });
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Report Date',
              color: OrionTheme.palette.common.black,
              font: {
                family: OrionTheme.typography.fontFamily,
                size: axisLabelFontSize,
              },
              padding: axisLabelPadding,
            },
            grid: {
              display: true,
              drawTicks: false,
              drawOnChartArea: true,
              drawBorder: true,
              borderColor: '#737373',
              color: '#737373',
            },
            ticks: {
              padding: window.innerWidth > 600 ? 16 : 8,
              color: OrionTheme.palette.common.black,
              font: getResponsiveMetricChartFont,
            },
          },
          y: {
            title: {
              display: true,
              text: yAxisTitle,
              color: OrionTheme.palette.common.black,
              font: {
                family: OrionTheme.typography.fontFamily,
                size: axisLabelFontSize,
              },
              padding: axisLabelPadding,
            },
            grid: {
              display: true,
              drawTicks: false,
              drawOnChartArea: true,
              drawBorder: true,
              borderColor: '#737373',
              color: '#737373',
            },
            ticks: {
              precision: 0,
              callback: function (value) {
                const formattedValue = value.toLocaleString(process.env.REACT_APP_LOCALE, {
                  notation: 'compact',
                  compactDisplay: 'short',
                });
                return (
                  (chartOptionsAndDataSet.options.tickPrefix ?? '') +
                  formattedValue +
                  (chartOptionsAndDataSet.options.tickSuffix ?? '')
                );
              },

              color: OrionTheme.palette.common.black,
              padding: window.innerWidth > 600 ? 16 : 8,
              maxTicksLimit: 6,
              font: getResponsiveMetricChartFont,
            },
            afterFit: (scaleInstance) => {
              if (window.innerWidth > 600) {
                scaleInstance.width = 120;
              } else {
                scaleInstance.width = 72;
              }
            },
          },
        },
      },
      data: {
        labels: getMetricLabels(),
        datasets: [
          {
            data: chartOptionsAndDataSet.dataset.data,
            borderColor: OrionTheme.palette.secondary.main,
            backgroundColor: OrionTheme.palette.secondary.main,
          },
        ],
      },
    });
  }, [chartOptionsAndDataSet]);

  return { canvasRef, isLoading, hasHistoryData, setContextData, chartRef };
};
