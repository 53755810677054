import { Typography, useMediaQuery } from '@mui/material';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { GradeUtils } from 'src/utils/GradeUtils';
import { ScoreSectionType } from 'src/enums/google/ScoreSection';
import { useContext } from 'react';
import { ServiceContext } from 'src/components/providers/service';
import { FontWeight } from 'src/components/theme/orion/overrides/FontWeight';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { GradeSize, ScoreBox } from '../ScoreBox';
import { pxToRem } from 'src/components/theme/orion/overrides/Typography';
import { useGradeSectionScore } from 'src/hooks/common/useGradeSectionScore';

type Props = {
  sectionName: ScoreSectionType;
};

export const GradeHeader = ({ sectionName }: Props): JSX.Element => {
  const [service] = useContext(ServiceContext);
  const smallerScreens = useMediaQuery(OrionTheme.breakpoints.down('lg'));
  const isTablet = useMediaQuery(OrionTheme.breakpoints.between('sm', 'md'));
  const { scoreName, scoreValue } = useGradeSectionScore({ service, sectionName });

  return (
    <FlexBoxRow sx={{ flexDirection: isTablet ? 'column' : 'row', justifyContent: 'flex-start', gap: 2 }}>
      <ScoreBox
        scoreName={scoreName}
        scoreValue={scoreValue}
        size={smallerScreens ? GradeSize.Small : GradeSize.Medium}
      />
      <Typography
        variant="h4"
        sx={{
          fontWeight: FontWeight.SemiBold,
          whiteSpace: 'pre',
          fontSize: OrionTheme.breakpoints.between('md', 'lg') ? pxToRem(16) : pxToRem(20),
          textTransform: 'capitalize',
          textAlign: isTablet ? 'center' : 'left',
          color: 'text.primary',
        }}
      >
        {GradeUtils.formatSectionName(sectionName)}
      </Typography>
    </FlexBoxRow>
  );
};
