import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import {DateRange} from 'src/components/common/metrics/DateRange';
import {Email} from 'src/components/common/metrics/Email';
import {ResponsiveRow} from 'src/components/common/ResponsiveRow';
import {BoldTypography} from 'src/components/common/styled/BoldTypography';
import {DynamicChart} from 'src/components/common/metrics/DynamicChart';
import {MetricsDataProvider} from 'src/components/providers/metricsData';
import {SelectedMetricProvider} from 'src/components/providers/selectedMetrics';
import styled from 'styled-components';
import {MetricSelect} from './MetricsSelect';
import {ServiceType} from 'src/enums/ServiceType';
import {useFacebookGrade} from 'src/hooks/facebook/useFacebookGrade';
import {useParams} from 'react-router-dom';

const StyledColumn = styled(FlexBoxColumn)`
    width: 100%;
    gap: ${({theme}) => theme.spacing(2)};
`;

const SelectContainer = styled(ResponsiveRow).attrs((props) => ({...props, breakpoint: 600}))`
    width: 100%;
    gap: ${({theme}) => theme.spacing(2)};
    align-items: center;
    ${({theme}) => theme.breakpoints.down(600)} {
      align-items: flex-start;
    }
    justify-content: space-between;
`;

export const PerformanceTracker = (): JSX.Element => {
  const {t} = useTranslation([ServiceType.FACEBOOK]);
  const {UUID} = useParams();
  const {grade} = useFacebookGrade(UUID);

  return (
    <SelectedMetricProvider>
      <MetricsDataProvider>
        <StyledColumn>
          <BoldTypography sx={{alignSelf: 'flex-start'}} variant='h3'>{t('metrics.performanceTracker.title')}</BoldTypography>
          <Typography sx={{alignSelf: 'flex-start'}} mb={2} variant='h5'>{t('metrics.performanceTracker.description')}</Typography>
          <BoldTypography sx={{alignSelf: 'flex-start'}} variant='h6'>{t('metrics.performanceTracker.caption')}</BoldTypography>
          <SelectContainer>
            <MetricSelect />
            <DateRange />
          </SelectContainer>
          <DynamicChart currencySymbol={grade?.overallScore.currencySymbol ?? '$'} />
          <Email sx={{mt: 3}} />
        </StyledColumn>
      </MetricsDataProvider>
    </SelectedMetricProvider>
  );
};
