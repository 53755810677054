import {SelectChangeEvent} from '@mui/material';
import {useCallback, useContext} from 'react';
import {IMetric} from 'src/components/providers/metricsData';
import {SelectedMetricContext} from 'src/components/providers/selectedMetrics';

type ReturnType = {
  value: keyof IMetric;
  onSelect: (e: SelectChangeEvent<unknown>) => void;
};

export const useMetricSelect = (): ReturnType => {
  const [value, setValue] = useContext(SelectedMetricContext);


  const onSelect = useCallback((e: SelectChangeEvent<unknown>) => {
    setValue(e.target.value as keyof IMetric);
  }, []);

  return {value, onSelect};
};
