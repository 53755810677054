import { GoogleAdsPage } from 'src/components/googleAds/page/Page';
import { ContentCardForErrors } from 'src/components/common/ContentCard';
import { GridType } from 'src/enums/GridType';
import { useTranslation } from 'react-i18next';
import { ServiceType } from 'src/enums/ServiceType';
import { Button, Divider, Link } from '@mui/material';
import ErrorFooter from 'src/components/googleAds/errors/ErrorFooter';
import { OrionColors } from '../../components/theme/orion/common/colors';
import { Email } from '../../components/common/metrics/Email';

export const GoogleAdsNoSpend = (): JSX.Element => {
  const { t } = useTranslation([ServiceType.GOOGLE]);

  return (
    <GoogleAdsPage gridType={GridType.FORM} additionalFooter={<ErrorFooter />}>
      <ContentCardForErrors title={t('error.title_nospend')} body={t('error.body_nospend')}>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          LinkComponent={Link}
          href="https://www.wordstream.com/learn"
          target="_blank"
          style={{ textDecoration: 'none' }}
        >
          {t('error.button_nospend')}
        </Button>
        <Divider sx={{ width: '100%', marginY: 5, borderColor: OrionColors.gray.legacyTint }} />
        <Email />
      </ContentCardForErrors>
    </GoogleAdsPage>
  );
};
