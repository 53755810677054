import { Typography, Box, Button, TypographyVariant, useMediaQuery, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation, TFunction } from 'react-i18next';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { KeywordsBackgroundContainer } from 'src/components/common/styled/StyledFooterElements';
import { SubFooter } from '../common/SubFooter';
import { FlexBoxColumn } from '../common/FlexBoxColumn';
import { StyledPageContent } from '../common/styled/StyledPageElements';
import { FlexBoxRow } from '../common/FlexBoxRow';
import { OrionTheme } from '../theme/orion/Theme';

import KeywordFooterImageOneDesktop from 'src/assets/KeywordFooterImageOne.svg';
import KeywordFooterImageTwoDesktop from 'src/assets/KeywordFooterImageTwo.svg';
import KeywordFooterImageOneMobile from 'src/assets/KeywordFooterImageOneSmall.svg';
import KeywordFooterImageTwoMobile from 'src/assets/KeywordFooterImageTwoSmall.svg';

const ImageOneContainer = styled(FlexBoxRow)(({ theme }) => ({
  position: 'absolute',
  width: useMediaQuery(theme.breakpoints.down('sm')) ? '263px' : '422px',
  height: useMediaQuery(theme.breakpoints.down('sm')) ? '252px' : '404px',
  right: useMediaQuery(theme.breakpoints.down('sm')) ? '-69px' : '-108px',
  top: 0,
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 2,
}));

const ImageTwoContainer = styled(FlexBoxRow)(({ theme }) => ({
  position: 'absolute',
  width: useMediaQuery(theme.breakpoints.down('sm')) ? '295px' : '472px',
  height: useMediaQuery(theme.breakpoints.down('sm')) ? '273px' : '437px',
  right: useMediaQuery(theme.breakpoints.down('sm')) ? '14px' : '37px',
  bottom: useMediaQuery(theme.breakpoints.down('sm')) ? '-108px' : '-201px',
  justifyContent: 'center',
  alignItems: 'center',
}));

type Props = {
  additionalTitle?: JSX.Element;
  body?: JSX.Element[];
  titleTranslationKey?: string;
  titleSize?: TypographyVariant;
  link?: string;
  withSubFooter?: boolean;
};

const getDefaultListItems = (t: TFunction<'translation'>): JSX.Element[] => [
  <Box key="listItem1" component="li">
    <Typography variant="body1">{t('footer.listItem1')}</Typography>
  </Box>,
  <Box key="listItem2" component="li">
    <Typography variant="body1">{t('footer.listItem2')}</Typography>
  </Box>,
  <Box key="listItem3" component="li">
    <Typography variant="body1">{t('footer.listItem3')}</Typography>
  </Box>,
];

export const Footer = ({
  body,
  titleTranslationKey = 'footer.title',
  titleSize,
  link = `https://www.wordstream.com/website-grader?cid=Web_Any_KWT_WebsiteGrader_LiQGrader&utm_source=freetools
    &utm_medium=fkt&utm_campaign=freemium`,
  withSubFooter = true,
}: Props): JSX.Element => {
  const { t } = useTranslation(['keywords']);
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(OrionTheme.breakpoints.between('sm', 'md'));
  const handleClick = (link: string) => {
    window.open(encodeURI(link), '_blank');
  };

  return (
    <>
      <KeywordsBackgroundContainer
        sx={{ position: 'relative', alignSelf: 'stretch', height: isMobile ? '100%' : OrionTheme.spacing(58) }}
      >
        <ImageOneContainer
          sx={{
            background: `url(${isMobile ? KeywordFooterImageOneMobile : KeywordFooterImageOneDesktop})`,
            backgroundRepeat: 'no-repeat',
          }}
        />
        <ImageTwoContainer
          sx={{
            background: `url(${isMobile ? KeywordFooterImageTwoMobile : KeywordFooterImageTwoDesktop})`,
            backgroundRepeat: 'no-repeat',
          }}
        />
        <StyledPageContent sx={{ gap: 5, py: isMobile ? 23.5 : 8, px: isMobile ? 1 : 0 }}>
          <FlexBoxColumn
            sx={{
              zIndex: 5,
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              gap: 3,
              maxWidth: isTablet ? '40%' : '60%',
            }}
          >
            <Typography
              variant={titleSize ?? 'h1'}
              color={`${OrionColors.cream.blocking}`}
              sx={{ whiteSpace: 'pre', alignSelf: 'flex-start', lineHeight: '35px' }}
            >
              {t(titleTranslationKey)}
            </Typography>
            <Box
              component="ul"
              sx={{ alignItems: 'flex-start', color: OrionColors.cream.blocking, paddingInlineStart: 3, my: 0 }}
            >
              {body ? body : getDefaultListItems(t)}
            </Box>
          </FlexBoxColumn>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ alignSelf: 'flex-start' }}
            onClick={() => handleClick(link)}
          >
            {t('footer.label', { context: 'button' })}
          </Button>
        </StyledPageContent>
      </KeywordsBackgroundContainer>
      {withSubFooter && (
        <>
          <Divider sx={{ borderColor: OrionColors.cream.blocking }} />
          <SubFooter />
        </>
      )}
    </>
  );
};
