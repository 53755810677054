import {useMemo} from 'react';
import {ICampaignGoals} from 'src/types/facebook/FacebookGrade';
import {GradeUtils} from 'src/utils/GradeUtils';

type Props = {
  campaignGoals?: ICampaignGoals;
}

type ReturnType = {
  percentAwareness: string | number;
  percentConsiderations: string | number;
  percentConversions: string | number;
}

export const useTypePercents = ({campaignGoals}: Props): ReturnType => {
  const {awareness, totalImpressions, consideration, totalClicks, conversion, totalResults} = campaignGoals || {};

  const percentAwareness = useMemo(() => awareness && totalImpressions !== undefined
    ? GradeUtils.roundoff(awareness.impressions / totalImpressions, 2, true)
    : '-', [awareness, totalImpressions]);

  const percentConsiderations = useMemo(() => consideration && totalClicks !== undefined
    ? GradeUtils.roundoff(consideration.clicks / totalClicks, 2, true)
    : '-', [consideration, totalClicks]);

  const percentConversions = useMemo(() => conversion && totalResults !== undefined
    ? GradeUtils.roundoff(conversion.totalResults / totalResults, 2, true)
    : '-', [conversion, totalResults]);

  return {percentAwareness, percentConsiderations, percentConversions};
};
