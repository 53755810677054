import {useParams} from 'react-router-dom';
import {useGoogleGrade} from 'src/hooks/google/useGoogleGrade';
import {useEffect, useRef, useState} from 'react';
import {IQualityScoreChartProps} from './LineGraph';
import {IVerticalStackedChartProps} from 'src/components/common/grade/charts/VerticalStackedGraph';
import {IDoughnutChartProps} from 'src/components/common/grade/charts/DoughnutChart';
import {IHorizontalBarGraphProps} from 'src/components/common/grade/charts/HorizontalBarGraph';
import {GoogleAdsScoreSection} from 'src/enums/google/ScoreSection';
import {useTranslation} from 'react-i18next';
import {
  DatasetDuration,
  getAccountActivityDataset,
  getImpressionsDataset,
  getQualityScoreDataset,
  getWastedSpendDataset
} from 'src/utils/datasetHelper';
import {GradeUtils} from 'src/utils/GradeUtils';
import {ServiceType} from 'src/enums/ServiceType';

type Props = {
  section: GoogleAdsScoreSection;
  isShopping?: boolean;
}

type ReturnType = {
  title: string;
  onDurationChange: (selectedDuration: DatasetDuration, section: GoogleAdsScoreSection) => void;
  qualityScoreData: Pick<IQualityScoreChartProps, 'dataset'> | undefined;
  qualityScoreLabels: number[] | undefined;
  accountActivityData: Pick<IVerticalStackedChartProps, 'dataset'> | undefined;
  impressionsData: Pick<IDoughnutChartProps, 'dataset'> | undefined;
  wastedSpendData: Pick<IHorizontalBarGraphProps, 'dataset'> | undefined;
}

export const useSectionManager = ({section}: Props): ReturnType => {
  const {UUID} = useParams();
  const {grade} = useGoogleGrade(UUID);
  const {t} = useTranslation([ServiceType.GOOGLE]);

  const sectionKey = GradeUtils.getSectionKey(section);
  const title = t(`grade.${sectionKey}.chart.title`);

  const [qualityScoreData, setQualityScoreData] = useState<Pick<IQualityScoreChartProps, 'dataset'> | undefined>();
  const [accountActivityData, setAccountActivityData] = useState<Pick<IVerticalStackedChartProps, 'dataset'> | undefined>();
  const [impressionsData, setImpressionsData] = useState<Pick<IDoughnutChartProps, 'dataset'> | undefined>();
  const [wastedSpendData, setWastedSpendData] = useState<Pick<IHorizontalBarGraphProps, 'dataset'> | undefined>();
  const [qualityScoreLabels, setQualityScoreLabels] = useState<number[] | undefined>();
  const isInitialRender = useRef(true);

  const onDurationChange = (selectedDuration: DatasetDuration, section: GoogleAdsScoreSection): void => {
    if (grade) {
      switch (section) {
        case GoogleAdsScoreSection.QUALITY_SCORE:
          setQualityScoreData(getQualityScoreDataset(grade, selectedDuration));
          break;
        case GoogleAdsScoreSection.ACCOUNT_ACTIVITY:
          setAccountActivityData(getAccountActivityDataset(grade, selectedDuration));
          break;
        case GoogleAdsScoreSection.WASTED_SPEND:
          setWastedSpendData(getWastedSpendDataset(grade, selectedDuration));
          break;
      }
    }
  };

  useEffect(() => {
    if (grade && section === GoogleAdsScoreSection.QUALITY_SCORE && isInitialRender.current) {
      setQualityScoreLabels(grade.qualityScore.duration90.buckets?.map((bucket) => bucket.bucket));
      onDurationChange('duration90', section);
      isInitialRender.current = false;
    }
  }, [grade, section, onDurationChange]);

  useEffect(() => {
    if (grade) {
      switch (section) {
        case GoogleAdsScoreSection.ACCOUNT_ACTIVITY:
          setAccountActivityData({...getAccountActivityDataset(grade)});
          break;
        case GoogleAdsScoreSection.IMPRESSION_SHARE:
          setImpressionsData({...getImpressionsDataset(grade)});
          break;
        case GoogleAdsScoreSection.WASTED_SPEND:
          setWastedSpendData({...getWastedSpendDataset(grade)});
          break;
      }
    }
  }, [grade]);

  return {title, onDurationChange, qualityScoreData, qualityScoreLabels, accountActivityData, impressionsData, wastedSpendData};
};
