import { Checkbox, Typography, Divider, SxProps, useMediaQuery } from '@mui/material';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { ReactComponent as EmailIcon } from 'src/assets/EmailIcon.svg';
import { useTranslation } from 'react-i18next';
import { useMetricsSubscribed } from 'src/hooks/common/useMetricsSubscribed';
import { ServiceType } from 'src/enums/ServiceType';
import { BoldTypography } from '../styled/BoldTypography';
import { StyledCard } from '../styled/StyledCard';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { OrionTheme } from 'src/components/theme/orion/Theme';

interface EmailProps {
  sx?: SxProps;
}

export const Email = ({ sx }: EmailProps): JSX.Element => {
  const { t } = useTranslation([ServiceType.GOOGLE]);
  const { subscribed, onToggle } = useMetricsSubscribed();
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));

  return (
    <StyledCard sx={{ ...sx, p: 0, alignSelf: 'center' }} data-testid="emailBox">
      <FlexBoxRow sx={{ justifyContent: 'stretch', alignItems: 'stretch' }}>
        <FlexBoxColumn sx={{ backgroundColor: OrionColors.cream.background, p: 2 }}>
          <EmailIcon />
        </FlexBoxColumn>
        <Divider orientation="vertical" flexItem={true} sx={{ borderColor: OrionColors.gray.dark }} />
        <FlexBoxColumn sx={{ p: 2, alignItems: 'flex-start', flex: '1 1 0' }}>
          <BoldTypography variant="body2">{t('tracker.email.header')}</BoldTypography>
          <FlexBoxRow sx={{ py: 0.5, gap: 1 }}>
            <Checkbox
              sx={{ p: 0, color: 'primary.main' }}
              size={isMobile ? 'small' : 'medium'}
              checked={subscribed}
              onChange={onToggle}
              aria-label={t('tracker.email.header')}
            />
            <Typography variant="body2">{t('tracker.email.body')}</Typography>
          </FlexBoxRow>
        </FlexBoxColumn>
      </FlexBoxRow>
    </StyledCard>
  );
};
