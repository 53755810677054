import {useContext, useEffect} from 'react';
import {Logger} from 'src/utils/Logger';
import ErrorSnackbar from '../common/ErrorSnackbar';
import {KeywordsContext} from '../providers/keywords';

export const KeywordError = (): JSX.Element => {
  const {status: {error}} = useContext(KeywordsContext);

  useEffect(() => {
    if (!error) return;

    Logger.error('keyword fetch error', {error}, 'KeywordError');
  }, [error]);
    
  return error ? <ErrorSnackbar /> : <></>;
};
