import { MenuItem, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMetricSelect } from 'src/components/common/metrics/useMetricSelect';
import StyledSelect from 'src/components/common/styled/StyledSelect';
import { GoogleAdType } from 'src/enums/GoogleAdType';
import { OrionTheme } from 'src/components/theme/orion/Theme';

export const MetricSelect = (): JSX.Element => {
  const { value, onSelect } = useMetricSelect();
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));
  const { t } = useTranslation([GoogleAdType.DISPLAY]);

  return (
    <StyledSelect
      data-testid="displayMetricSelect"
      value={value}
      onChange={onSelect}
      sx={{ width: isMobile ? '100%' : '296px' }}
      size="small"
    >
      <MenuItem value="overallScore">{t('tracker.overall_score')}</MenuItem>
      <MenuItem value="ctr">{t('tracker.display_ad_types')}</MenuItem>
      <MenuItem value="viewableImpressions">{t('tracker.display_ad_placements')}</MenuItem>
      <MenuItem value="cpm">{t('tracker.audience_performance')}</MenuItem>
      <MenuItem value="spend">{t('tracker.spend')}</MenuItem>
    </StyledSelect>
  );
};
