import { styled, Typography, Link, Divider, useMediaQuery, useTheme } from '@mui/material';
import { OrionColors } from 'src/components/theme/orion/common/colors';
import { useTranslation } from 'react-i18next';
import { FlexBoxColumn } from './FlexBoxColumn';
import { ResponsiveRow } from './ResponsiveRow';
import { FlexBoxRow } from './FlexBoxRow';
import { SubFooterBox } from 'src/components/common/styled/StyledFooterElements';
import { StyledPageContent } from './styled/StyledPageElements';

import facebook from 'src/assets/platformIcons/footerIcons/FacebookFooterIcon.svg';
import instagram from 'src/assets/platformIcons/footerIcons/InstagramFooterIcon.svg';
import linkedIn from 'src/assets/platformIcons/footerIcons/LinkedInFooterIcon.svg';
import twitter from 'src/assets/platformIcons/footerIcons/XFooterIcon.svg';
import youtube from 'src/assets/platformIcons/footerIcons/YoutubeFooterIcon.svg';

const ColoredLink = styled(Link)`
  color: ${OrionColors.cream.blocking};
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
`;

const IconLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;

export const SubFooter = (): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const { t } = useTranslation(['keywords', 'common']);

  return (
    <SubFooterBox sx={{ alignSelf: 'stretch', alignItems: 'stretch' }}>
      <StyledPageContent>
        <ResponsiveRow breakpoint={'sm'} sx={{ justifyContent: 'space-between', alignSelf: 'stretch' }}>
          <FlexBoxColumn sx={{ py: 3, alignItems: isMobile ? 'center' : 'flex-start' }}>
            <FlexBoxRow gap={1.25}>
              <IconLink href={t('footer.social.facebook', { ns: 'common' })} target="_blank">
                <img src={facebook} id="logo" alt="WordStream By LocaliQ on Facebook" />
              </IconLink>
              <IconLink href={t('footer.social.twitter', { ns: 'common' })} target="_blank">
                <img src={twitter} id="logo" alt="WordStream By LocaliQ on Twitter" />
              </IconLink>
              <IconLink href={t('footer.social.linkedin', { ns: 'common' })} target="_blank">
                <img src={linkedIn} id="logo" alt="WordStream By LocaliQ on LinkedIn" />
              </IconLink>
              <IconLink href={t('footer.social.instagram', { ns: 'common' })} target="_blank">
                <img src={instagram} id="logo" alt="WordStream By LocaliQ on Instagram" />
              </IconLink>
              <IconLink href={t('footer.social.youtube', { ns: 'common' })} target="_blank">
                <img src={youtube} id="logo" alt="WordStream By LocaliQ on Youtube" />
              </IconLink>
            </FlexBoxRow>
          </FlexBoxColumn>
          <FlexBoxColumn gap={0.5} sx={{ alignItems: isMobile ? 'center' : 'flex-end', pb: isMobile ? 3 : 0 }}>
            {isLargeScreen ? (
              <></>
            ) : (
              <Typography variant="body2" color={OrionColors.cream.blocking}>
                {t('footer.copyright')}
              </Typography>
            )}
            <ResponsiveRow breakpoint={'md'} gap={0.5} sx={{ alignItems: isMobileOrTablet ? 'center' : 'flex-start' }}>
              {isLargeScreen ? (
                <Typography variant="body2" color={OrionColors.cream.blocking}>
                  {t('footer.copyright')}
                </Typography>
              ) : (
                <></>
              )}
              <FlexBoxRow gap={1} justifyContent="flex-start">
                <ColoredLink
                  variant="body2"
                  href={t('footer.link_privacy', { ns: 'common' })}
                  target="_blank"
                  color={OrionColors.cream.blocking}
                >
                  {t('privacy', { ns: 'common' })}
                </ColoredLink>
                <Divider orientation={'vertical'} flexItem={true} sx={{ borderColor: OrionColors.cream.blocking }} />
                <ColoredLink
                  variant="body2"
                  href={t('footer.link_trademarks', { ns: 'common' })}
                  target="_blank"
                  color={OrionColors.cream.blocking}
                >
                  {t('trademarks', { ns: 'common' })}
                </ColoredLink>
                {isMobileOrTablet ? (
                  <></>
                ) : (
                  <Divider orientation={'vertical'} flexItem={true} sx={{ borderColor: OrionColors.cream.blocking }} />
                )}
              </FlexBoxRow>
              <FlexBoxRow gap={1} justifyContent="flex-start" sx={{ pl: isMobileOrTablet ? 0 : 0.5 }}>
                <ColoredLink
                  variant="body2"
                  href={t('footer.link_legal', { ns: 'common' })}
                  target="_blank"
                  color={OrionColors.cream.blocking}
                >
                  {t('legal', { ns: 'common' })}
                </ColoredLink>
                <Divider orientation={'vertical'} flexItem={true} sx={{ borderColor: OrionColors.cream.blocking }} />
                <ColoredLink
                  variant="body2"
                  href={t('footer.link_cookies', { ns: 'common' })}
                  target="_blank"
                  color={OrionColors.cream.blocking}
                >
                  {t('cookie', { ns: 'common' })}
                </ColoredLink>
              </FlexBoxRow>
            </ResponsiveRow>
          </FlexBoxColumn>
        </ResponsiveRow>
      </StyledPageContent>
    </SubFooterBox>
  );
};
