import { Box, Link, Typography, TypographyProps, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FlexBoxRow } from 'src/components/common/FlexBoxRow';
import { FlexBoxColumn } from 'src/components/common/FlexBoxColumn';
import { ResponsiveRow } from 'src/components/common/ResponsiveRow';
import { IAdStat } from 'src/types/google/GoogleGrade';
import { AdScore } from './AdScore';
import { ServiceType } from 'src/enums/ServiceType';
import { BoldTypography } from '../../../common/styled/BoldTypography';
import { OrionTheme } from 'src/components/theme/orion/Theme';
import { LineHeight } from 'src/components/theme/orion/overrides/LineHeight';
import { FontWeight } from 'src/components/theme/orion/overrides/FontWeight';
import styled from 'styled-components';

type Props = {
  stat: IAdStat;
};

const ColoredLink = styled(Link).attrs((props) => ({ ...props, variant: 'primary' }))`
  font-family: Unify Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  // Fix for long urls
  overflow-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`;

const AdContainerBackground = styled(Box)({
  padding: OrionTheme.spacing(1),
  paddingTop: OrionTheme.spacing(2),
  background: '#F0F0F0',
});

const AdContainerFlexColumn = styled(FlexBoxColumn)({
  alignItems: 'flex-start',
  backgroundColor: OrionTheme.palette.common.white,
  padding: OrionTheme.spacing(2),
  borderRadius: OrionTheme.spacing(1),
  gap: OrionTheme.spacing(1.5),
  boxShadow:
    '0px 1px 5px 0px rgba(0, 0, 0, 0.20), 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14)',
});

const BorderBox = styled(Box)({
  border: '4px solid #BDC1C6',
  paddingLeft: OrionTheme.spacing(2),
  paddingRight: OrionTheme.spacing(2),
  paddingTop: OrionTheme.spacing(4),
  borderBottom: 'none',
  borderTopRightRadius: OrionTheme.spacing(5),
  borderTopLeftRadius: OrionTheme.spacing(5),
});

const AdText = styled(Typography).attrs((props: TypographyProps) => ({
  ...props,
  variant: 'caption',
}))({
  fontFamily: 'Roboto',
  color: '#202124',
  lineHeight: LineHeight.LOOSE,
  fontWeight: FontWeight.Bold,
});

const AdUrl = styled(Typography).attrs((props: TypographyProps) => ({
  ...props,
  variant: 'caption',
}))({
  fontFamily: 'Roboto',
  color: '#202124',
  lineHeight: LineHeight.LOOSE,
  fontWeight: FontWeight.Regular,
});

const AdDescription = styled(Typography).attrs((props: TypographyProps) => ({
  ...props,
  variant: 'button',
}))({
  fontFamily: 'Roboto',
  textTransform: 'none',
  lineHeight: '20px',
});

export const RowContent = ({ stat }: Props): JSX.Element => {
  const { t } = useTranslation([ServiceType.GOOGLE]);
  const domain = stat.finalUrl.replace(/(http(s)?:\/\/)|(\/.*){1}/g, '');
  const isMobile = useMediaQuery(OrionTheme.breakpoints.down('sm'));

  return (
    <FlexBoxColumn sx={{ paddingX: isMobile ? 0 : 2 }}>
      <ResponsiveRow breakpoint={600} sx={{ alignSelf: 'stretch', alignItems: 'flex-start', gap: isMobile ? 2 : 10 }}>
        {/* Ad Preview Starts */}
        <FlexBoxColumn gap={1.25} sx={{ alignItems: 'flex-start', flex: '0.70 0 0' }}>
          <BoldTypography variant="h6" sx={{ color: 'text.disabled' }}>
            {t('metrics.textAds.preview')}
          </BoldTypography>
          <BorderBox>
            <AdContainerBackground>
              <AdContainerFlexColumn>
                <FlexBoxRow sx={{ justifyContent: 'flex-start', alignItems: 'stretch', gap: 0.5 }}>
                  <AdText>{t('metrics.textAds.ad')}</AdText>
                  <AdText>&bull;</AdText>
                  <AdUrl>{domain}</AdUrl>
                </FlexBoxRow>
                <Typography variant="h5" sx={{ color: '#1558D6', fontFamily: 'Roboto', lineHeight: '26px' }}>
                  {stat.headline} | {stat.headline2}
                </Typography>
                <AdDescription>
                  {stat.description1}. {stat.description2}
                </AdDescription>
              </AdContainerFlexColumn>
            </AdContainerBackground>
          </BorderBox>
        </FlexBoxColumn>
        {/* Ad Preview Ends */}
        {/* Ad Stats Starts */}
        <FlexBoxColumn gap={2} sx={{ alignSelf: 'flex-start', alignItems: 'flex-start', flex: '1 0 0' }}>
          <Typography alignSelf={'flex-start'} fontWeight={'fontWeightBold'} variant="h5">
            {stat.statsType === 'BEST' ? t('metrics.textAds.bestAd') : t('metrics.textAds.worstAd')}
          </Typography>
          <FlexBoxColumn gap={1} sx={{ alignItems: 'flex-start', width: '100%' }}>
            <Typography fontWeight={'fontWeightBold'} variant="body1">
              {t('metrics.textAds.adUrl')}
            </Typography>
            <ColoredLink href={stat.finalUrl} target="_blank">
              {stat.finalUrl}
            </ColoredLink>
            <Box sx={{ mt: 3.5, width: '100%' }}>
              <AdScore {...stat} />
            </Box>
          </FlexBoxColumn>
        </FlexBoxColumn>
        {/* Ad Stats Ends */}
      </ResponsiveRow>
    </FlexBoxColumn>
  );
};
