import {useTheme, Typography, useMediaQuery} from '@mui/material';
import {FlexBoxColumn} from 'src/components/common/FlexBoxColumn';
import MetricArrowRight from 'src/assets/MetricArrowRight.svg';
import MetricArrowDown from 'src/assets/MetricArrowDown.svg';
import React from 'react';

interface ArrowWithPercentageProps {
  text?: string;
  percentage?: string | number;
}

export const ArrowWithPercentage: React.FC<ArrowWithPercentageProps> = ({percentage, text}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const arrowSrc = isMobile ? MetricArrowDown : MetricArrowRight;

  return (
    <FlexBoxColumn sx={{flexDirection: isMobile ? 'column-reverse' : 'column', gap: 0.5}}>
      <img src={arrowSrc} alt="Performance Metrics" />
      {
        text
        && <Typography variant='caption'
          fontWeight={'fontWeightRegular'} >
          {text}
          &nbsp;
          {
            percentage !== undefined
              ? percentage.toLocaleString(process.env.LOCALE, {maximumFractionDigits: 2, style: 'percent'})
              : '\u00A0'
          }
        </Typography>
      }
    </FlexBoxColumn>
  );
};
