import {KeywordsPage} from 'src/components/keywords/page/Page';
import SearchResult from 'src/components/keywords/searchResult/SearchResult';
import {KeywordsProvider} from 'src/components/providers/keywords';
import {Helmet} from 'react-helmet-async';
import useGATracking from 'src/hooks/google/useGATracking';
import {KeywordError} from 'src/components/keywords/KeywordError';
import {PageTitle} from 'src/enums/PageTitle';

export const KeywordsHome = (): JSX.Element => {
  useGATracking({pageViewDefaults: {title: 'WordStream Free Keyword Tool'}, triggerOnMount: true});

  return <KeywordsProvider>
    <KeywordsPage>
      <Helmet><title>{PageTitle.KEYWORDS}</title></Helmet>
      <SearchResult />
      <KeywordError />
    </KeywordsPage>
  </KeywordsProvider>;
};
