import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import useSWR from 'swr';

type ReturnType = {
  file: string;
}

export const useDownloadPage = (): ReturnType => {
  const {UUID} = useParams();
  const [file, setFile] = useState('');
  const navigate = useNavigate();

  const {data, error} = useSWR(`${process.env.REACT_APP_BACKEND_URL}/google/keywords/${UUID}/download`);

  useEffect(() => {
    if (!data && !error) return;
    if (error && error.status === 404) {
      navigate('/fkt/noroute?event=linkExpired');
      return;
    }

    setFile(data.downloadUrl);
    const a = document.createElement('a');

    a.href = data.downloadUrl;
    a.download = 'Keywords';
    a.click();
  }, [data, error]);

  return {file};
};
